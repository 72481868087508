import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage, FormattedRelativeTime } from "react-intl";
import { api_postValueOrErrorMessage } from "../../Api/util";
import { NOTIFICATION } from "../../Hook/notification";
import { OPEN_CHANNELS } from "../../Hook/openChannels";
import { displayPopin } from "../../Hook/popins";
import Avatar from "../Avatar";
import Pseudonym from "../Member/Pseudonym";
import angel from "../../Component/Filters/svg/angel.svg";
import user from "./svg/user.svg";
import cutie from "../../Component/Filters/svg/cutie.svg";
import { USER } from "../../Hook/user";
import { useEffect, useState } from "react";

export default function PrivateChatItem({ e, tab, filter, userHook }: any) {
  let time = ((e.last_message?.time_sent as any) - Date.now()) / 1000;
  const [seed, setSeed] = useState(Math.random());
  useEffect(() => {
    const listener = () => setSeed(Math.random());
    window.addEventListener("RefreshMessageDesktop", listener);

    return () => window.removeEventListener("RefreshMessageDesktop", listener)
  })
  
  return (
    <div
      key={e.id}
      onClick={() => {
        if (!e.can_chat && e.type === "fanclub")
          return displayPopin()("fanclub", { member: e.star });
        let err = USER?.informations?.restrictions?.find(
          (e) =>
            e.type === "chat_access" ||
            e.type === "public_chat_access",
        );
        if (err) {
          return displayPopin()("chaterror", { error: err });
        }
        if (e.type === "private") {
          NOTIFICATION.decreaseMessageCount(e.nb_new_messages ?? 0);
        }
        OPEN_CHANNELS.openChannel(e);
      }}
      className="bg-cards relative rounded-xl flex px-4 py-4 gap-6 text-sm"
    >
      <div className="relative">
        <div className="w-16 border-2 border-unactive h-16 rounded-full overflow-hidden relative top-1/2 -translate-y-1/2">
          {e.type !== "public" && (
            <Avatar
              member={e.type === "fanclub" ? e.star : e.otherMember}
            />
          )}
          {e.type === "public" && (
            <img
              src={`https://flagcdn.com/108x81/${e.id === "fr-1" ? "fr" : "gb"}.png`}
              className="w-full h-full"
            />
          )}
        </div>
        {e.type === "private" && e.nb_new_messages > 0 && (
          <div className="w-6 h-6 bottom-2 absolute flex justify-center items-center right-0 bg-primary rounded-full">
            {e.nb_new_messages}
          </div>
        )}
      </div>
      <div className="flex-1" style={{ width: "calc(100% - 6rem)" }}>
        <div>
          <div className="whitespace-nowrap text-ellipsis w-full overflow-hidden">
            {e.type === "public" && (
              <div className="flex gap-2">
                <div className="text-woman">
                  <FormattedMessage
                    id={`chatlist__public_chat__${e.id}`}
                  />
                </div>
                <div className="text-messageplaceholder">|</div>
                <div className="flex items-center gap-1">
                  <img className="h-4" src={user} alt="user icon" />
                  {e.members.length}
                </div>
              </div>
            )}
            {e.type === "private" && (
              <div className="flex gap-2 items-center flex-wrap">
                <Pseudonym online member={e.otherMember} />

                {e.otherMember.is_angel && (
                  <img src={angel} alt="angel" className="text-md" />
                )}
                {e.otherMember.is_cutie && (
                  <img src={cutie} alt="cutie" className="h-4" />
                )}
              </div>
            )}
            {e.type === "fanclub" && (
              <FormattedMessage
                id={`chatlist__fanclub_chat`}
                values={{ member: e.star.pseudonym || "fanclub" }}
              />
            )}
          </div>
        </div>
        <div className="flex w-full my-2">
          <div className="flex-1 text-messagetxt bg-message px-2 py-2 h-14 rounded-t-xl rounded-br-xl">
            {e.last_message?.text && (
              <div className="text-xs mt-1 h-8 overflow-hidden">
                {e.last_message?.text}
              </div>
            )}
            {e.last_message?.type === "gift" &&
              e.last_message?.author !==
              userHook.informations?.id && (
                <div className="text-xs mt-1 h-8 overflow-hidden">
                  <FormattedMessage id="chatmessage__received_gift" />
                </div>
              )}
            {e.last_message?.type === "gift" &&
              e.last_message?.author ===
              userHook.informations?.id && (
                <div className="text-xs mt-1 h-8 overflow-hidden">
                  <FormattedMessage id="chatmessage__sent_gift" />
                </div>
              )}
            {e.last_message?.type === "content" &&
              e.last_message?.author !==
              userHook.informations?.id && (
                <div className="text-xs mt-1 h-8 overflow-hidden">
                  <FormattedMessage id="chatmessage__received_media" />
                </div>
              )}
            {e.last_message?.type === "content" &&
              e.last_message?.author ===
              userHook.informations?.id && (
                <div className="text-xs mt-1 h-8 overflow-hidden">
                  <FormattedMessage id="chatmessage__sent_media" />
                </div>
              )}
          </div>
        </div>
      </div>
      {tab === 1 && (
        <div
          className="px-1 my-auto rounded-full bg-background w-8 h-8 flex items-center justify-center text-messageplaceholder"
          onClick={(event) => {
            event.stopPropagation();
            api_postValueOrErrorMessage("chat/channels/remove", {
              channel: e.id,
            });
            filter(e.id);
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </div>
      )}
      <div className="absolute left-[6.5rem] bottom-0.5 text-[0.6rem] text-messageplaceholder">
        <RelativeChatTime time={time} />
      </div>
    </div>
  );
}

function RelativeChatTime({ time }: any) {
  if (time.toString() === "NaN") time = 0;
  try {
    return (
      <FormattedRelativeTime
        value={time}
        numeric="auto"
        updateIntervalInSeconds={60}
      />
    );
  } catch (err) {
    return null;
  }
}