import React from 'react'
import { FormattedMessage } from 'react-intl'
import { membersInformations_members } from '../../Api/types'
import { api_postValueOrErrorMessage } from '../../Api/util'
import Avatar from '../../Component/Avatar'
import Button from '../../Component/Input/Button'
import Pseudonym from '../../Component/Member/Pseudonym'
import { closeCurrentPopin } from '../../Hook/popins'

function BlockedPopin({member, reload} : {member: membersInformations_members, reload: any}) {
  return (
    <>
      <div onClick={closeCurrentPopin} className="top-0 left-0 right-0 bottom-0 fixed bg-black opacity-60 z-40"></div>
      <div className="z-50 p-8 lg:max-w-2xl flex flex-col lg:rounded-xl gap-4 bg-popinbg fixed max-h-[100dvh] scroll--numbers overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className='flex gap-2 justify-center items-center'>
          <div className='w-20 h-20 rounded-full overflow-hidden'><Avatar member={member}/></div>
          <div><Pseudonym member={member} /></div>
        </div>
        <Button color="primary" name="blocked__unblock_button" onClick={() => {
          api_postValueOrErrorMessage("members/unblock", {id: member.id});
          closeCurrentPopin();
          reload && reload();
        }}/>
      </div>
    </>
  )
}

export default BlockedPopin