import {
  faArrowRight,
  faCheck,
  faInfoCircle,
  faLock,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import Button from "../../Component/Input/Button";
import bgMobile from "./assets/background4.png"
import bgDesktop from "./assets/background2.png"
import censure from "./assets/censure.png";
import useIsMobile from "../../Hook/mobile";
import { LOCALE } from "../../Hook/locale";

function ContentProviderIntro({ nextStep }: any) {
  const detectMobile = useIsMobile();
  return (
    <>
      <div style={{ backgroundImage: `url(${detectMobile.isMobile ? bgMobile : bgDesktop})` }} className="w-full flex lg:flex-row flex-col items-center lg:justify-center h-72 lg:h-60 bg-contain bg-no-repeat">
        <img className="h-40" src={censure} />
        <div className="text-center lg:text-left">
          <h3 className="text-primary text-2xl"><FormattedMessage id="content_provider__want_to_publish_nudes" /></h3>
          <p className="text-black mt-4"><FormattedMessage id="content_provider__enforced_by_law" /></p>
        </div>
      </div>
      <div className="flex flex-col gap-4 lg:flex-row">
        <div className="flex gap-6 p-4 px-6 flex-1 h-full rounded-xl border border-border items-center">
          <div className="relative"><FontAwesomeIcon className="text-primary h-10" icon={faRocket} />
            <div className="bg-green rounded-full flex items-center justify-center h-8 w-8 absolute bottom-0 -right-4 border-4 border-popinbg"><FontAwesomeIcon className="text-white" icon={faCheck} /></div>
          </div>
          <div className="text-green"><FormattedMessage id="content_provider__free_simple_fast" /></div>
        </div>
        <div className="flex gap-6 p-4 px-6 flex-1 h-full rounded-xl border border-border items-center mb-4">
          <div className="relative"><FontAwesomeIcon className="text-primary h-10" icon={faLock} />
            <div className="bg-green rounded-full flex items-center justify-center h-8 w-8 absolute bottom-0 -right-4 border-4 border-popinbg"><FontAwesomeIcon className="text-white" icon={faCheck} /></div>
          </div>
          <div>
            <p className=" text-green"><FormattedMessage id="content_provider__fully_secured" /></p>
            <p className="text-xs mt-1"><FormattedMessage id="content_provider__trusted_partners" /></p>
          </div>
        </div>
      </div>
      <Button
        onClick={() => {
          nextStep();
        }}
        color="primary"
        className={"flex items-center justify-center gap-4 !px-10 !py-3"}
      >
        <FormattedMessage id="content_provider__sign_my_account" />
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
      <div
        onClick={() =>
          window.open(
            `https://www.snapdesk.info/hc/${LOCALE.locale === "fr" ? "fr-fr" : "en-us"}/articles/4406628307474-A-quoi-sert-l-authentification-pour-publier-du-contenu-SEXY-ou-EXPLICITE-`,
            "_blank",
          )
        }
        className="flex cursor-pointer items-center justify-center gap-2"
      >
        <FontAwesomeIcon icon={faInfoCircle} />
        <FormattedMessage id="content_provider__more_infos" />
      </div>
    </>
  );
}

export default ContentProviderIntro;
