import { useEffect, useState } from 'react'
import { api_getPlatformInformations, getPlatformCountriesPerContinent, getPlatformGifts, getPlatformTrophies } from '../Api/platform';
import { challenges, challenges_challenges, gifts_gifts, locationCountriesInformations, locationCountriesInformations_countries, trophies_trophies, websiteCounters, websiteParameters } from '../Api/types';
import { api_getValueOrErrorMessage, api_postValueOrErrorMessage } from '../Api/util';
import BaseHookWithUpdaters from './baseHookWithUpdaters';
import { LOCALE } from './locale';

class Platform extends BaseHookWithUpdaters {
    platform: websiteParameters | undefined;
    trophies: trophies_trophies[] = [];
    gifts: gifts_gifts[] = [];
    continent: {[key: string]: locationCountriesInformations_countries[]} = {};
    challenges: challenges_challenges[] = [];
    counters:websiteCounters = {
        nb_online_members: 0, 
        nb_ephemes: 0
    }
    fetch = async () => {
        if(this.isLoading) return;
        this.isLoading = true;
        
        const listAllPromises = [
            this.fetchPlatform(),
            this.fetchTrophies(),
            this.fetchGifts(),
            this.fetchContinent(),
            this.fetchChallenges(),
            this.fetchCounters(),
        ]

        await Promise.allSettled(listAllPromises);
        this.update();
        const SecurionPay = (window as any).SecurionPay;
        SecurionPay.setPublicKey(this.platform?.securionpay_public_key);
        this.isLoaded = true;
        this.isLoading = false;
    }

    fetchPlatform = async () => {
        this.platform = await api_getPlatformInformations();
        this.platform.languages = this.platform.languages.filter(e => e.type === "front");

    }

    fetchTrophies = async () => {
        this.trophies = (await getPlatformTrophies()).trophies;
        
    }

    fetchGifts = async () => {
        this.gifts = (await getPlatformGifts()).gifts;
        
    }

    fetchContinent = async () => {
        this.continent  = await getPlatformCountriesPerContinent();
        
    }

    fetchChallenges = async () => {
        this.challenges = (await api_postValueOrErrorMessage<challenges>("challenges", {sort:"date"})).challenges;
        
    }

    fetchCounters = async () => {
        this.counters = (await api_getValueOrErrorMessage<any>("website/counters"));
    }

}

export let PLATFORM = new Platform();
function usePlatform() {
    const [platform, setPlatform] = useState<Platform|null>(PLATFORM.isLoaded ? PLATFORM : null);

    useEffect(() => {
        PLATFORM.register(setPlatform);
        if(!platform){
            console.log("here", PLATFORM.isLoaded);
            PLATFORM.fetch();
        }
        return () => {
            PLATFORM.unregister(setPlatform);
        }
    }, [])

    return platform;
}

export default usePlatform;