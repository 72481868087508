import React from 'react'
import { FormattedMessage } from 'react-intl';
import { closeCurrentPopin, displayPopin } from '../../../Hook/popins';
import Button from '../../Input/Button';

function GuestMessage() {

    return (
        <div className='flex gap-4 text-xs'>
            <div className='flex-1'>
                <div className='flex w-full mb-2 justify-center text-primary font-bold'>
                    <div>Admin</div>
                </div>
                <div className="text-primary text-center mb-4 relative whitespace-prewrap w-full bg-primary px-4 py-2 rounded-xl">
                    <div className='text-black mb-4 whitespace-pre-wrap'><FormattedMessage id="chat__guest_error" /></div>
                    <div className='flex justify-center gap-2 items-center'>
                        <Button  color="white" className={"font-bold !py-2 !px-6"} onClick={() => {
                            closeCurrentPopin();
                            displayPopin()("login");
                        }}><FormattedMessage id="menu__login" /></Button>
                        <div className='text-black'><FormattedMessage id="chat__guest_or" /></div>
                        <Button color="white" className={"font-bold !py-2 !px-6"} onClick={() => {
                            closeCurrentPopin();
                            window.location.href= "/smartrotation/direct.php";
                        }}><FormattedMessage id="register__register" /></Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GuestMessage