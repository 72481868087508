import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { chatChannelsAdd, chatChannelsInformations, membersInformations_members, userInformations } from '../../Api/types'
import { closeCurrentToast } from '../../Hook/toast';
import Avatar from '../Avatar'
import Pseudonym from '../Member/Pseudonym';
import useIsMobile from '../../Hook/mobile';
import { useNavigate } from 'react-router';
import { fetchMembersFromIds } from '../../Api/member';
import { api_postValueOrErrorMessage } from '../../Api/util';
import { OPEN_CHANNELS } from '../../Hook/openChannels';
import user from '../../Hook/user';
import useUser from '../../Hook/user';
import { FormattedMessage } from 'react-intl';
const defaultTimer = 10000;

let interval:any;

function PToast({ member, title, message, id }: { member: membersInformations_members | userInformations, title: string, message: string, id: number }) {
  const user = useUser()!;
  const navigate = useNavigate();
  useEffect(() => {
    let timer = defaultTimer; //10s
    interval = setInterval(() => {
      timer -= 50;
      let timerHTML = document.getElementById("timer");
      if (timerHTML) timerHTML.style.width = (timer * 100 / defaultTimer) + "%";
      if (timer < 0) {
        clearInterval(interval);
        closeCurrentToast();
      };
    }, 50);
  }, [id]);

  return (
    <div className='flex w-full rounded-xl overflow-hidden lg:w-96 bg-[#D5D7D8] p-2 relative gap-3' onClick={() => {
      navigate("/member/" + member.id);
      closeCurrentToast();
    }}>
      <div className='absolute top-2 right-3' onClick={(event) => {
        event.stopPropagation();
        closeCurrentToast();
        clearInterval(interval);
      }}><FontAwesomeIcon icon={faClose} /></div>
      <div className='w-12 h-12 rounded-full overflow-hidden m-4'><Avatar member={member} /></div>
      <div>
        <div className='text-black'><FormattedMessage id="toast__profile_visit_title" values={{member:member.pseudonym}} /><Pseudonym member={member} /></div>
      </div>
      <div id="timer" className='absolute bottom-0 left-0 w-full h-0.5 bg-primary'></div>
    </div>
  )
}

export default PToast