import React, { useEffect, useRef, useState } from "react";
import { api_chatGetChannels } from "../../Api/chat";
import { chatChannelsInformations_channels } from "../../Api/types";
import useNotification, { NOTIFICATION } from "../../Hook/notification";
import useOpenedChannels from "../../Hook/openChannels";
import { displayPopin } from "../../Hook/popins";
import useUser from "../../Hook/user";
import ChatTabs from "./chatsTabs";
import OpenDesktopChats from "./OpenDesktopChats";
import chat from "./svg/chat.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

function ChatDesktop() {
  const [privateC, setPrivate] = useState<
    null | chatChannelsInformations_channels[]
  >(null);
  const [publicC, setPublic] = useState<
    null | chatChannelsInformations_channels[]
  >(null);
  const [page, setPage] = useState(0);
  const counters = useNotification();
  const user = useUser();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const openChannel = useOpenedChannels();

  useEffect(() => {
    if (user?.informations?.type === "guest") return;
    const publicCall = async () => {
      let publicChats = await api_chatGetChannels(
        ["public", "fanclub"],
        0,
        true,
      );
      if (publicChats) setPublic(publicChats);
    };
    const privateCall = async () => {
      let r: any[] = [];
      for (let i = 0; i <= page; i++) {
        let privateChats = await api_chatGetChannels(["private"], i, true);
        if (privateChats) r = [...r, ...privateChats];
      }
      setPrivate(r);
    };
    const interval = setInterval(publicCall, 25000);
    let interval2 = setInterval(privateCall, 50000);
    if (!publicC) publicCall();
    if (!privateC) privateCall();

    window.addEventListener("refreshChat", () => {
      privateCall();
      clearInterval(interval2);
      interval2 = setInterval(privateCall, 50000);
    });
    return () => {
      window.removeEventListener("refreshChat", privateCall);
      clearInterval(interval);
      clearInterval(interval2);
    };
  }, [user, page]);

  return (
    <>
      {open && (
        <div
          onClick={() => setOpen(false)}
          className="fixed inset-0 bg-[#000000AA] z-20"
        ></div>
      )}
      {open && (
        <div
          onClick={() => setOpen(false)}
          className="fixed top-5 right-10 -translate-x-[26rem] z-30 border-primary cursor-pointer border-2 p-2 rounded-full h-14 w-14 flex items-center text-primary justify-center"
        >
          <FontAwesomeIcon className="h-8 text-primary" icon={faClose} />
        </div>
      )}
      <div
        ref={ref}
        className={
          "fixed right-0 top-0 w-0 h-screen flex items-center transition-all z-30" +
          (open ? " -translate-x-[26rem]" : "")
        }
      >
        <OpenDesktopChats channels={openChannel.channels} />
        <div
          className="flex items-center justify-center w-16 h-16 right-0 bg-background border-2 border-r-0 rounded-l-lg border-border absolute cursor-pointer"
          onClick={() => {
            if (user?.informations?.type === "guest")
              return displayPopin()("login", {
                errorMessage: "terminated_guest_freetour",
              });
            setOpen(!open);
          }}
        >
          <img className="w-10 h-10" src={chat} alt="chat icon" />
          {user?.informations?.type === "guest" && (
            <div className="absolute right-3 bottom-3 bg-primary h-5 w-5 rounded-full flex items-center justify-center">
              <div className=" text-xs">1</div>
            </div>
          )}
          {user?.informations?.type !== "guest" &&
            counters.chat_message > 0 && (
              <div className="absolute right-3 bottom-3 bg-primary h-5 w-5 rounded-full flex items-center justify-center">
                <div className=" text-xs">{counters.chat_message}</div>
              </div>
            )}
        </div>
        <div className="absolute px-2 py-2 scroll--numbers  overflow-y-scroll bg-background left-full max-w-[26rem] w-screen border-l-2 border-border border-t-2 h-full rounded-t-md">
          <ChatTabs
            page={page}
            loadMore={async () => {
              const p = page + 1;
              setPage(p);
              let privateChats = await api_chatGetChannels(
                ["private"],
                p,
                true,
              );
              if (privateChats) setPrivate([...privateC!, ...privateChats]);
            }}
            publicC={publicC}
            privateC={privateC}
            filter={(id: string) => {
              let x = privateC!.filter((a) => a.id !== id);
              setPrivate(x);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default ChatDesktop;
