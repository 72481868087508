import React, { ChangeEvent } from 'react'

type Props = {
    label?: string | JSX.Element | React.ReactNode,
    placeholder?: string,
    value?: string,
    setValue?: (value: string) => void,
    name?: string,
    defaultValue?: string,
    type?: "password" | "text" | "textarea" | "email",
    ref?: any,
    id?: string,
    maxLength?: number,
    onChange?: (event:ChangeEvent<HTMLInputElement>|ChangeEvent<HTMLTextAreaElement>) => void,
    autoComplete?: string,
    pattern?: string,
    disabled?: boolean
}

function TextInput({
    label,
    name,
    placeholder,
    defaultValue,
    value,
    setValue,
    type = "text",
    ref,
    id,
    onChange,
    maxLength,
    pattern,
    disabled,
    autoComplete
}: Props) {
    if (type === "textarea") return (
        <div>
            <div className='text-sm text-label mb-1 label'>{label}</div>
            <textarea
                name={name}
                defaultValue={defaultValue}
                placeholder={placeholder}
                value={value}
                maxLength={maxLength}
                onChange={(e) => (onChange && onChange(e))|| (setValue && setValue(e.target.value))}
                disabled={disabled}
                className={'bg-input outline-none rounded-md h-40 p-4 w-full'}
            />
        </div>
    )
    return (
        <div>
            <div className='text-sm text-label mb-1 label'>{label}</div>
            <input
                id={id}
                ref={ref}
                type={type}
                name={name}
                defaultValue={defaultValue}
                placeholder={placeholder}
                value={value}
                maxLength={maxLength}
                onChange={(e) => (onChange && onChange(e))|| (setValue && setValue(e.target.value))}
                className={'bg-input outline-none rounded-md p-4 w-full'}
                disabled={disabled}
                autoComplete={autoComplete}
                pattern={pattern}
            />
        </div>
    )
}

export default TextInput