import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import useLocale from '../../Hook/locale';
import usePlatform from '../../Hook/platform';

function LocaleSelector({ headerPosition }: any) {
    const Platform = usePlatform();
    const locale = useLocale();
    const [show, setShow] = useState(false);
    if (!Platform?.platform) return null;

    const changeLang = (value: string) => {
        locale.changeLang(value);
    }


    return (
        <div onClick={() => setShow(!show)} className={'cursor-pointer relative outline-none px-4 py-2 bg-cards rounded-full text-center m-auto bg-transparent w-full lg:w-fit my-0 flex justify-between items-center' + (headerPosition ? " w-fit border-none" : "")}>
            <div className='flex gap-2 items-center justify-between w-full'>
                <FontAwesomeIcon className='w-3 text-primary' icon={faChevronDown} />
                <div className='flex gap-2 items-center'>
                    <img className='h-[12px]' src={`https://flagcdn.com/16x12/${locale.locale === "en" ? "us" : locale.locale}.png`} alt={"flag " + locale.locale} />
                </div>
            </div>
            {show && <div className="absolute z-[200] flex flex-col bottom-0 left-0 transform translate-y-full w-full p-4 rounded-xl bg-popinbg gap-2">
                {Platform.platform.languages.map(e => {
                    return <div onClick={() => changeLang(e.iso)} className='  flex gap-2 items-center'> <img className='h-[12px]' src={`https://flagcdn.com/16x12/${e.iso === "en" ? "us" : e.iso}.png`} alt={"flag " + e.iso} />{e.iso}</div>
                })}
            </div>}
        </div>
    )
}

export default LocaleSelector