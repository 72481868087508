import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
declare let __webpack_public_path__: string;

if ((window as any).__webpack_public_path__) {
  __webpack_public_path__ = (window as any).__webpack_public_path__;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <App />
);

document.addEventListener('contextmenu', function(e) {
  let tag = (e.target as HTMLElement).tagName;
  if(tag === "VIDEO" || tag === "IMG")
    e.preventDefault(); // Prevent right-click menu
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
