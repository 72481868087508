import { faCheck, faCoins, faCreditCardAlt, faHandshake } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { userTokensOffers_offers } from '../../Api/types'
import Button from '../../Component/Input/Button'
import { FormatPrice } from '../../Component/utils'

function TokensChooseOffer({ tokenOffers, index, setIndex, nextStep }: { nextStep: any, tokenOffers: userTokensOffers_offers[], index: number, setIndex: any }) {
  return (
    <div className='flex flex-col gap-4 px-4 lg:px-0'>
      <div className='text-xl text-center [&>*]:text-primary'><FormattedMessage id="popin__tokens_title" /></div>
      <div className='text-sm text-center [&>*]:text-primary'><FormattedMessage id="popin__tokens_features_title" /></div>
      <div className='grid grid-cols-2 gap-4'>
        {tokenOffers.map((e, i) => <div className={'bg-cards w-full relative border text-white p-4 overflow-hidden cursor-pointer flex flex-col gap-2 rounded-xl' + (index === i ? " border-primary" : " border-cards")} onClick={() => setIndex(i)}>
          {e.iframe_url && <div className='text-primary text-center w-full text-xs font-bold'>AUDIOTEL</div>}

          <div className='text-center bg-unactive py-1 rounded-full flex justify-between items-center px-4 font-bold'><span>+ <FormattedNumber value={e.tokens} /></span>
          <FontAwesomeIcon className=' text-yellow-800' icon={faCoins} /></div>
          <hr />
          <div className='flex gap-1 uppercase font-semibold justify-center'><FormatPrice currency={e.currency} price={e.price} /></div>
          <div className='bg-popinbg h-4 w-4 rounded-full m-auto relative'>
            {index === i && <><div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-primary opacity-50 h-6 w-6 rounded-full'>
              
            </div>
            <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center bg-primary h-4 w-4 rounded-full'>
                <FontAwesomeIcon className='h-3' icon={faCheck} />
              </div>
            </>}
            {index !== i && <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border-2 border-tabs h-5 w-5 rounded-full'>
              
              </div>}
          </div>
        </div>)}
      </div>
      <Button fillSpace name="ephemeviewer__next_epheme" color="primary" onClick={nextStep} />
      <div className='flex px-4  justify-between text-xs'>
        <div><FontAwesomeIcon className='mr-1' icon={faCreditCardAlt} /><FormattedMessage id="popin__upgrade_account_secured_payement" /></div>
        <div><FontAwesomeIcon className='mr-1' icon={faHandshake} /><FormattedMessage id="popin__upgrade_account_anonymous" /></div>
      </div>
      <div className='text-xs px-4  text-center'><FormattedMessage id="popin__upgrade_account_bank_note" /></div>
      <div className='text-xs px-4  text-center'><FormattedMessage id="popin__upgrade_account_service_provider" /></div>
    </div>
  )
}

export default TokensChooseOffer