import { faArrowLeft, faCrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { userSubscriptionOffers, userSubscriptionOffers_offers } from '../../Api/types';
import { api_postValueOrErrorMessage } from '../../Api/util';
import { closeCurrentPopin } from '../../Hook/popins';
import Audiotel from './Audiotel';
import CardsPicker from './CardsPicker';
import SubsChooseOffer from './SubsChooseOffer';

function Subscriptions(props:any) {
  const [subOffers, setSubOffers] = useState<userSubscriptionOffers_offers[]>([]);
  const [step, setStep] = useState(0);
  const [index, setIndex] = useState(0);
  const [card, setCard] = useState();
  const [error, setError] = useState();
  const [promo, setPromo] = useState(false);

  useEffect(() => {
    (async () => {
      let offers:any;
      if(props.code) offers = (await api_postValueOrErrorMessage<userSubscriptionOffers>("user/subscription/offers", props.code ? {code: props.code}: {}));
      let baseOffers = (await api_postValueOrErrorMessage<userSubscriptionOffers>("user/subscription/offers",{})).offers;
      if(!props.code) return setSubOffers(baseOffers);
      let final:any;
      if(offers.error_message){
        setError(offers.error_message)
        final = baseOffers;
      }else{
        if(offers.offers) setPromo(true)
        offers = offers.offers;
        final = offers;
      }
      setSubOffers(final);
    })()
  }, [])

  const tryCode = async (code:string) => {
    let offers:any = (await api_postValueOrErrorMessage<userSubscriptionOffers>("user/subscription/offers",{code: code}));
    if(offers.error_message) return offers.error_message;
    setSubOffers(offers.offers);
    setPromo(true)
  }

  const nextStep = () => setStep(step + 1);

  return (
    <>
      <div onClick={closeCurrentPopin} className="top-0 left-0 right-0 bottom-0 fixed bg-black opacity-60 z-40"></div>
      <div className="z-50 h-screen lg:max-w-2xl lg:rounded-xl w-screen lg:h-fit lg:pb-4 fixed max-h-[100dvh] scroll--numbers overflow-y-auto lg:top-1/2 lg:left-1/2 transform lg:-translate-x-1/2 lg:-translate-y-1/2 bg-chatbg rounded-xl flex flex-col">
        <div className='flex gap-4 border-b border-b-unactive p-4 bg-cards'>
          <FontAwesomeIcon icon={faArrowLeft} className='text-label h-7' onClick={closeCurrentPopin} />
          <div className='text-xl [&>*]:text-primary [&>*]:capitalize'><FormattedMessage id="popin__upgrade_account_title" /> <FontAwesomeIcon icon={faCrown} /></div>
        </div>
        <div className='flex flex-col gap-4'>
          {step === 0 && <SubsChooseOffer promo={promo} tryCode={tryCode} nextStep={nextStep} subOffers={subOffers} index={index} setIndex={setIndex} />}
          {step === 1 && (subOffers[index].iframe_url ? <Audiotel subOffer={subOffers[index]} index={index} setIndex={setStep} /> : <CardsPicker subOffer={subOffers[index]} setStep={setStep} />)}
        </div>
      </div>
    </>
  )
}

export default Subscriptions