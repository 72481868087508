import { FormattedMessage } from 'react-intl'
import Loader from '../../Component/Loader'

function Uploading() {
  return (
    <>
      <div className="top-0 left-0 right-0 bottom-0 fixed bg-black opacity-60"></div>
      <div className="z-50 p-8 lg:max-w-2xl lg:rounded-xl lg:rounded-xl bg-popinbg w-screen fixed max-h-[100dvh] scroll--numbers overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className='flex justify-center text-lg font-semibold'><FormattedMessage id="uploader__please_be_patient" /></div>
        <div className='flex justify-center mt-4'><Loader /></div>
      </div>
    </>
  )
}

export default Uploading