
import { closeCurrentPopin } from '../../Hook/popins'
import ProfileFanclub from '../../Page/Profile/Components/ProfileFanclub'

function Fanclub({member}:any) {
    return (
        <>
            <div onClick={closeCurrentPopin} className="top-0 left-0 right-0 bottom-0 fixed bg-black opacity-60 z-40"></div>
            <div className="z-50 p-8 lg:max-w-2xl lg:rounded-xl bg-popinbg w-screen fixed max-h-[100dvh] scroll--numbers overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <ProfileFanclub member={member} />
            </div>
        </>
    )
}

export default Fanclub