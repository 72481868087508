import { FormattedMessage } from "react-intl"
import { displayPopin } from "../../Hook/popins"
import Button from "../Input/Button"
import smiley from "./svg/smiley.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

function SubError({close, error, pseudo}: {close:any, error:any, pseudo:string}) {
    return (
        <div className="absolute bottom-0 left-0 right-0 z-50 rounded-t-3xl bg-cards p-4 flex flex-col gap-4 items-center">
            <div onClick={close} className="absolute top-4 right-6 text-xl"><FontAwesomeIcon icon={faClose} /></div>
            <div><img className="h-32" src={smiley} /></div>
            <div className='text-white whitespace-pre-wrap [&>*]:text-primary text-center mb-2'><FormattedMessage values={{member:pseudo}} id={"chaterror__label__" + error} /></div>
            <Button className={"text-white font-bold bg-primary"} appearance='full' color='white' onClick={() => {close();displayPopin()("subscribe")}} name="menu__subscribe"></Button>
        </div>
    )
}

export default SubError