import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { closeCurrentPopin } from "../../Hook/popins";
import { USER } from "../../Hook/user";
import TextInput from "../../Component/Input/TextInput";
import Loader from "../../Component/Loader";
import { membersInformations_members } from "../../Api/types";
import { fetchMembersFromIds } from "../../Api/member";
import Avatar from "../../Component/Avatar";
import Pseudonym from "../../Component/Member/Pseudonym";
import Button from "../../Component/Input/Button";

function ForceLoginPopin({ errorMessage, userID }: { errorMessage?: string, userID: string }) {
  const [error, setError] = useState(errorMessage);
  const [user, setUser] = useState<membersInformations_members|null>(null);
  const [password, setPassword] = useState("");
  const intl = useIntl();

  useEffect(() => {
    fetchMembersFromIds([userID]).then(members => {
      if(members.length) setUser(members[0]);
    });
  }, [])

  const login = async () => {
    const login = {
      login: user?.pseudonym,
      password,
      session_duration: "short"
    };
    let answer = await USER.login(login);
    if (answer.status === "error") {
      setError("login__wrong_credentials_error");
    } else {
      closeCurrentPopin();
    }
  }
  return (
    <>
      <div onClick={closeCurrentPopin} className="top-0  left-0 right-0 bottom-0 fixed bg-black opacity-60"></div>
      <form className="z-50 p-8 absolute top-1/2 w-full lg:max-w-sm left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-popinbg rounded-xl flex flex-col gap-4">
        {!user && <div className="flex justify-center"><Loader /></div>}
        {user && <>
          <h2 className="text-center text-primary text-2xl"><FormattedMessage id="menu__login" /></h2>
          <div className="w-20 h-20 mx-auto rounded-full overflow-hidden"><Avatar member={user} /></div>
          <div className="mx-auto"><Pseudonym member={user} /></div>
          <TextInput autoComplete="current-password" label={intl.formatMessage({ id: "login__password" })} type="password"
            setValue={setPassword} />
          {error && <h2 className="text-red-400 text-center"><FormattedMessage id={error === "terminated_guest_freetour" ? "guestpopin__connect_to_view_message" : error} /></h2>}
          <Button color="primary" onClick={login} className=" bg-primary"><FormattedMessage id="login__button_login" /></Button>
          <a href="/landing/011?redirection=onboarding&steps=007&visit_source=landingpage-011&fo=lp_011-ob_007" className="text-center text-xs "><FormattedMessage id="guestpopin__register_button" /></a>
        </>}
      </form>
    </>
  );
}

export default ForceLoginPopin;
