import { faFire, faRankingStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { FormattedMessage } from 'react-intl';
import { api_postValueOrErrorMessage } from '../../../Api/util';
import { PLATFORM } from '../../../Hook/platform';
import useUser from '../../../Hook/user';
import Button from '../../Input/Button';
import Pseudonym from '../../Member/Pseudonym';

function SimulatedGift({simulated, setSimulated}:any) {
  const user = useUser();
  return (<div className='w-60 max-w-[100%] m-auto my-0 bg-message flex flex-col gap-4 p-4 rounded-xl items-center'>
    <div className="[&>span]:text-primary text-center">
      <FormattedMessage  id={ "giftmessage__title_before_sending"} values={{ member: simulated.member.pseudonym }} />
    </div>
    <img width={64} height={64} src={simulated.gift.thumbnail} />
    <div className='h-0.5 w-full bg-border'></div>
    <div><Pseudonym member={simulated.member} /></div>
    <div className='flex justify-around w-full'>
      <div className='flex flex-col items-center text-2xl'>
        <FontAwesomeIcon icon={faFire} />
        <div>+{simulated.flames_given}</div>
      </div>
      <div className='flex flex-col items-center text-2xl'>
        <FontAwesomeIcon icon={faRankingStar} />
        <div>+{simulated.member_rank_before - simulated.member_rank_after}</div>
      </div>
    </div>
    <Button fillSpace onClick={() => {
      setSimulated(null);
      api_postValueOrErrorMessage("gifts/add", {id: simulated.gift.id, member: simulated.member.id});
    }} color="primary" name="giftmessage__send_button" />
  </div>)
}

export default SimulatedGift