import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "../../Component/Avatar";
import { FormattedMessage, useIntl } from "react-intl";
import { faCheckCircle, faCircleXmark, faEdit, faPen } from "@fortawesome/free-solid-svg-icons";
import TextInput from "../../Component/Input/TextInput";
import Button from "../../Component/Input/Button";
import { api_postValueOrErrorMessage } from "../../Api/util";
import { closeCurrentPopin } from "../../Hook/popins";
import { useRef, useState } from "react";
import useUser from "../../Hook/user";

export default function InformationEdit() {
    const intl = useIntl();
    const user = useUser();
    const inputRef = useRef<HTMLInputElement>(null);

    const [edit, setEdit] = useState<"pseudo" | "email" | "pwd" | "">("");

    const [pseudo, setPseudo] = useState(user?.informations?.pseudonym);
    const [email, setEmail] = useState(user?.informations?.email);
    const [newPwd, setNewPwd] = useState<string | undefined>('');
    const [pwd, setPwd] = useState('');
    const [errorInfos, setErrorInfos] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);

    const changePwd = () => {
        if (newPwd) {
            api_postValueOrErrorMessage('user/password/modify', {
                new_password: newPwd,
                old_password: pwd
            }).then((value) => {
                if (value.error_message) return setErrorInfos("popin__edit_error_" + value.error_message);
                setSuccess(true);
            });
        }
    }

    const changePseudo = () => {
        if (pseudo) {
            api_postValueOrErrorMessage('user/pseudonym/modify', {
                pseudonym: pseudo,
                password: pwd
            }).then((value) => {
                if (value.error_message) return setErrorInfos("popin__edit_error_" + value.error_message);
                setSuccess(true);
            });
        }
    }

    const changeEmail = () => {
        if (email) {
            api_postValueOrErrorMessage('user/email/modify', {
                email: email,
                password: pwd
            }).then((value) => {
                if (value.error_message) return setErrorInfos("popin__edit_error_" + value.error_message);
                setSuccess(true);
            });
        }
    }


    return <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-4 border border-border rounded-xl p-2'>
            <div className='relative w-28 h-28 m-auto'>
                <div className='w-28 h-28 overflow-hidden rounded-full'>
                    <Avatar member={user?.informations} />
                    <input onChange={(e) => e.target.files ? user?.changeAvatar(e.target.files[0]) : null} type="file" accept='image/*' className='hidden' ref={inputRef} />
                </div>
                <div onClick={() => inputRef.current?.click()} className='absolute bottom-0 right-0 flex justify-center items-center bg-primary w-10 h-10 rounded-full'><FontAwesomeIcon icon={faPen} /></div>
            </div>
            <div className=' text-xs rounded-xl'>
                <div className='p-2 flex items-center gap-4'>
                    <FontAwesomeIcon icon={faCheckCircle} className='text-light-green-600 h-5 bg-white rounded-full' />
                    <FormattedMessage id="popin__avatar_requirement_1" />
                </div>
                <div className='p-2 flex items-center gap-4'>
                    <FontAwesomeIcon icon={faCircleXmark} className='text-red-600 h-5 bg-white rounded-full' />
                    <FormattedMessage id="popin__avatar_requirement_2" />
                </div>
                <div className='p-2 flex items-center gap-4'>
                    <FontAwesomeIcon icon={faCheckCircle} className='text-light-green-600 h-5 bg-white rounded-full' />
                    <FormattedMessage id="popin__avatar_requirement_3" />
                </div>
            </div>
        </div>

        <div className='relative'>
            <TextInput disabled={edit !== "pseudo"} label={intl.formatMessage({ id: "popin__edit_profil_pseudo" })} value={pseudo} setValue={setPseudo} />
            {edit !== "pseudo" && <Button className={"absolute top-1/2 right-4 -translate-y-2 flex gap-2 items-center justify-center"} onClick={() => { setPwd(""); setEdit("pseudo"); setSuccess(false) }} appearance="outline" color='primary'><FormattedMessage id="popin__edit_change" /> <FontAwesomeIcon icon={faEdit} /></Button>}
        </div>
        {edit === "pseudo" && !success && <>
            <div>
                <TextInput type="password" label={intl.formatMessage({ id: "popin__edit_profil_old_password" })} setValue={setPwd} />
            </div>
            <div className="flex gap-2">
                <Button name="parameters__delete_account_decline" appearance="outline" onClick={() => { setPwd(""); setEdit(""); setErrorInfos(null); setPseudo(user?.informations?.pseudonym) }} color="primary" fillSpace />
                <Button name="popin__edit_profil_validate_button" onClick={changePseudo} color="primary" fillSpace />
            </div>
            {errorInfos && <div className='text-red-600 text-center'><FormattedMessage id={errorInfos} /></div>}
        </>}
        {success && edit === "pseudo" && <div className="text-center"><FormattedMessage id="popin__edit_profil_pseudo_success" /></div>}

        <div className='h-px w-full bg-tabs' />

        <div className='relative'>
            <TextInput disabled={edit !== "email"} label={intl.formatMessage({ id: "popin__edit_profil_mail" })} value={email} setValue={setEmail} />
            {edit !== "email" && <Button className={"absolute top-1/2 right-4 -translate-y-2 flex gap-2 items-center justify-center"} onClick={() => { setPwd(""); setEdit("email"); setSuccess(false) }} appearance="outline" color='primary'><FormattedMessage id="popin__edit_change" /> <FontAwesomeIcon icon={faEdit} /></Button>}
        </div>
        {edit === "email" && !success &&  <>
            <div>
                <TextInput type="password" label={intl.formatMessage({ id: "popin__edit_profil_old_password" })} setValue={setPwd} />
            </div>
            <div className="flex gap-2">
                <Button name="parameters__delete_account_decline" appearance="outline" onClick={() => { setPwd(""); setEdit(""); setErrorInfos(null); setEmail(user?.informations?.email) }} color="primary" fillSpace />
                <Button name="popin__edit_profil_validate_button" onClick={changeEmail} color="primary" fillSpace />
            </div>
            {errorInfos && <div className='text-red-600 text-center'><FormattedMessage id={errorInfos} /></div>}
        </>}
        {success && edit === "email" && <div className="text-center"><FormattedMessage id="popin__edit_profil_email_success" /></div>}

        <div className='h-px w-full bg-tabs' />

        <div className='relative'>
            <TextInput disabled={edit !== "pwd"} type="password" value={newPwd} label={intl.formatMessage({ id: "popin__edit_profil_new_password" })} setValue={setNewPwd} />
            {edit !== "pwd" && <Button className={"absolute top-1/2 right-4 -translate-y-2 flex gap-2 items-center justify-center"} onClick={() => { setPwd(""); setEdit("pwd"); setSuccess(false) }} appearance="outline" color='primary'><FormattedMessage id="popin__edit_change" /> <FontAwesomeIcon icon={faEdit} /></Button>}
        </div>
        {edit === "pwd" && !success &&  <>
            <div>
                <TextInput type="password" label={intl.formatMessage({ id: "popin__edit_profil_old_password" })} setValue={setPwd} />
            </div>
            <div className="flex gap-2">
                <Button name="parameters__delete_account_decline" appearance="outline" onClick={() => { setPwd(""); setEdit(""); setErrorInfos(null); setNewPwd("") }} color="primary" fillSpace />
                <Button name="popin__edit_profil_validate_button" onClick={changePwd} color="primary" fillSpace />
            </div>
            {errorInfos && <div className='text-red-600 text-center'><FormattedMessage id={errorInfos} /></div>}
        </>}
        {success && edit === "pwd" && <div className="text-center"><FormattedMessage id="popin__edit_profil_pwd_success" /></div>}



    </div>
}