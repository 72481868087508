import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, ReactNode, useState } from "react";
import useIsMobile from "../../Hook/mobile";

const FileDropArea = ({
  children,
  registerFile,
}: {
  children: ReactNode;
  registerFile: (file: File) => void;
}) => {
  const [dragging, setDragging] = useState(false);
  const mobile = useIsMobile();
  const handleDrop = useCallback((event: any) => {
    setDragging(false);
    event.preventDefault();
    const file: File = event.dataTransfer.files[0];
    if (file.type.startsWith("image/") || file.type.startsWith("video/"))
      registerFile(file);
  }, []);

  const handleDragOver = useCallback((event: any) => {
    event.preventDefault();
  }, []);

  const handleDragEnter = useCallback((event: any) => {
    setDragging(true);
    event.preventDefault();
  }, []);

  const handleDragLeave = useCallback((event: any) => {
    setDragging(false);
    event.preventDefault();
  }, []);
  if(mobile.isMobile) return children as any;
  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      className="relative h-full"
    >
      {children}
      {dragging && (
        <div
          onDragLeave={handleDragLeave}
          className="inset-0 absolute flex justify-center items-center bg-black bg-opacity-40"
        >
          <FontAwesomeIcon className="h-10 w-auto" icon={faUpload} />
        </div>
      )}
    </div>
  );
};

export default FileDropArea;
