import { faArrowRight, faHeart, faHeartCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage, FormattedRelativeTime, useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { challenges_challenges, ephemesInformations_ephemes } from '../../Api/types'
import { displayPopin } from '../../Hook/popins'
import useUser, { USER } from '../../Hook/user'
import Pseudonym from '../Member/Pseudonym'
import { PLATFORM } from '../../Hook/platform'
import { ReactComponent as UnderageLogo } from "./underageLogo.svg";
import Button from '../Input/Button'

function EphemeItem({ epheme, showPopin, shouldSnapLeft }: { epheme: ephemesInformations_ephemes, showPopin: any, shouldSnapLeft: boolean }) {
  const intl = useIntl();
  const user = useUser();

  let softOnly = false;
  if(user?.informations?.cohorts?.includes("agecontrol_a_ON")) softOnly = true;
  if(softOnly){
    if(user!.cards.length > 0) softOnly = false
    else if(user!.informations!.has_billing_transactions) softOnly = false
    else if(user?.informations?.content_provider) softOnly = false;
  }
  if(user!.informations!.type === "guest") softOnly = true;

  const navigate = useNavigate();
  let time = (epheme.time_published * 1000 - Date.now()) / 1000;
  if (time.toString() === "NaN") time = 0;

  return (
    <div onClick={() => {
      if (USER?.informations?.id === epheme.author) {
        return navigate("/myepheme/" + epheme.id);
      }
      if (epheme.thermometer !== "soft" && softOnly) {
        if (user!.informations?.type === "guest") return displayPopin()("login");
        return displayPopin()("underage");
      }
      if (epheme.type === "fanclub" && !epheme.authorInformation?.is_fan_user) {
        if (user!.informations?.type === "guest") return displayPopin()("login");
        return displayPopin()("fanclub", { member: epheme.authorInformation! })
      }
      showPopin();
    }}
      className={'w-full col-span-1 pb-1 lg:w-52 rounded-lg relative overflow-hidden cursor-pointer bg-background ' +
        (epheme.type === "challenge" ? " border border-primary" : "")
      }
    >

      <div className='relative'>
        <div className='absolute z-10 flex gap-2 items-center [&>*]text-base left-2 top-3'>
          <FontAwesomeIcon className='text-red-600' icon={faHeart} />
          <div className=' leading-[0] relative top-0.5'>{epheme.likes}</div>
        </div>
        <div className={'absolute text-xs z-10 top-2 right-2 min-w-[4.5rem] text-center border  bg-[#00000030] rounded-xl px-2 py-1 ' + `text-${epheme.thermometer} border-${epheme.thermometer}` + (epheme.type === "challenge" ? " left-2 right-auto !top-auto bottom-2" : "")}>{epheme.type === "challenge" ? intl.formatMessage({ id: "challenge__hashtag__" + epheme.challenge }) : intl.formatMessage({ id: "ephemeitem__thermometer_" + epheme.thermometer })}</div>
        {softOnly && epheme.thermometer !== "soft" && <div className='absolute flex flex-col gap-4 z-10 top-1/2 -translate-y-1/2 left-1/2 w-16 h-16 rounded-full justify-center items-center font-bold text-2xl -translate-x-1/2'>
          <div><UnderageLogo className='w-24 h-24' /></div>
          <div className='text-xs'><Button className={" whitespace-nowrap bg-[#00000022] border-[1pt] border-[#F8F8F8] rounded-full !py-2 !px-4 flex gap-4"} color='grey' onClick={() => {}}><FormattedMessage id="epheme__verify_majority" /><FontAwesomeIcon icon={faArrowRight}/></Button></div>
        </div>}
        {(!softOnly || epheme.thermometer === "soft") && epheme.type === "fanclub" && <div className='absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'><FontAwesomeIcon className='h-24 w-24 text-woman' icon={faHeartCircleExclamation} /></div>}
        <img className={'w-full aspect-[4/5]' + (softOnly && epheme.thermometer !== "soft" ? " blur-md" : "")} src={(epheme.type === "fanclub" || softOnly && epheme.thermometer !== "soft") ? epheme.thumbnail_preview : (epheme.thumbnail_selfie ?? epheme.thumbnail)} alt={epheme.title} />
        {epheme.type === "challenge" && <div className='absolute border border-gray-700 right-2 h-10 w-10 flex items-center justify-center rounded-full bg-cards bottom-2'>
          <img className='max-w-[80%] h-auto max-h-[80%]' src={`${(window as any).__webpack_public_path__}trophies-icon/trophie_${epheme.trophyId}.png`} />
        </div>}
      </div>
      <div className='text-xs mx-2  mt-2 overflow-hidden flex-col flex gap-2'>
        <Pseudonym online member={epheme.authorInformation} />
        <p className=' text-ellipsis whitespace-nowrap overflow-hidden'>{epheme.title}</p>
        <div className='text-[0.6rem] text-tabs'><FormattedRelativeTime value={time} numeric="auto" updateIntervalInSeconds={60} /></div>
      </div>
    </div>
  )
}

export default EphemeItem