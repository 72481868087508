import { blobToBase64, reduceImageSize } from "../utils";
import { userInformations } from "./types";
import { api_getValueOrErrorMessage, api_postValueOrErrorMessage } from "./util"

export const api_getUserInformations = async () => {
    return await api_getValueOrErrorMessage<userInformations>("user/informations")
}

export const api_postLogin = async (informations: any) => {
    return await api_postValueOrErrorMessage<any>("user/login", informations);
}

export const api_requestCertification = async (file: File) => {
    const blob = await reduceImageSize(file, 1500, 1500);
    const fileB64 = await blobToBase64(blob);
    return await api_postValueOrErrorMessage<any>("user/certification/request", {
        image: fileB64
    });
}

export const api_requestPwdReset = async (email:string) => {
    return await api_postValueOrErrorMessage<any>("user/password/request_reset", {
        email
    });
}

export const api_pwdReset = async (code:string, new_password:string) => {
    return await api_postValueOrErrorMessage<any>("user/password/reset", {
        code,
        new_password
    });
}