import { useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";

export default function UploadingBar({ xhr }: { xhr: XMLHttpRequest }) {
  const percent = useRef<HTMLSpanElement>(null);
  const progressBar = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let handler = (event: any) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        if (percent.current) {
          percent.current.innerHTML = percentComplete.toFixed(2) + "%";
          if (progressBar.current)
            progressBar.current.style.width = percentComplete + "%";
        }
        // Update your UI with the progress here, e.g.,
        // progressBar.value = percentComplete;
      }
      // Event listener for upload progress
    };
    xhr.upload.addEventListener("progress", handler);

    (xhr as any).startUpload();
  }, [xhr]);
  return (
    <div className="sticky top-0 left-0 right-0 z-50 px-4 py-4 bg-cards flex flex-col gap-2">
      <div className="text-center">
        <FormattedMessage id="uploader__please_be_patient" />{" "}
        <span ref={percent}>0%</span>
      </div>
      <div className="w-full bg-white h-2 rounded-full overflow-hidden">
        <div ref={progressBar} className="bg-primary w-0 h-full"></div>
      </div>
    </div>
  );
}
