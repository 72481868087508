import { faArrowDown, faHeart, faMessage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, FormattedRelativeTime, FormattedTime } from 'react-intl';
import { useNavigate } from 'react-router';
import angel from "../../Component/Filters/svg/angel.svg";
import cutie from "../../Component/Filters/svg/cutie.svg";
import { chatChannelsAdd, chatChannelsInformations, ephemesInformations_ephemes } from '../../Api/types';
import { api_postValueOrErrorMessage } from '../../Api/util';
import Avatar from '../../Component/Avatar';
import Loader from '../../Component/Loader';
import Pseudonym from '../../Component/Member/Pseudonym';
import { closeCurrentPopin, displayPopin } from '../../Hook/popins';
import useUser from '../../Hook/user';
import ProfileFanclub from '../../Page/Profile/Components/ProfileFanclub';
import ReportModal from './ReportModal';
import close from "./icons/close.svg";
import mutedV from "./icons/muted.svg";
import report from "./icons/report.svg";
import volume from "./icons/volume.svg";
import FollowButton from '../../Component/Input/FollowButton';
import { fetchMembersFromIds } from '../../Api/member';
import { OPEN_CHANNELS } from '../../Hook/openChannels';

let startPos = 0;
const threshold = 100; //100px scrolled minimum

function MobileEphemeViewer({ loadNext, fanclubError, currentEpheme, currentView }: { fanclubError: boolean, loadNext: any, currentEpheme: ephemesInformations_ephemes, currentView: any }) {
  const navigate = useNavigate();
  const [muted, setMuted] = useState(true);
  const [reportModal, setReportModal] = useState(false);
  const [liked, setLiked] = useState(false);
  const elRef = useRef<HTMLDivElement>(null);
  const divElement = useRef<HTMLDivElement>(null);
  const user = useUser()!;

  useEffect(() => {
    elRef.current!.style.transform = `translateY(0px)`;
    retrigger();
  }, [currentEpheme]);

  useEffect(() => {
    const yPos = window.scrollY;
    document.body.classList.add("prevent-scroll")
    return () => {
      document.body.classList.remove("prevent-scroll")
      window.scrollTo(0, yPos);
    }
  })

  const retrigger = () => {
    divElement.current?.classList.remove('fade-out');

    // // Trigger reflow to reset the animation
    void divElement.current?.offsetWidth;

    // // Re-add the fade-out class to retrigger the animation
    divElement.current?.classList.add('fade-out');
  }

  const endTouch = (event: any) => {
    let change = startPos - event.changedTouches[0].screenY;
    elRef.current!.style.transform = `translateY(0px)`
    if (change > threshold) {
      loadNext();
      setMuted(true)
      setLiked(false)
    }
  }

  const like = () => {
    if (user.informations?.type === "guest") {
      closeCurrentPopin();
      return displayPopin()("login", { errorMessage: "terminated_guest_freetour" });
    }
    api_postValueOrErrorMessage("ephemes/like", { id: currentEpheme.id });
    setLiked(true)
  }

  const startTouch = (event: any) => {
    startPos = event.changedTouches[0].screenY
  };

  const touchMove = (event: any) => {
    let change = startPos - event.changedTouches[0].screenY;
    if (change > 0) {
      if (change > threshold) {
        return elRef.current!.style.transform = `translateY(-${threshold}px)`
      }
      elRef.current!.style.transform = `translateY(-${change}px)`
    } else {
      elRef.current!.style.transform = `translateY(0px)`
    }
  }
  const chat = () => {
    api_postValueOrErrorMessage<chatChannelsAdd>("chat/channels/add", { members: [currentEpheme.authorInformation!.id] }).then(channel => {
      if (!channel.error_message)
        api_postValueOrErrorMessage<chatChannelsInformations>("chat/channels/informations", { channels: [channel.channel] }).then(async chan => {
          if (!chan.error_message) {
            let currentChan = chan.channels[0];
            let otherMember = currentChan.members.find(e => e !== user?.informations?.id);
            const member = await fetchMembersFromIds([otherMember as string]);
            currentChan.otherMember = member[0];
            OPEN_CHANNELS.openChannel(currentChan);
          }
        });
    })
  }

  let time = (currentEpheme.time_published * 1000 - Date.now()) / 1000;
  if (time.toString() === "NaN") time = 0;

  return (
    <div onTouchStart={startTouch} onTouchMove={touchMove} onTouchEnd={endTouch} className='z-50 fixed flex top-0 left-0 h-full max-h-[100dvh] right-0 bg-popinbg'>
      <div className='justify-center items-center w-full relative max-h-[100dvh]' >
        <div className='z-50 flex flex-col relative max-h-[100dvh]' style={{ height: "-webkit-fill-available" }}>
          <div className='absolute top-4 left-4 cursor-pointer z-50' onClick={closeCurrentPopin}><img src={close} alt="close" /></div>
          <div className='flex justify-around w-full bg-black'>
            <div className='flex-1'></div>
            <div onClick={() => {
              navigate("/member/" + currentEpheme.authorInformation?.id);
              closeCurrentPopin();
            }} className='h-16 flex-1 flex-shrink-0 relative z-40  flex justify-center items-center flex-col'>
              <div className='flex gap-2 items-center'>
                <Pseudonym member={currentEpheme.authorInformation} />
                {currentEpheme.authorInformation!.is_angel && <img src={angel} alt="angel" className="text-md" />}
                {currentEpheme.authorInformation!.is_cutie && <img src={cutie} alt="cutie" className="h-4" />}
              </div>
              {currentEpheme.authorInformation?.age_range && <div className='text-xs text-gray-400'><FormattedMessage id={"age_range__value_label__" + currentEpheme.authorInformation?.age_range} /></div>}
            </div>

            <div className='flex-1 text-xs flex justify-center'> <FollowButton member={currentEpheme.authorInformation!} /></div>
          </div>

          {!currentView && <Loader />}
          <div ref={elRef} onClick={retrigger} className='relative z-10 flex-1 flex items-center'>
            {fanclubError && <>
              <img className={'max-w-full max-h-full m-auto'} alt={currentEpheme.title} src={currentEpheme.thumbnail_preview} />
              <div className='absolute top-6 left-1/2 -translate-x-1/2 z-50 w-full px-4'>
                <ProfileFanclub member={currentEpheme.authorInformation!} />
              </div>
            </>}
            {!fanclubError && currentView && <>
              {currentView.photo ?
                <img onDoubleClick={like} className='max-w-full max-h-viewer m-auto' alt={currentEpheme.title} src={currentView.photo} onLoad={(event) => {

                }} />
                :
                <video onDoubleClick={like} className='max-w-full max-h-viewer m-auto' playsInline loop autoPlay muted={muted} src={currentView.video_hd} onLoadedData={(event) => {

                }} />
              }
              <div className={'[&>*]:text-red-600 left-12 absolute flex flex-col top-full transition-transform ' + (liked ? '-translate-y-[90%]' : 'translate-y-[90%]')}>
                <FontAwesomeIcon className=' h-12 ml-8 relative top-12' icon={faHeart} />
                <FontAwesomeIcon className=' h-14 ml-4 -left-8 top-8 relative' icon={faHeart} />
                <FontAwesomeIcon className=' h-8 ml-8 relative ' icon={faHeart} />
                <FontAwesomeIcon className=' h-12 ml-20 relative ' icon={faHeart} />
                <FontAwesomeIcon className=' h-12 -left-2 relative bottom-8' icon={faHeart} />
              </div>
            </>}
            <div className='absolute -bottom-20 left-1/2 -translate-x-1/2'>
              <FontAwesomeIcon className='h-10 w-10' icon={faArrowDown} />
            </div>
          </div>
          <div className='absolute right-4 bottom-24 flex flex-col gap-4 items-center z-50'>
            <div className='relative'>
              <div className='rounded-full h-12 w-12 overflow-hidden border-2 border-border relative' onClick={() => { navigate('/member/' + currentEpheme.authorInformation?.id); closeCurrentPopin() }}>
                <Avatar member={currentEpheme.authorInformation} />
              </div>
              {/* <div className='absolute w-5 h-5 -bottom-2 left-3.5 bg-red-700 flex items-center justify-center rounded-full'>+</div> */}
            </div>
            <div className='bg-black flex justify-center items-center rounded-full overflow-hidden p-2 h-[36px] w-[36px]' onClick={like}>
              <FontAwesomeIcon className={(liked ? 'text-red-600' : '') + ' h-[16px] w-[18px]'} icon={faHeart} />
            </div>
            <div className='bg-black flex justify-center items-center rounded-full overflow-hidden p-2 h-[36px] w-[36px]' onClick={chat}>
              <FontAwesomeIcon className={(liked ? 'text-red-600' : '') + ' h-[16px] w-[18px]'} icon={faMessage} />
            </div>
            <div onClick={() => {
              if (user.informations?.type === "guest") {
                closeCurrentPopin();
                return displayPopin()("login", { errorMessage: "terminated_guest_freetour" });
              }
              setReportModal(true)
            }} className='h-[36px] w-[36px] bg-black rounded-full flex justify-center items-center'>
              <img src={report} alt="report" />
            </div>
          </div>
          <div className='absolute left-4 bottom-24 flex flex-col gap-4 items-center z-50 '>
            <div onClick={() => setMuted(!muted)}><img className={currentView && currentView.video_hd ? '' : 'opacity-0'} src={muted ? mutedV : volume} alt="volume" /></div>
          </div>
          <div ref={divElement} className='absolute bottom-0 left-0 right-0 z-[100] opacity-100 bg-black bg-opacity-60 py-4 text-center'>
            <div>“{currentEpheme.title}”</div>
            <div className='text-xs mt-4'><FormattedRelativeTime value={time} numeric="auto" updateIntervalInSeconds={60} /></div>
          </div>
        </div>
        {reportModal && <ReportModal setReportModal={setReportModal} next={loadNext} id={currentEpheme.id} />}
      </div>
    </div>
  )
}

export default MobileEphemeViewer