import { useEffect } from "react";
import useIsMobile from "../Hook/mobile";

function Main({ children, noAds }: any) {
  const detectMobile = useIsMobile();
  useEffect(() => {
    (window as any).showAds2 && (window as any).showAds2();
  }, [])

  useEffect(() => {
    let topads = document.getElementById("top-mob-ads");
    if(!topads) return;
    if(noAds){
      topads.style.display = "none";
    }else{
      topads.style.display = "block";
    }
  }, [noAds])
  return (
    <div className='w-full flex flex-col gap-4 lg:px-4 relative pb-12 '>
      <div>
        {!noAds && <div className="flex justify-center items-center mt-4"><div id={detectMobile.isMobile ? "ad-300x100-m-home-1" : "ad-728x90-d-home-1"}></div></div>}
      </div>
      {children}
      {!noAds && <div className="flex justify-center items-center mb-4"><div id={detectMobile.isMobile ? "ad-300x100-m-home-2" : "ad-728x90-d-home-2"}></div></div>}
    </div>
  )
}

export default Main