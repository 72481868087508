import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '../../Component/Input/Button'
import { closeCurrentPopin, displayPopin } from '../../Hook/popins'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faChevronRight, faClose, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import bg from "./assets/bg.png";
import bgMobile from "./assets/bg-mobile.png";
import sad from "./assets/sad.png";
import sub from "./assets/sub.png";
import coins from "./assets/coins.png";
import useIsMobile from '../../Hook/mobile'

function PaymentSuccessful(props:any) {
  const detect = useIsMobile();
  return (
    <>
      <div onClick={closeCurrentPopin} className="top-0 left-0 right-0 bottom-0 fixed bg-black opacity-60 z-z-4010"></div>
      <div
        className={
          "bg-popinbg p-4 pb-6 scroll--numbers fixed lg:max-w-4xl left-1/2 top-1/2 z-50  flex max-h-[100dvh] w-screen -translate-x-1/2 -translate-y-1/2 transform flex-col overflow-y-auto lg:rounded-xl"
        }
      >
        <div className="flex items-center justify-between pt-4 px-4 lg:px-8 lg:pt-2">
          <div></div>
          <h2 className="flex-1 text-lg font-bold text-center">
            <FormattedMessage id="popin__payment_ok_title" />
          </h2>
          <FontAwesomeIcon onClick={closeCurrentPopin} icon={faClose} />
        </div>
        <div className='text-green text-center mt-4'><FontAwesomeIcon className='h-12 w-12' icon={faCheckCircle} /></div>
        <div className='p-4'>
          <p><FormattedMessage id="popin__payment_ok_message" values={{
            currency: props.currency,
            amount: props.amount
          }} /></p>
        </div>
        <Button color='primary' onClick={closeCurrentPopin} appearance='outline'><FormattedMessage id="underage__close" /></Button>
      </div>
    </>
  )
}

export default PaymentSuccessful