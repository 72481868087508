import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input } from '@material-tailwind/react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { api_getValueOrErrorMessage } from '../../Api/util'
import Button from '../../Component/Input/Button'
import { closeCurrentPopin, displayPopin } from '../../Hook/popins'
import useUser from '../../Hook/user'
import TextInput from '../../Component/Input/TextInput'

function EmailConfirm() {
    const user = useUser()!;
    const intl = useIntl();
    const [email, setEmail] = useState(user.informations?.email);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState<null|string>(null);
    return (
        <>
            <div onClick={closeCurrentPopin} className="top-0 left-0 right-0 bottom-0 fixed bg-black opacity-60 z-z-4010"></div>
            <div className="z-50 text-sm p-8 lg:rounded-xl lg:max-w-2xl bg-popinbg w-screen fixed max-h-[100dvh] scroll--numbers overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-4">
                <h2 className='border-primary px-2 py-1 border-2 text-base rounded font-bold text-center flex items-center justify-center gap-2'><FontAwesomeIcon icon={faEnvelope} /><FormattedMessage id="emailverifypage__title" /></h2>
                <p className="whitespace-pre-wrap"><FormattedMessage id="emailverifypage__description" /></p>
                <TextInput disabled label={intl.formatMessage({id:"register__form_email"})} type="email" value={email}></TextInput>

                <Button color="primary" onClick={() => {
                    api_getValueOrErrorMessage("user/email/send_confirmation_message").then(e => {
                        if(e.error_message) return setError(e.error_message);
                        return setSuccess(true);
                    })
                }}>
                    <FormattedMessage id="emailverifypage__resend_verif" />

                </Button>
                {error && <div className=' text-red-600 text-center'><FormattedMessage id={error} /></div>}
                {success && <div className=' text-green text-center'><FormattedMessage id={"emailverifypage__verif_mail_sent"} /></div>}
                <p className='text-center'><FormattedMessage id="emailverifypage__description2" /></p>
                <div className='text-center underline' onClick={() => {
                    closeCurrentPopin();
                    displayPopin()("contact");
                }}><FormattedMessage id="emailverifypage__link_contact" /></div>
            </div>
        </>
    )
}

export default EmailConfirm