import { Input } from '@material-tailwind/react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { api_postValueOrErrorMessage } from '../../Api/util';
import Button from '../../Component/Input/Button';
import { closeCurrentPopin } from '../../Hook/popins';
import Select from '../../Component/Select';
import TextInput from '../../Component/Input/TextInput';

function ChatReport(props: any) {
    const intl = useIntl();
    const [currentReport, setCurrentReport] = useState(intl.formatMessage({ id: "reportpopin__body_reason1" }));
    const [otherReason, setOtherReason] = useState("")
    const other = currentReport === intl.formatMessage({ id: "reportpopin__body_other_reason" });

    return (
        <>
            <div onClick={closeCurrentPopin} className="top-0 left-0 z-[110] right-0 bottom-0 fixed bg-black opacity-60"></div>
            <div className="z-[120] p-8 lg:max-w-2xl bg-popinbg w-screen fixed max-h-[100dvh] flex flex-col gap-4 scroll--numbers top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">

                <div className='text-center text-xl'><FormattedMessage id="reportpopin_chat__title" values={{ member: props.member.pseudonym }} /></div>
                <hr className='mb-2' />
                <div><FormattedMessage id="reportpopin__body_text" /></div>
                <Select
                    options={[
                        {
                            value: intl.formatMessage({ id: "reportpopin__body_reason1" }),
                            message: intl.formatMessage({ id: "reportpopin__body_reason1" })
                        },
                        {
                            value: intl.formatMessage({ id: "reportpopin__body_reason3" }),
                            message: intl.formatMessage({ id: "reportpopin__body_reason3" })
                        },
                        {
                            value: intl.formatMessage({ id: "reportpopin__body_other_reason" }),
                            message: intl.formatMessage({ id: "reportpopin__body_other_reason" })
                        },
                    ]}
                    onChange={v => setCurrentReport(v)}
                />
                {other && <TextInput value={otherReason} onChange={(event) => setOtherReason(event.target.value)} label={intl.formatMessage({ id: "reportpopin__body_other_reason" })} />}
                <div className='flex gap-2 justify-center'>
                    <Button color="primary" appearance='outline' onClick={closeCurrentPopin}><FormattedMessage id="reportpopin__cancel_button" /></Button>
                    <Button color="primary" appearance='outline' onClick={() => {
                        api_postValueOrErrorMessage("chat/report", {
                            member: props.member.id,
                            channel: props.channel,
                            message: other ? otherReason : currentReport
                        });
                        if(props.cb) props.cb();
                        closeCurrentPopin()
                    }}><FormattedMessage id="reportpopin__report_button" /></Button>
                </div>
            </div>
        </>
    )
}

export default ChatReport