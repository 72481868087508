import { locationCountriesInformations, locationCountriesInformations_countries, membersInformations, membersInformations_members } from "../types";

const cachedCountries:{[key:number]:locationCountriesInformations_countries} = {};

export const countryInformationsFilterFetchingList = (countriesIDs: number[]) => {
  const notInMemory:number[] = [];
  const inMemory:{[key:string]:locationCountriesInformations_countries} = {};

  for(let countrieID of countriesIDs)
  {
    if(cachedCountries[countrieID])
    {
      inMemory[countrieID] = cachedCountries[countrieID];
    }
    else
    {
      notInMemory.push(countrieID);
    }
  }
  
  return {notInMemory, inMemory};

}

export const cacheCountries = (countries: locationCountriesInformations) => {
  for(let country of countries.countries){
    cachedCountries[country.id] = country;
  }
}

export default {}