import {
  cacheMembers,
  memberInformationsFilterFetchingList,
} from "./cache/member";
import { getCitiesInformations, getCountryInformations } from "./location";
import {
  membersFollowedInput,
  membersInformations,
  membersInformations_members,
  membersOnline,
  membersRelation,
  membersSearchInput,
  trophiesMember,
} from "./types";
import { api_postValueOrErrorMessage } from "./util";

type memberEndpoint =
  | "blocked"
  | "fans"
  | "followers"
  | "followed"
  | "highlighted"
  | "profile_visitors"
  | "relations"
  | "search"
  | "top"
  | "top_flames_day"
  | "likes";
export const fetchMembers = async (
  endpoint: memberEndpoint,
  payload: membersFollowedInput | membersSearchInput,
) => {
  const members =
    endpoint === "likes"
      ? await api_postValueOrErrorMessage<
        { members: any[] } | { members: string[] }
      >(`ephemes/${endpoint}`, payload)
      : await api_postValueOrErrorMessage<
        { members: any[] } | { members: string[] }
      >(`members/${endpoint}`, payload);
  let ids: string[] = members.members.map((e) =>
    typeof e === "object" ? e.id : e,
  );
  const memberCacheResults = memberInformationsFilterFetchingList(ids);
  const newlyFetched = memberCacheResults.notInMemory.length
    ? await api_postValueOrErrorMessage<membersInformations>(
      `members/informations`,
      { members: memberCacheResults.notInMemory },
    )
    : { members: [] };

  cacheMembers(newlyFetched);

  let online = await getOnlineStatus(ids);
  // newlyFetched.members.forEach((e, i) => {
  //   e.is_online = online[i].is_online;
  //   e.time_online = online[i].time_online;
  // });

  const returnArray: membersInformations_members[] = [];
  let index = 0;
  for (let member of ids) {
    let onlineStatus = online.find(e => e.id === member)!;
    if (memberCacheResults.inMemory[member]) {
      memberCacheResults.inMemory[member].is_online = onlineStatus.is_online;
      memberCacheResults.inMemory[member].time_online = onlineStatus.time_online;
      returnArray.push(memberCacheResults.inMemory[member]);
    } else {
      newlyFetched.members[index].is_online = onlineStatus.is_online;
      newlyFetched.members[index].time_online = onlineStatus.time_online;
      returnArray.push(newlyFetched.members[index]);
      index++;
    }
  }


  let countryIDS = returnArray.map((e) => e.country_id);
  countryIDS = Array.from(new Set(countryIDS));

  let countries = await getCountryInformations(countryIDS);
  returnArray.forEach((e, i) => {
    e.countryInformations = countries.find(
      (country) => country.id === e.country_id,
    );
  });

  let citiesIDs = returnArray.map((e) => e.city_id);
  let set = new Set(citiesIDs);
  set.delete(undefined);
  citiesIDs = Array.from(set);

  let cities = await getCitiesInformations(citiesIDs as any);
  returnArray.forEach((e, i) => {
    e.cityInformations = cities.find((city) => city.id === e.city_id);
  });

  if (typeof members.members[0] === "object") {
    for (let fetchedMember of returnArray) {
      let member = members.members.find((e) => e.id === fetchedMember.id);
      fetchedMember.common_boost = member.boost_common;
      fetchedMember.common_flames = member.flames;
    }
  }

  return returnArray;
};

export const fetchMembersFromIds = async (ids: string[]) => {
  const memberCacheResults = memberInformationsFilterFetchingList(ids);
  const newlyFetched = memberCacheResults.notInMemory.length
    ? await api_postValueOrErrorMessage<membersInformations>(
      `members/informations`,
      { members: memberCacheResults.notInMemory },
    )
    : { members: [] };

  cacheMembers(newlyFetched);

  const returnArray: membersInformations_members[] = [];
  for (let member of ids) {
    if (memberCacheResults.inMemory[member]) {
      returnArray.push(memberCacheResults.inMemory[member]);
    } else {
      returnArray.push(
        newlyFetched.members.find(
          (e) => e.id === member,
        ) as membersInformations_members,
      );
    }
  }

  let countryIDS = returnArray.map((e) => e.country_id);
  countryIDS = Array.from(new Set(countryIDS));

  let countries = await getCountryInformations(countryIDS);
  returnArray.forEach((e, i) => {
    e.countryInformations = countries.find(
      (country) => country.id === e.country_id,
    );
  });

  return returnArray;
};

export async function getRelation(id: string) {
  let returnValue = await api_postValueOrErrorMessage<membersRelation>(
    `members/relation`,
    { id },
  );
  return returnValue;
}

export async function getTrophies(id: string, type: "classic" | "challenge") {
  return (
    await api_postValueOrErrorMessage<trophiesMember>(`trophies/member`, {
      id,
      types: [type],
    })
  ).trophies;
}

export async function getOnlineStatus(ids: string[]) {
  return (
    await api_postValueOrErrorMessage<membersOnline>(`members/online`, {
      members: ids,
    })
  ).members;
}
