import { faClose, faCoins, faRocket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getRelation } from '../../Api/member';
import { chatChannelsInformations_channels, membersInformations_members } from '../../Api/types';
import { api_postValueOrErrorMessage } from '../../Api/util';
import { displayPopin } from '../../Hook/popins';
import useUser from '../../Hook/user';
import Avatar from '../Avatar';
import Button from '../Input/Button';

function TokenBar({ member, setSimulated, channel, close }: { close: any, channel: chatChannelsInformations_channels, member: membersInformations_members | undefined, setSimulated: any }) {
    const user = useUser()!;
    const [commonBoost, setCommonBoost] = useState<any>({});
    const inputValue = useRef<any>(undefined);
    useEffect(() => {
        if (!member) return;
        getRelation(member.id).then((e) => {
            setCommonBoost(e);
        })
    }, [member])

    return (
        <div className='absolute top-0 left-0 bottom-0 right-0 z-50 bg-background px-2 py-4 flex flex-col gap-4'>
            <div className='text-right px-4' onClick={close}><FontAwesomeIcon className='text-messageplaceholder text-2xl' icon={faClose} /></div>
            <div className='flex items-center justify-center'>
                <div className='w-12 h-12 rounded-full overflow-hidden relative left-1'><Avatar member={member} /></div>
                <div className='w-14 h-14 rounded-full overflow-hidden relative right-1 border-4 border-background'><Avatar member={user.informations} /></div>
            </div>
            <div className='py-4 text-center w-full [&>*]:text-primary text-xs flex gap-2 justify-center'>
                <FormattedMessage id="gifttray__header_common_boost" values={{ member: member?.pseudonym }} />
            </div>
            <div className='w-fit mx-auto'>
                <div className="flex gap-4 items-center bg-chatheader px-4 py-2 rounded-full"><FontAwesomeIcon className='text-man' icon={faRocket} />+{commonBoost.boost_common}%</div>
            </div>
            <div className=''>
                <div><input onChange={e => inputValue.current.innerHTML = e.target.value} min={200} max={10000} defaultValue={200} className='w-full  accent-primary' type="range" /></div>
                <div className='flex justify-center items-center gap-2'>
                    <div className='' ref={inputValue}>200</div>
                    <FontAwesomeIcon className=' text-yellow-400' icon={faCoins} />
                </div>
                <Button className={"mt-4"} fillSpace name="tipmessage__send_button" color='primary' onClick={() => {
                    let coins = Number(inputValue.current.innerHTML);
                    close();
                    api_postValueOrErrorMessage<any>("tips/simulate", {
                        amount: Number(inputValue.current.innerHTML),
                        member: member?.id
                    }).then(answer => {
                        if (answer.error_message) return displayPopin()("tokens");
                        else setSimulated({ ...answer, coins, member })
                    })
                }}/>
            </div>
        </div>
    )
}

export default TokenBar