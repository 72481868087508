import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { api_postValueOrErrorMessage } from '../../Api/util';
import { closeCurrentPopin } from '../../Hook/popins';

function ChatContent(props: any) {
    const [view, setView] = useState<null|string>(null);
    const [error, setError] = useState<null|string>(null);
    const content = props.content;

    useEffect(() => {
        api_postValueOrErrorMessage<{content: string}>("chat/contents/view", {content: content.id}).then(e => {
            if(e.error_message) setError(e.error_message);
            else{
                setView(e.content);
            }
        })
    }, []);
    return (
        <>
            <div onClick={closeCurrentPopin} className="top-0 left-0 z-[110] right-0 bottom-0 fixed bg-black opacity-60"></div>
            <div className="z-[120] p-8 lg:max-w-2xl bg-popinbg w-screen fixed max-h-[100dvh] flex flex-col gap-4 scroll--numbers overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">

                <div onClick={closeCurrentPopin} className='absolute top-3 right-3'><FontAwesomeIcon icon={faClose} /></div>
                {content.type === "video" &&  view && <video src={view} autoPlay playsInline/>}
                {content.type !== "video"  && view && <img src={view ?? content.thumbnail} />}
                {!view && <img src={content.thumbnail} />}
                {error && <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-primary px-2 w-max py-1 rounded-xl'><FormattedMessage id={error} /></div>}
            </div>
        </>
    )
}

export default ChatContent