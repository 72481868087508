import { locationCitiesInformations, locationCitiesInformations_cities } from "../types";

const cachedCities: { [key: number]: locationCitiesInformations_cities } = {};

export const cityInformationsFilterFetchingList = (CitiesIDs: number[]) => {
    const notInMemory: number[] = [];
    const inMemory: { [key: string]: locationCitiesInformations_cities } = {};

    for (let countrieID of CitiesIDs) {
        if (cachedCities[countrieID]) {
            inMemory[countrieID] = cachedCities[countrieID];
        }
        else {
            notInMemory.push(countrieID);
        }
    }

    return { notInMemory, inMemory };

}

export const cacheCities = (Cities: locationCitiesInformations) => {
    for (let country of Cities.cities) {
        cachedCities[country.id] = country;
    }
}

export default {}