import { useEffect, useState } from "react";
import BaseHookWithUpdaters from "./baseHookWithUpdaters";
import {PLATFORM} from "./platform";
class Locale extends BaseHookWithUpdaters {
  locale = ''
  translation:any = {}
  cachedTranslations:any = {}
  changeLang = async (locale:string):Promise<any> => {
    if(this.locale === locale) return;
    if(this.cachedTranslations[locale]){
      this.translation = this.cachedTranslations[locale];
      this.locale = locale;
      localStorage.setItem("lang", locale);
      return this.update()
    }

    let translationJSON:any = PLATFORM.platform?.languages.find(e => e.type === "front" && e.iso === locale);
    if(!translationJSON) return this.changeLang("en");
    try{
      let translation = await (await fetch(translationJSON.translations_file)).json();
      this.translation = translation;
      this.cachedTranslations[locale] = translation;

      this.locale = locale;
      localStorage.setItem("lang", locale);
      return this.update();
    }catch(err){
      console.error(err);
    }
  }
}

export const LOCALE = new Locale();

function useLocale() {
    const [locale, setLocale] = useState<Locale>(LOCALE);

    useEffect(() => {
      LOCALE.register(setLocale);
        return () => {
          LOCALE.unregister(setLocale);
        }
    }, [])


    return locale;
}

export default useLocale;