
import { FormattedMessage } from 'react-intl'
import { userSubscriptionOffers_offers } from '../../Api/types'
import Button from '../../Component/Input/Button'

function Audiotel({ subOffer, index, setIndex }: { subOffer: userSubscriptionOffers_offers, index: number, setIndex: any }) {
    return (
        <>
            <iframe className='w-full max-w-md m-auto' height="480px" width="100%" src={subOffer.iframe_url} />
        </>
    )
}

export default Audiotel