import { faFire, faHome, faMessage, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';
import useNotification from '../Hook/notification';
import { displayPopin } from '../Hook/popins';
import useUser from '../Hook/user';

let prevY = 0;

function MenuMobile() {
  const navigate = useNavigate();
  const user = useUser();
  const notification = useNotification();
  const location = useLocation();

  // useEffect(() => {
  //   console.log("here")
  //   const listener = () => {
  //     console.log("here");
  //     document.getElementById("bottom-bar")?.classList.toggle("hidden", window.scrollY > 30 && window.scrollY > prevY);
  //     prevY = window.scrollY;
  //   }
  //   window.addEventListener("scroll", listener);
  //   return () => window.removeEventListener("scroll", listener);
  // }, []);

  return (
    <div className='lg:hidden'>
      <div id="bottom-bar" className='h-16 w-full fixed z-40 bg-cards border-t border-unactive left-0 bottom-0 flex [&>*]:flex-1 gap-x-[1px]'>
        <div onClick={() => {
          if(location.pathname === "/") window.scrollTo({top: 0, left: 0, behavior: "smooth"})
          else navigate("/")
        }} className={'h-full flex flex-col justify-center items-center text-messageplaceholder gap-1 ' +
          (location.pathname === "/" ? ' !text-primary' : '')
        }>
          <FontAwesomeIcon className='w-1/3 h-1/3' icon={faHome} />
          <div className='text-xs'><FormattedMessage id="menu__home" /></div>
        </div>
        <div onClick={() => {
          if(location.pathname === "/members") window.scrollTo({top: 0, left: 0, behavior: "smooth"})
            else navigate("/members")
        }} className={'h-full flex flex-col justify-center items-center text-messageplaceholder whitespace-nowrap gap-1 ' +
          (location.pathname === "/members" ? ' !text-primary' : '')
        }>
          <FontAwesomeIcon className='w-1/3 h-1/3' icon={faUsers} />
          <div className='text-xs'><FormattedMessage id="menu_desktop__members" /></div>
        </div>
        <div onClick={() => {
          if(user?.informations?.type === "guest") return displayPopin()("login", { errorMessage: "terminated_guest_freetour" })
          navigate("/uploader")
        }} className={'h-full flex flex-col justify-center items-center text-messageplaceholder gap-1 ' +
          (location.pathname === "/uploader" ? ' !text-primary' : '')
        }>
          <div className='h-10 w-10 flex justify-center items-center border-2 rounded-full border-messageplaceholder'>
            <div className='text-2xl'>+</div>
          </div>
        </div>
        <div onClick={() => {          
          if(location.pathname === "/top") window.scrollTo({top: 0, left: 0, behavior: "smooth"})
          else navigate("/top")
        }} className={'h-full flex flex-col justify-center items-center text-messageplaceholder gap-1 ' +
          (location.pathname === "/top" ? ' !text-primary' : '')
        }>
          <FontAwesomeIcon className='w-1/3 h-1/3' icon={faFire} />
          <div className='text-xs'><FormattedMessage id="menu__top_and_progress" /></div>
        </div>
        <div onClick={() => {
          if(location.pathname === "/chat") window.scrollTo({top: 0, left: 0, behavior: "smooth"})
          else navigate("/chat")
        }} className={'h-full flex flex-col justify-center relative items-center text-messageplaceholder gap-1 ' +
          (location.pathname === "/chat" ? ' !text-primary' : '')
        }>
          <FontAwesomeIcon className='w-1/3 h-1/3' icon={faMessage} />
          <div className='text-xs'><FormattedMessage id="menu__chat" /></div>
          {notification.chat_message > 0 && <div className='absolute bg-green text-white h-4 w-4 rounded-full flex items-center justify-center top-2 right-5 text-xs'>{notification.chat_message}</div>}
        </div>
      </div>
    </div>
  )
}

export default MenuMobile