import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Filters from '../../Component/Filters/Filters'
import Button from '../../Component/Input/Button'
import TextInput from '../../Component/Input/TextInput'
import MemberList from '../../Component/MemberList'
import Content from '../Content'
import Footer from '../Footer'
import Header from '../Header'
import Main from '../Main'
import MenuMobile from '../MenuMobile'

function Search() {
  const [search, setSearch] = useState("")
  const intl = useIntl();
  return (
    <>
      <Header />
      <Content>
        <Main>
          <div className='max-w-5xl w-full mx-auto my-0 flex flex-col gap-8 px-4'>
            <h2 className='text-center text-primary text-2xl'><FormattedMessage id="menu__search" /></h2>
            <Filters />
            <TextInput label={intl.formatMessage({id:"search__input_placeholder"})} setValue={setSearch} id="search-input" />
             <Button color='primary' name="search__button" onClick={() => {
              setSearch((document.getElementById("search-input") as HTMLInputElement)?.value)
             }}/>
            {search !== "" && <MemberList 
              endpoint='search'
              additionalInputs={{ 
                text:search
               }}
            />}
          </div>
        </Main>
      </Content>
      <Footer />
      <MenuMobile />
    </>
    )
}

export default Search