
import { faClose, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "@material-tailwind/react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import Button from "../../Component/Input/Button";
import TextInput from "../../Component/Input/TextInput";
import { closeCurrentPopin } from "../../Hook/popins";
import { USER } from "../../Hook/user";
const ACheckbox = Checkbox as any;
function LoginPopin({ errorMessage }: { errorMessage?: string }) {
  const [error, setError] = useState(errorMessage);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPwd, setShowPwd] = useState(false);
  const [chk, setChk] = useState(true);
  const intl = useIntl();
  const login = async () => {

    const login = {
      login: username,
      password,
      session_duration: chk ? "long" : "short"
    };
    let answer = await USER.login(login);
    if (answer.status === "error") {
      setError("login__wrong_credentials_error");
    } else {
      closeCurrentPopin();
    }
  }
  return (
    <>
      <div onClick={closeCurrentPopin} className="top-0  left-0 right-0 bottom-0 fixed bg-black opacity-60"></div>
      <form className="z-50 p-8 fixed top-1/2 w-full lg:max-w-sm left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-popinbg rounded-xl flex flex-col gap-4">
        <div onClick={closeCurrentPopin} className="absolute top-0 right-10 -translate-y-1/2 h-8 w-8 bg-white flex justify-center items-center rounded-full"><FontAwesomeIcon className="text-black" icon={faClose} /></div>

        <h2 className="text-center text-primary text-2xl"><FormattedMessage id="menu__login" /></h2>
        <TextInput autoComplete="username" label={intl.formatMessage({ id: "login__username" })}
          setValue={setUsername} />
        <div className="relative"><TextInput autoComplete="current-password" label={intl.formatMessage({ id: "login__password" })} type={showPwd ? "text" : "password"}
          setValue={setPassword} />
          {password.length > 0 && <div className="absolute right-5 bottom-4 cursor-pointer" onClick={() => setShowPwd(p => !p)}><FontAwesomeIcon icon={showPwd ? faEyeSlash : faEye} /></div>}
        </div>
        <h3 className=" text-right text-xs"><Link onClick={() => closeCurrentPopin()} to="forgotPassword"><FormattedMessage id="login__forgot_your_password" /></Link></h3>
        <ACheckbox defaultChecked onChange={() => setChk(e => !e)} label={intl.formatMessage({ id: "login__checkbox_long_session" })} />
        {error && <h2 className="text-red-400 text-center"><FormattedMessage id={error === "terminated_guest_freetour" ? "guestpopin__connect_to_view_message" : error} /></h2>}
        <Button color="primary" onClick={login} className=" bg-primary"><FormattedMessage id="login__button_login" /></Button>
        <a href="/landing/011?redirection=onboarding&steps=007&visit_source=landingpage-011&fo=lp_011-ob_007" className="text-center text-xs "><FormattedMessage id="guestpopin__register_button" /></a>
      </form>
    </>
  );
}

export default LoginPopin;
