import React, { useEffect } from 'react'
import Toast from '.';
import { displayToast, useToast } from '../../Hook/toast';
import useUser from '../../Hook/user';

function ToastHolder() {
  const toast = useToast();

  useEffect(()=>{
    (window as any).showToast = (user:any, title:string, message:string, type:any) =>  displayToast(user, title, message, type)
   
  }, [])
  const CurrentToast = toast.toast[0];
  return (
    <div className='fixed bottom-20 z-40 px-4 w-screen'>
      {CurrentToast}
    </div>
  )
}

export default ToastHolder