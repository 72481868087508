import { FormattedMessage, useIntl } from "react-intl";
import Button from "../../Component/Input/Button";
import TextInput from "../../Component/Input/TextInput";
import Content from "../Content";
import Footer from "../Footer";
import Header from "../Header";
import Main from "../Main";
import MenuMobile from "../MenuMobile";
import { useState } from "react";
import { api_requestPwdReset } from "../../Api/user";

export default function ForgotPassword() {
    const intl = useIntl();
    const [answer, setAnswer] = useState<null | "error" | "ok">(null);

    return <>
        <Header />
        <Content>
            <Main>
                <div className="max-w-3xl mx-auto w-full flex flex-col gap-4 px-8">
                    <h2><FormattedMessage id="forgotpassword__head_title" /></h2>
                    {answer !== "ok" && <>
                        <TextInput id="email-input" type="email" placeholder={intl.formatMessage({ id: "forgotpassword__username" })} />
                        <Button name="forgotpassword__recover_button" color="primary" onClick={() => {
                            let email = ((document.getElementById("email-input") as HTMLInputElement).value)
                            api_requestPwdReset(email).then(e => {
                                setAnswer(e.status);
                            })
                        }} />
                    </>}
                    <div className="text-center">
                        {answer === "error" && <FormattedMessage id="login__wrong_credentials_error" />}
                        {answer === "ok" && <FormattedMessage id="forgotpassword__reset_password_success" />}
                    </div>
                </div>
            </Main>
        </Content>
        <Footer />
        <MenuMobile />
    </>
}