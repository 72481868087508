import {
  faArrowLeft,
  faCheck,
  faChevronDown,
  faChevronRight,
  faCoins,
  faCrown,
  faDice,
  faEnvelope,
  faEye,
  faGear,
  faHeart,
  faImagePortrait,
  faInfo,
  faInfoCircle,
  faMagnifyingGlass,
  faPen,
  faUser,
  faUserFriends,
  faUserXmark,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import Avatar from "../Component/Avatar";
import Button from "../Component/Input/Button";
import LocaleSelector from "../Component/LocaleSelector/LocaleSelector";
import Pseudonym from "../Component/Member/Pseudonym";
import certified from "../Component/Member/certified.svg";
import useIsMobile from "../Hook/mobile";
import useNotification, { NOTIFICATION } from "../Hook/notification";
import { displayPopin } from "../Hook/popins";
import useUser from "../Hook/user";

function UserMenu({ setOpen, open }: { setOpen: any; open: boolean }) {
  const notifications = useNotification();
  const user = useUser();
  const detectMobile = useIsMobile();
  const navigate = useNavigate();
  if (!user) return null;

  return (
    <div className="text-sm flex flex-col gap-2 overflow-y-auto scroll--numbers pr-4">
      <div className="flex flex-col relative gap-4 w-full items-center lg:px-10">
        <FontAwesomeIcon
          onClick={() => setOpen((prev: boolean) => !prev)}
          className="absolute top-1 left-1 h-6"
          icon={faArrowLeft}
        />

        <div className="absolute top-1 right-1">
          <LocaleSelector />
        </div>
        {user.informations?.type !== "guest" && (
          <div className="flex flex-col items-center gap-1">
            <div className="h-16 w-16 mt-4 rounded-full overflow-hidden cursor-pointer" onClick={() => navigate("/me")}>
              <Avatar member={user.informations} />
            </div>
            <div
              className="flex items-center justify-center gap-2 cursor-pointer relative left-2.5"
              onClick={() => navigate("/me")}
            >
              <div className={`border-b border-${user.informations?.gender}`}>
                <Pseudonym member={user.informations} />
              </div>
              <FontAwesomeIcon
                className={`text-${user.informations?.gender} h-3`}
                icon={faChevronRight}
              />
            </div>
          </div>
        )}
        {user.informations?.type !== "guest" && (
          <>
            <div className="grid grid-cols-2 gap-2 flex-1 w-full">
              <div
                onClick={() => {
                  NOTIFICATION.resetCounter("profile_visit");
                  setOpen(!open);
                  navigate("/me", { state: { goto: 1 } });
                }}
                className="flex cursor-pointer gap-2 relative items-center justify-around p-2 bg-cards rounded-xl"
              >
                {notifications.profile_visit > 0 && <div className="absolute w-2.5 h-2.5 rounded-full top-2 right-2 bg-green z-50"></div>}
                <FontAwesomeIcon
                  icon={faEye}
                  className="h-5 text-soft flex-shrink-0"
                />
                <div className="flex flex-col text-[0.6rem] items-center justify-center gap-2">
                  <FormattedMessage id="menu__my_visits" />
                  <div className="text-base">
                    +{notifications.profile_visit}
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  NOTIFICATION.resetCounter("epheme_like");
                  setOpen(!open);
                  navigate("/me");
                }}
                className="flex cursor-pointer gap-2 items-center relative justify-around p-2 bg-cards rounded-xl"
              >
                {notifications.epheme_like > 0 && <div className="absolute w-2.5 h-2.5 rounded-full top-2 right-2 bg-green z-50"></div>}

                <FontAwesomeIcon
                  icon={faHeart}
                  className="h-5 text-hard flex-shrink-0"
                />
                <div className="flex flex-col text-[0.6rem] items-center justify-center gap-2">
                  <FormattedMessage id="popuprelation__nb_likes" />
                  <div className="text-base">+{notifications.epheme_like}</div>
                </div>
              </div>
              <div
                onClick={() => {
                  NOTIFICATION.resetCounter("follow");
                  setOpen(!open);
                  navigate("/me", { state: { goto: 2 } });
                }}
                className="flex relative cursor-pointer gap-2 items-center justify-around p-2 bg-cards rounded-xl"
              >
                {notifications.follow > 0 && <div className="absolute w-2.5 h-2.5 rounded-full top-2 right-2 bg-green z-50"></div>}

                <FontAwesomeIcon
                  icon={faUserFriends}
                  className="h-5 text-primary flex-shrink-0"
                />
                <div className="flex flex-col text-[0.6rem] items-center justify-center gap-2">
                  <FormattedMessage id="menu__my_followers" />
                  <div className="text-base">
                    {user.informations!.nb_followers}{" "}
                    {notifications.follow > 0
                      ? `(+${notifications.follow})`
                      : ""}
                  </div>
                </div>
              </div>
              <div
                className="flex cursor-pointer gap-2 items-center justify-around p-2 bg-cards rounded-xl"
                onClick={() => navigate("/tokens")}
              >
                <FontAwesomeIcon
                  icon={faCoins}
                  className="h-5 text-hot flex-shrink-0"
                />
                <div className="flex flex-col text-[0.6rem] items-center justify-center gap-2">
                  <FormattedMessage id="menu__my_tokens" />
                  <div className="text-base">{user.informations?.tokens}</div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div />

      {user.informations?.type !== "guest" && (
        <>
          {!user.informations?.is_subscribed && (
            <div
              className="flex flex-1 gap-4 items-center justify-center px-4 py-4 bg-primary cursor-pointer rounded-xl"
              onClick={() => {
                setOpen(!open);
                displayPopin()("subscribe");
              }}
            >
              <FontAwesomeIcon className="text-xl" icon={faCrown} />
              <div>
                <FormattedMessage id="menu__subscribe" />
              </div>
            </div>
          )}
          {!user.informations?.content_provider && (
            <div
              className="flex border-b cursor-pointer border-unactive pb-4 pt-2 gap-4 items-center"
              onClick={() => {
                setOpen(!open);
                displayPopin()("contentprovider");
              }}
            >
              <FontAwesomeIcon
                className="w-10 h-5 text-primary"
                icon={faCheck}
              />
              <div>
                <FormattedMessage id="menu__authentication_contributor" />
              </div>
            </div>
          )}
          {!user.informations?.is_certified && (
            <div
              className="flex border-b cursor-pointer border-unactive pb-4 pt-2 gap-4 items-center"
              onClick={() => {
                setOpen(!open);
                displayPopin()("certificate");
              }}
            >
              <img
                className="h-[1.2rem] w-10"
                src={certified}
                alt="certified"
              />
              <div>
                <FormattedMessage id="menu__certification" />
              </div>
            </div>
          )}
          <MyProfileMenu setParentOpen={setOpen} />
        </>
      )}
      {user.informations?.type === "guest" && (
        <div className="flex gap-4 mt-10">
          <Button
            className=" !px-4 !py-8"
            fillSpace
            name="menu__login"
            onClick={() => {
              setOpen(!open);
              displayPopin()("login");
            }}
            color="primary"
          />
          <Button
            className=" !px-4 !py-8"
            fillSpace
            name="menu__register"
            onClick={() => {
              setOpen(!open);
              window.location.href = "/smartrotation/direct.php";
            }}
            color="primary"
          />
        </div>
      )}
      <div
        className="flex border-b cursor-pointer border-unactive pb-4 pt-2 gap-4 items-center"
        onClick={() => {
          setOpen(!open);
          navigate("/search");
        }}
      >
        <FontAwesomeIcon
          className="w-10 h-5 text-primary"
          icon={faMagnifyingGlass}
        />
        <div>
          <FormattedMessage id="menu__search" />
        </div>
      </div>
      <div
        className="flex border-b cursor-pointer border-unactive pb-4 pt-2 gap-4 items-center"
        onClick={() => {
          setOpen(!open);
          if(user.informations?.type === "guest") {
            return displayPopin()("login");
          }
          navigate("/webcams");
        }}
      >
        <FontAwesomeIcon className="w-10 h-5 text-primary" icon={faVideo} />
        <div>
          <FormattedMessage id="menu__free_webcams" />
        </div>
      </div>
      <div
        className="flex border-b cursor-pointer border-unactive pb-4 pt-2 gap-4 items-center"
        onClick={() => {
          setOpen(!open);
          navigate("/challenge");
        }}
      >
        <FontAwesomeIcon className="w-10 h-5 text-primary" icon={faDice} />
        <div>
          <FormattedMessage id="menu__challenges" />
        </div>
      </div>
      <MoreInfosMenu setParentOpen={setOpen} />

      <div />
      <div className="flex gap-4">
        <Button
          className=" !text-left !h-auto  !px-4 !py-2 text-tabs !rounded-xl gap-2 border-unactive"
          appearance="outline"
          fillSpace
          onClick={() => {
            setOpen(!open);
            displayPopin()("contact");
          }}
          color="gray-600"
        >
          <FontAwesomeIcon icon={faEnvelope} />
          <FormattedMessage id="menu__contact" />
        </Button>
        {user.informations?.type !== "guest" && (
          <Button
            className=" !h-auto !px-4 !py-2 text-tabs !rounded-xl border-unactive"
            appearance="outline"
            fillSpace
            name="menu__logout"
            onClick={user.logout}
            color="gray-600"
          />
        )}
      </div>
    </div>
  );
}

const MyProfileMenu = ({ setParentOpen }: { setParentOpen: any }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div
        className="flex border-b cursor-pointer border-unactive pb-4 pt-2 gap-4 items-center relative"
        onClick={() => setOpen((prev) => !prev)}
      >
        <FontAwesomeIcon className="w-10 h-5 text-primary" icon={faUser} />
        <div>
          <FormattedMessage id="menu__my_profile" />
        </div>
        <FontAwesomeIcon
          className="w-6 absolute top-3 right-2 text-primary"
          icon={faChevronDown}
        />
      </div>
      {open && (
        <>
          <div
            className="flex border-b cursor-pointer border-unactive pb-4 pt-2 gap-4 items-center"
            onClick={() => navigate("/me")}
          >
            <FontAwesomeIcon
              className="w-10 h-5 text-primary"
              icon={faImagePortrait}
            />
            <div>
              <FormattedMessage id="menu__my_profile_page" />
            </div>
          </div>
          <div
            className="flex border-b cursor-pointer border-unactive pb-4 pt-2 gap-4 items-center"
            onClick={() => {
              setParentOpen(false);
              displayPopin()("edit");
            }}
          >
            <FontAwesomeIcon className="w-10 h-5 text-primary" icon={faPen} />
            <div>
              <FormattedMessage id="menu__edit_my_profile" />
            </div>
          </div>
          <div
            className="flex border-b cursor-pointer border-unactive pb-4 pt-2 gap-4 items-center"
            onClick={() => navigate("/parameters")}
          >
            <FontAwesomeIcon className="w-10 h-5 text-primary" icon={faGear} />
            <div>
              <FormattedMessage id="menu__my_settings" />
            </div>
          </div>
          <div
            className="flex border-b cursor-pointer border-unactive pb-4 pt-2 gap-4 items-center"
            onClick={() => navigate("/blocked")}
          >
            <FontAwesomeIcon
              className="w-10 h-5 text-primary"
              icon={faUserXmark}
            />
            <div>
              <FormattedMessage id="menu__blocked_page" />
            </div>
          </div>
        </>
      )}
    </>
  );
};

const MoreInfosMenu = ({ setParentOpen }: { setParentOpen: any }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div
        className="flex border-b cursor-pointer border-unactive pb-4 pt-2 gap-4 items-center relative"
        onClick={() => setOpen((prev) => !prev)}
      >
        <FontAwesomeIcon
          className="w-10 h-5 text-primary"
          icon={faInfoCircle}
        />
        <div>
          <FormattedMessage id="menu__more_informations" />
        </div>
        <FontAwesomeIcon
          className="w-6 absolute top-3 right-2 text-primary"
          icon={faChevronDown}
        />
      </div>
      {open && (
        <>
          <div
            className="flex border-b cursor-pointer border-unactive pb-4 pt-2 gap-4 items-center"
            onClick={() => navigate("/cgus")}
          >
            <FontAwesomeIcon className="w-10 h-5 text-primary" icon={faInfo} />
            <div>
              <FormattedMessage id="menu__cgu" />
            </div>
          </div>
          <div
            className="flex border-b cursor-pointer border-unactive pb-4 pt-2 gap-4 items-center"
            onClick={() => navigate("/privacy")}
          >
            <FontAwesomeIcon className="w-10 h-5 text-primary" icon={faInfo} />
            <div>
              <FormattedMessage id="menu__privacy" />
            </div>
          </div>
          <div
            className="flex border-b cursor-pointer border-unactive pb-4 pt-2 gap-4 items-center"
            onClick={() => navigate("/2257")}
          >
            <FontAwesomeIcon className="w-10 h-5 text-primary" icon={faInfo} />
            <div>
              <FormattedMessage id="menu__exemption" />
            </div>
          </div>
          <div
            className="flex border-b cursor-pointer border-unactive pb-4 pt-2 gap-4 items-center"
            onClick={() => navigate("/dmca")}
          >
            <FontAwesomeIcon className="w-10 h-5 text-primary" icon={faInfo} />
            <div>
              <FormattedMessage id="menu__dmca" />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserMenu;
