import React, { useState } from 'react'
import useUser from '../../Hook/user'
import { cards } from './cardsIcons';

function CardList({index, setIndex, pick, setPickMenu}: {index:number, setIndex: any, pick:any, setPickMenu:any}) {
    const user = useUser();
    if(user?.cards.length === 0) return null;

    if(pick) return (<div className='flex flex-col gap-4'>
        {user?.cards.map((e, i) => <div onClick={() => {setIndex(i);setPickMenu(!pick)}} className='p-4 cursor-pointer border border-border rounded-xl items-center flex item-center gap-2'>
            <div className='w-8' dangerouslySetInnerHTML={{__html: cards[e.card_brand]}}></div>
            <div>{chunk(e.card_number, 4).join(" ")}</div>
        </div>)}
    </div>)
    const card:any = user?.cards[index];
    return (
        <div onClick={() => setPickMenu(!pick)} className='p-4 cursor-pointer border border-primary rounded-xl items-center flex item-center gap-2'>
            <div className='w-8' dangerouslySetInnerHTML={{__html: cards[card.card_brand]}}></div>
            <div>{chunk(card.card_number, 4).join(" ")}</div>
        </div>
    )
}

function chunk(str:string, n:number) {
    var ret = [];
    var i;
    var len;
  
    for(i = 0, len = str.length; i < len; i += n) {
       ret.push(str.substring(i, i + n));
    }
  
    return ret
  };

export default CardList