import { faCoins, faFire, faRankingStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { api_postValueOrErrorMessage } from '../../../Api/util';
import useUser from '../../../Hook/user';
import Button from '../../Input/Button';
import Pseudonym from '../../Member/Pseudonym';

function SimulatedToken({ simulated, setSimulated }: any) {
  const user = useUser();
  return (<div className='w-60 max-w-[100%] m-auto my-0 bg-message flex flex-col gap-4 p-4 rounded-xl items-center'>
    <div className="[&>span]:text-primary text-center">
      <FormattedMessage id={"tipmessage__title_before_sending"} values={{ member: simulated.member.pseudonym }} />
    </div>

    <div className='flex gap-2 items-center text-2xl'>
      <div>{simulated.coins}</div>
      <FontAwesomeIcon icon={faCoins} />
    </div>
    <div className='h-0.5 w-full bg-border'></div>
    <div><Pseudonym member={simulated.member} /></div>
    <div className='flex justify-around w-full'>
      <div className='flex flex-col items-center text-2xl'>
        <FontAwesomeIcon icon={faFire} />
        <div>+{simulated.flames_given}</div>
      </div>
      <div className='flex flex-col items-center text-2xl'>
        <FontAwesomeIcon icon={faRankingStar} />
        <div>+{simulated.member_rank_before - simulated.member_rank_after}</div>
      </div>
    </div>
    <Button fillSpace onClick={() => {
      setSimulated(null);
      api_postValueOrErrorMessage("tips/add", { amount: simulated.coins, member: simulated.member.id });
    }} color="primary" name="tipmessage__send_button" />
  </div>)
}

export default SimulatedToken