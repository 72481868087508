import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl';
import { api_postEphemesList } from '../../Api/epheme';
import { ephemesInformations_ephemes } from '../../Api/types';
import useFilters from '../../Hook/filters';
import useIsMobile from '../../Hook/mobile'
import { displayPopin } from '../../Hook/popins';
import Button from '../Input/Button';
import Loader from '../Loader';
import EphemeItem from './EphemeItem';

function EphemeList({ additionalInputs, endpoint = "public_feed" }: { additionalInputs?: any, endpoint?: "member_feed" | "followed_feed" | "public_feed" | "user_feed" }) {
  const [ephemes, setEphemes] = useState<ephemesInformations_ephemes[]>([]);
  const [isLoading, setLoading] = useState(true);
  const filters = useFilters();
  useEffect(() => {
    //helps calling async API with await syntax instead of then
    (async () => {
      setLoading(true);
      setEphemes([]);
      setEphemes(await api_postEphemesList(endpoint, { offset: 0, limit: 8, genders: ["woman"], type: "classic", thermometers: ["soft"], sort: "popularity", ...filters.filters, ...additionalInputs }))
      setLoading(false);
    })()
  }, [filters, additionalInputs]);
  const reload = async () => {
    setLoading(true);
    setEphemes(await api_postEphemesList(endpoint, { offset: 0, limit: 8, genders: ["woman"], type: "classic", thermometers: ["soft"], sort: "popularity", ...filters.filters, ...additionalInputs }))
    setLoading(false);
  }
  const loadMore = async () => {
    setLoading(true);
    setEphemes([...ephemes, ...await api_postEphemesList(endpoint, { offset: ephemes.length, limit: 8, genders: ["woman"], type: "classic", thermometers: ["soft"], sort: "popularity", ...filters.filters, ...additionalInputs })])
    setLoading(false);
  }
  return (
    <div>
      {!isLoading && ephemes.length === 0 && <FormattedMessage id="ephemelist__no_result" />}
      <div className='w-full max-w-6xl grid-cols-2 lg:grid-cols-4 grid m-auto gap-4 my-0'>
        {ephemes.map((epheme, i) => <EphemeItem shouldSnapLeft={i === ephemes.length - 1} key={epheme.id} epheme={epheme} showPopin={() => {
          displayPopin()("viewer", { current: epheme, list: ephemes, reload, loadMoreToDisplay: [endpoint, { offset: 0, limit: 8, genders: ["woman"], type: "classic", thermometers: ["soft"], sort: "popularity", ...filters.filters, ...additionalInputs }] })
        }} />)}
        {isLoading && <Loader />}
      </div>
      {!isLoading && ephemes.length > 0 && ephemes.length % 8 === 0 && <div className='mt-8 flex justify-center'><Button name="button_fetch_more_list" color="primary" onClick={loadMore} /></div>}
    </div>
  )
}

export default EphemeList