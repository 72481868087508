
import { faClose, faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import { membersInformations_members, membersRelation, membersRelations_members } from "../../Api/types";
import { closeCurrentPopin } from "../../Hook/popins";
import useUser from "../../Hook/user";
import Avatar from "../../Component/Avatar";

function CommonBoost({ member, relation }: { member: membersInformations_members, relation: membersRelation }) {
  const user = useUser()!;
  return (
    <>
      <div onClick={closeCurrentPopin} className="top-0  left-0 right-0 bottom-0 fixed bg-black opacity-60"></div>
      <div className="z-50 p-8 fixed top-1/2 w-full lg:max-w-sm left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-popinbg rounded-xl flex flex-col gap-4">
        <div onClick={closeCurrentPopin} className="absolute top-0 right-10 -translate-y-1/2 h-8 w-8 bg-white flex justify-center items-center rounded-full"><FontAwesomeIcon className="text-black" icon={faClose} /></div>

        <h2 className="text-center text-primary text-2xl"><FormattedMessage id="profile__common_boost_short" /></h2>
        <div className="flex gap-4 items-center justify-center">
          <div className="w-16 h-16 overflow-hidden rounded-full"><Avatar member={user.informations} /></div>
          <div className="flex gap-1">
            <div className="relative flex gap-px">
              <FontAwesomeIcon icon={faRocket} />
              <FontAwesomeIcon className="w-2 h-2 -scale-x-100" icon={faRocket} />
            </div>
            <div>{relation.boost_common}%</div>
          </div>
          <div className="w-16 h-16 overflow-hidden rounded-full"><Avatar member={member} /></div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <FontAwesomeIcon icon={faRocket} />
            <div className="flex-1"><FormattedMessage id="popuprelation__my_boost" /></div>
            <div>{user.informations?.boost}%</div>
          </div>
          <div className="flex gap-2 items-center">
            <FontAwesomeIcon icon={faRocket} />
            <div className="flex-1"><FormattedMessage values={{ member: member.pseudonym }} id="popuprelation__their_boost" /></div>
            <div>{member.boost}%</div>
          </div>

          <hr />

          <div className="flex gap-2">
            <div>{relation.nb_chat_messages}</div>
            <div className="flex-1"><FormattedMessage id="popuprelation__nb_chat_messages" /></div>
            <div>{relation.nb_chat_messages_boost}%</div>
          </div>
          <div className="flex gap-2">
            <div>{relation.nb_likes}</div>
            <div className="flex-1"><FormattedMessage id="popuprelation__nb_likes" /></div>
            <div>{relation.nb_likes_boost}%</div>
          </div>
          <div className="flex gap-2">
            <div>{relation.flames_record}</div>
            <div className="flex-1"><FormattedMessage id="popuprelation__flames_record" /></div>
            <div>{relation.flames_record_boost}%</div>
          </div>
          <div className="flex gap-2">
            <div>{relation.flames_gift_sent}</div>
            <div className="flex-1"><FormattedMessage id="popuprelation__flames_gift_sent" /></div>
            <div>{relation.flames_gift_sent_boost}%</div>
          </div>

          <hr />

          <div className="flex gap-2 items-center">
            <FontAwesomeIcon icon={faRocket} />
            <div className="flex-1"><FormattedMessage id="popuprelation__bonus_boost" /></div>
            <div>{(relation.nb_chat_messages_boost ?? 0) + (relation.nb_likes_boost ?? 0) + (relation.flames_record_boost ?? 0) + (relation.flames_gift_sent_boost ?? 0)}%</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CommonBoost;
