import React, { useEffect, useState } from 'react'
import useUser from '../../Hook/user';
import Button from './Button';
import { displayPopin } from '../../Hook/popins';
import { api_postValueOrErrorMessage } from '../../Api/util';
import { membersInformations_members } from '../../Api/types';
import { FormattedMessage } from 'react-intl';


function FollowButton({ member }: { member: membersInformations_members }) {
    const user = useUser();
    const [followed, isFollowed] = useState(user?.followed.has(member.id));
    const [rerender, sr] = useState(0);

    useEffect(() => {
        isFollowed(user?.followed.has(member.id));
        const cb = (e: any) => {
            isFollowed(user?.followed.has(member.id))
        }
        document.addEventListener(
            "followed",
            cb,
            false,
        );
        return () => {
            document.removeEventListener("followed", cb);
        }
    }, [member])
    return (
        <Button className={"mx-0 border-2 h-fit my-auto !py-1 border-primary [@media(hover:hover)]:hover:bg-transparent [@media(hover:hover)]:hover:text-primary"} color="primary" onClick={(event) => {
            (event as MouseEvent).stopPropagation();
            if (user?.informations?.type === "guest") {
                return displayPopin()("login", { errorMessage: "terminated_guest_freetour" });
            }
            if (followed) {
                user?.followed.delete(member.id);
                api_postValueOrErrorMessage("members/unfollow", { id: member.id })
            }
            else {
                user?.followed.add(member.id);
                api_postValueOrErrorMessage("members/follow", { id: member.id })

            }
            isFollowed(!followed)
            document.dispatchEvent(new Event("followed"));
        }}>
            <FormattedMessage id={followed ? "memberitem__unfollow" : "memberitem__follow"} />{followed ? "" : " +"}
        </Button>
    )
}

export default FollowButton