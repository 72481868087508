import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import Button from '../../Component/Input/Button'
import { closeCurrentPopin } from '../../Hook/popins'

function Contact() {
    return (
        <>
            <div onClick={closeCurrentPopin} className="top-0 left-0 right-0 bottom-0 fixed bg-black opacity-60 z-z-4010"></div>
            <div className="z-50 p-8 lg:max-w-2xl lg:rounded-xl bg-popinbg w-screen fixed max-h-[100dvh] flex flex-col gap-4 scroll--numbers overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">

                <div onClick={closeCurrentPopin} className='absolute top-9 right-8'><FontAwesomeIcon icon={faClose} /></div>
                <h1 className='text-lg font-bold text-center'><FormattedMessage id="contact__support_title" /></h1>
                <h2 className="text-center border border-border rounded-xl py-3"><FormattedMessage id="contact__questions_title" /></h2>
                <div><FormattedMessage id="contact__questions_description" /></div>
                <Button color='primary' name="contact__snapdesk_link" onClick={() => window.open("https://www.snapdesk.info/", "_blank")} />
                <h2 className="text-center border border-border rounded-xl py-3"><FormattedMessage id="contact__email_title" /></h2>
                <div><FormattedMessage id="contact__email_description" /></div>
                <Button color='primary' name="contact__email_link" onClick={() => window.open("mailto:julie@snapdesk.info")} />
            </div>
        </>
    )
}

export default Contact