import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { fetchMembers, fetchMembersFromIds } from "../../Api/member";
import {
  chatChannelsAdd,
  chatChannelsInformations,
  membersInformations_members,
} from "../../Api/types";
import { api_postValueOrErrorMessage } from "../../Api/util";
import useFilters from "../../Hook/filters";
import { displayPopin } from "../../Hook/popins";
import useUser, { USER } from "../../Hook/user";
import Button from "../Input/Button";
import Loader from "../Loader";
import MemberItem from "./MemberItem";
import { channelAddWithMember } from "../../Api/chat";

function MemberList({
  additionalInputs,
  endpoint = "top",
  common,
  noGlobalFilters,
  progress,
  chat,
  blocked,
  alwaysLoadMore
}: {
  additionalInputs?: any;
  noGlobalFilters?: boolean;
  common?: boolean;
  chat?: any;
  progress?: boolean;
  endpoint?:
    | "blocked"
    | "fans"
    | "followers"
    | "followed"
    | "highlighted"
    | "profile_visitors"
    | "relations"
    | "search"
    | "top"
    | "top_flames_day"
    | "likes";
  blocked?: boolean;
  alwaysLoadMore?: boolean;
}) {
  const [members, setMembers] = useState<membersInformations_members[]>([]);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(true);
  const user = useUser();
  const filters = useFilters();

  useEffect(() => {
    //helps calling async API with await syntax instead of then
    (async () => {
      setLoading(true);
      setMembers([]);
      let members = await fetchMembers(endpoint, {
        offset: 0,
        limit: 8,
        ...(noGlobalFilters ? {} : filters.filters),
        ...additionalInputs,
      });
      console.log(members);
      setHasMore(members.length === 8)
      if(alwaysLoadMore)
        members = members.filter(e => e.time_online !== undefined);
      setMembers(
        members
      );
      setLoading(false);
    })();
  }, [filters, additionalInputs]);
  const reload = async () => {
    setLoading(true);
    let members = await fetchMembers(endpoint, {
      offset: 0,
      limit: 8,
      type: "classic",
      ...(noGlobalFilters ? {} : filters.filters),
      ...additionalInputs,
    });
    setHasMore(members.length === 8)
    if(alwaysLoadMore)
      members = members.filter(e => e.time_online !== undefined);
    setMembers(
      members
    );
    setLoading(false);
  };
  const loadMore = async (forcePage?: number) => {
    setLoading(true);
    console.log(forcePage, page);
    let newM = (await fetchMembers(endpoint, {
      offset: (forcePage ?? page) * 8,
      limit: 8,
      ...(noGlobalFilters ? {} : filters.filters),
      ...additionalInputs,
    }))
    let has8Members = newM.length === 8;
    setHasMore(has8Members);
    if(alwaysLoadMore){
      newM = newM.filter(e => e.time_online !== undefined);
      newM = newM.filter(e => !members.find(a => a.id === e.id));
      if(has8Members && newM.length === 0){
        loadMore(forcePage ? forcePage + 1 : page + 1);
        return;
      }
    }
    setPage((p) => (forcePage ?? p) + 1);
    setMembers([
      ...members,
      ...newM,
    ]);
    setLoading(false);
  };
  return (
    <div>
      <div
        className={
          "w-fit lg:w-full max-w-6xl grid-cols-1  grid m-auto gap-4 my-0" +
          (chat ? " lg:grid-cols-1" : " lg:grid-cols-2")
        }
      >
        {!isLoading && members.length === 0 && (
          <FormattedMessage id="memberlist__no_result" />
        )}
        {members.map((member, i) => (
          <MemberItem
            medal={endpoint === "top" ? i + 1 : undefined}
            reload={reload}
            blocked={blocked}
            common={common}
            progress={progress}
            shouldSnapLeft={i === members.length - 1}
            key={member.id}
            chat={chat}
            member={member}
            onClick={
              chat
                ? () => {
                    if (user?.informations?.type === "guest")
                      return displayPopin()("login");
                    let err = USER?.informations?.restrictions?.find(
                      (e) => e.type === "chat_access",
                    );
                    if (err) {
                      return displayPopin()("chaterror");
                    }
                    api_postValueOrErrorMessage<chatChannelsAdd>(
                      "chat/channels/add",
                      { members: [member.id] },
                    ).then((channel) => {
                      if (!channel.error_message)
                        api_postValueOrErrorMessage<chatChannelsInformations>(
                          "chat/channels/informations",
                          { channels: [channel.channel] },
                        ).then(async (chan) => {
                          if (!chan.error_message) {
                            let currentChan = chan.channels[0];
                            let otherMember = currentChan.members.find(
                              (e) => e !== user?.informations?.id,
                            );
                            const member = await fetchMembersFromIds([
                              otherMember as string,
                            ]);
                            currentChan.otherMember = member[0];
                            chat(currentChan);
                          }
                        });
                    });
                  }
                : null
            }
          />
        ))}
        {isLoading && <Loader />}
      </div>
      {!isLoading && (hasMore) && (
        <div className="mt-8 flex justify-center">
          <Button name="load_more" color="primary" onClick={() => loadMore()} />
        </div>
      )}
    </div>
  );
}

export default MemberList;
