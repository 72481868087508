import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { userTokensOffers, userTokensOffers_offers } from '../../Api/types';
import { api_getValueOrErrorMessage } from '../../Api/util';
import { closeCurrentPopin } from '../../Hook/popins';
import Audiotel from './Audiotel';
import CardsPicker from './CardsPicker';
import TokensChooseOffer from './TokensChooseOffer';

function Tokens() {
  const [tokenOffers, setTokenOffers] = useState<userTokensOffers_offers[]>([]);
  const [step, setStep] = useState(0);
  const [index, setIndex] = useState(0);
  const [card, setCard] = useState();
  
  useEffect(() => {
    (async () => {
      setTokenOffers((await api_getValueOrErrorMessage<userTokensOffers>("user/tokens/offers")).offers);
    })()
  }, [])

  const nextStep = () => setStep(step+1);

  return (
    <>
      <div onClick={closeCurrentPopin} className="top-0 left-0 right-0 bottom-0 fixed bg-black opacity-60 z-40"></div>
      <div className="z-50 h-screen lg:max-w-2xl px-4 lg:rounded-xl w-screen lg:h-fit lg:pb-4 fixed max-h-[100dvh] scroll--numbers overflow-y-auto lg:top-1/2 lg:left-1/2 transform lg:-translate-x-1/2 lg:-translate-y-1/2 bg-chatbg rounded-xl flex flex-col gap-4 pt-4">
        <div onClick={closeCurrentPopin} className='absolute top-5 right-8'><FontAwesomeIcon icon={faClose}/></div>
        {step === 0 && <TokensChooseOffer nextStep={nextStep} tokenOffers={tokenOffers} index={index} setIndex={setIndex}/>}
        {step === 1 && (tokenOffers[index].iframe_url ? <Audiotel tokenOffer={tokenOffers[index]} index={index} setIndex={setStep}/> : <CardsPicker tokenOffer={tokenOffers[index]} setStep={setStep} />)}
      </div>
    </>
  )
}

export default Tokens