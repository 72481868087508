import { Api_Error } from "./types";

export async function api_getValueOrErrorMessage<T>(url: string):Promise<T&Api_Error>{
  try {
    let apicall = await fetch("/api_front/" + url);
    return await apicall.json();
  } catch (err: any) {
    let response = err.response.text;
    return err.response.text ?? JSON.parse(response);
  }
};

export async function api_postValueOrErrorMessage<T>(url: string, data: any):Promise<T&Api_Error> {
  try {
    for(let key in data){
      if(data[key] === "yes") data[key] = true
      if(data[key] === "no") data[key] = false;
    }
    let apicall = await fetch("/api_front/" + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    });
    return await apicall.json();
  } catch (err: any) {
    let response = err.response.text;
    return err.response.text ?? JSON.parse(response);
  }
};

export async function api_postFDOrErrorMessage<T>(url: string, data: any):Promise<T&Api_Error> {
  try {
    let apicall = await fetch("/api_front/" + url, {
      method: "POST",
      body: data
    });
    return await apicall.json();
  } catch (err: any) {
    let response = err.response.text;
    return err.response.text ?? JSON.parse(response);
  }
};
