import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useFilters from '../../Hook/filters';
import Select from '../Select';


function FilterOptions({ property, updater, value, none, fill, title, className }:
  { title?: string, property: string, updater?: any, value?: any, none?: boolean, fill?: boolean, className?: string }) {
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  let filters = useFilters();
  if (value) filters = { filters: { [property]: value } };
  let filterExist = filters.filters[property];
  let all = false;
  if (filterExist) all = Array.isArray(filterExist) && filterExist.length === optionsProperties[property].length;
  if (filterExist && filterExist.length === 0) filterExist = false;

  let displayValue = "";
  if (none && !filterExist) displayValue = intl.formatMessage({ id: "preferences__nothing_selected" });
  if (all) {
    displayValue = intl.formatMessage({ id: "filter__online_all" });
  } else if (!filterExist) {
    displayValue = intl.formatMessage({ id: "filter__online_all" });
  } else if (filterExist) {
    if (Array.isArray(filterExist)) {
      displayValue = filterExist.map(e => intl.formatMessage({ id: optionsProperties[property].find((a: any) => a.value === e).message })).join(", ");
    }
    else displayValue = intl.formatMessage({ id: "filter__online_" + filterExist });
  }

  return (
    <div className='flex-1'>
      <div className={'text-label mb-2 w-full lg:w-52 overflow-hidden text-ellipsis text-sm' + (fill ? ' !w-full' : '')}><FormattedMessage id={"filter__title_" + (title ?? property)} /></div>
      <div className={className}>
        <Select
          options={optionsProperties[property]}
          noDefault
          displayValue={displayValue}
          largePadding
          selected={filters.filters[property]}
          onChange={updater ? updater : (e) => filters.updateFilter(property, e)}
        />
      </div>

    </div>
  )
}

export function FilterPicker({ property, value, updater, fill, className }: { property: string, value: any, updater: any, fill?: boolean, className?: string }) {
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  let filters = useFilters();
  if (value) filters = { filters: { [property]: value } };
  let filterExist = filters.filters[property];
  let all = false;
  if (filterExist) all = Array.isArray(filterExist) && filterExist.length === optionsProperties[property].length;
  if (filterExist && filterExist.length === 0) filterExist = false;

  let displayValue = "";
  if (!filterExist) displayValue = intl.formatMessage({ id: "preferences__nothing_selected" });
  if (all) {
    displayValue = intl.formatMessage({ id: "filter__online_all" });
  } else if (!filterExist) {
    displayValue = intl.formatMessage({ id: "filter__online_all" });
  } else if (filterExist) {
    if (Array.isArray(filterExist)) {
      displayValue = filterExist.map(e => intl.formatMessage({ id: optionsProperties[property].find((a: any) => a.value === e).value })).join(", ");
    }
    else displayValue = intl.formatMessage({ id: property + "__value_label__" + filterExist });
  }
  return (
    <div>
      <div className={'text-label mb-2 w-full lg:w-52 overflow-hidden text-ellipsis text-sm' + (fill ? ' !w-full' : '')}><FormattedMessage id={"filter__title_" + (property)} /></div>
      <div className={className}>
        <Select
          options={optionsProperties.age_ranges}
          noDefault
          displayValue={displayValue}
          largePadding
          selected={filterExist}
          onChange={updater ? updater : (e) => filters.updateFilter(property, e)}
        />
      </div>

    </div>
  )
}

export default FilterOptions

//properties for the filters
const optionsProperties: any = {
  "kind": [
    { value: "man", message: "filter__kind_man" },
    { value: "woman", message: "filter__kind_woman" },
    { value: "couple", message: "filter__kind_couple" }
  ],
  "age_ranges": [
    { value: "18-20", message: "age_range__value_label__18-20" },
    { value: "21-25", message: "age_range__value_label__21-25" },
    { value: "26-30", message: "age_range__value_label__26-30" },
    { value: "31-35", message: "age_range__value_label__31-35" },
    { value: "36-40", message: "age_range__value_label__36-40" },
    { value: "41-45", message: "age_range__value_label__41-45" },
    { value: "46-50", message: "age_range__value_label__46-50" },
    { value: "51-60", message: "age_range__value_label__51-60" },
    { value: "60", message: "age_range__value_label__60" }
  ],
  "languages": [
    { value: "fr", message: "spoken_language__label__french" },
    { value: "en", message: "spoken_language__label__english" },
    { value: "nl", message: "spoken_language__label__dutch" },
    { value: "pt", message: "spoken_language__label__portuguese" },
    { value: "it", message: "spoken_language__label__italian" },
    { value: "es", message: "spoken_language__label__spanish" },
    { value: "de", message: "spoken_language__label__german" },
  ],
  "is_online": [
    { value: "yes", message: "filter__online_yes" },
    { value: "no", message: "filter__online_no" }
  ],
  "website_uses": [
    { value: "share", message: "website_uses__label__share" },
    { value: "photos", message: "website_uses__label__photo" },
    { value: "videos", message: "website_uses__label__video" },
    { value: "chat", message: "website_uses__label__chat" }
  ],
  "gender_sought": [
    { value: "man", message: "gender_sought__label__man" },
    { value: "woman", message: "gender_sought__label__woman" },
    { value: "couple", message: "gender_sought__label__couple" },
  ],
  "relationship_sought": [
    { value: "virtual", message: "relationship_sought__label__virtual" },
    { value: "real", message: "relationship_sought__label__real" },
    { value: "friendship", message: "relationship_sought__label__friendship" },
    { value: "sexting", message: "relationship_sought__label__sexting" },
  ],
  "has_public_photos": [
    { value: "yes", message: "has_public_photo__label__yes" },
    { value: "no", message: "has_public_photo__label__no" }
  ],
  "has_fanclub": [
    { value: "yes", message: "has_fanclub__label__yes" },
    { value: "no", message: "has_fanclub__label__no" },
  ],
  countries: [
    { value: 250, message: "country__label__france" },
    { value: 56, message: "country__label__belgium" },
    { value: 442, message: "country__label__luxembourg" },
    { value: 724, message: "country__label__spain" },
    { value: 826, message: "country__label__england" },
    { value: 620, message: "country__label__portugal" },
    { value: 528, message: "country__label__holland" },
    { value: 276, message: "country__label__germany" },
    { value: 380, message: "country__label__italy" },
    { value: 642, message: "country__label__romania" },
  ],
  thermometer: [
    { value: "soft", message: "filter__thermometer_soft" },
    { value: "hot", message: "filter__thermometer_hot" },
    { value: "hard", message: "filter__thermometer_hard" },
  ]
}