import { PLATFORM } from "../Hook/platform";
import {
  cacheEphemes,
  ephemeInformationsFilterFetchingList,
} from "./cache/ephemeCache";
import { fetchMembersFromIds } from "./member";
import {
  Api_Error,
  ephemesFeed,
  ephemesInformations,
  ephemesInformations_ephemes,
  ephemesMemberFeedInput,
  ephemesPublicFeedInput,
  ephemesView,
} from "./types";
import { api_postValueOrErrorMessage } from "./util";

export const api_postEphemesList = async (
  endpoint: "member_feed" | "followed_feed" | "public_feed" | "user_feed",
  payload: ephemesPublicFeedInput | ephemesMemberFeedInput,
) => {
  const ephemes = await api_postValueOrErrorMessage<ephemesFeed>(
    `ephemes/${endpoint}`,
    payload,
  );
  const cacheAnswer = ephemeInformationsFilterFetchingList(ephemes);
  const ephemesInformations: ephemesInformations = cacheAnswer.notInMemory
    .length
    ? await api_postValueOrErrorMessage<ephemesInformations>(
        `ephemes/informations`,
        { ephemes: cacheAnswer.notInMemory },
      )
    : { ephemes: [] };
  cacheEphemes(ephemesInformations);
  let index = 0;
  const returnEphemes: ephemesInformations_ephemes[] = [];
  for (let ephemeID of ephemes.ephemes) {
    //if we got the epheme in memory we can push it directly to the return array;
    if (cacheAnswer.alreadyInMemory[ephemeID]) {
      returnEphemes.push(cacheAnswer.alreadyInMemory[ephemeID]);
    }
    //else it means that we fetched the informations and we can user an index as they are in the same order as the const 'ephemes' order
    else {
      returnEphemes.push(ephemesInformations.ephemes[index]);
      index++;
    }
  }

  let ids = returnEphemes.map((e) => e.author);
  let authors = await fetchMembersFromIds(ids);
  returnEphemes.forEach((e, i) => {
    e.authorInformation = authors[i];
    if (e.type === "challenge") {
      e.challengeInfos = PLATFORM.challenges.find(
        (chal) => chal.id === e.challenge,
      );
      const challengeTrophy = PLATFORM.trophies.find(
        (t: any) => t.challenge == e.challenge,
      );

      e.trophyId = challengeTrophy!.id;
    }
  });

  return returnEphemes;
};

export const api_getSingleEphemeInformation = async (ephemeID: string) => {
  let epheme = await api_postValueOrErrorMessage<ephemesInformations>(
    `ephemes/informations`,
    { ephemes: [ephemeID] },
  );
  if (epheme.error_message) return null;

  let authors = await fetchMembersFromIds([epheme.ephemes[0].author]);
  epheme.ephemes[0].authorInformation = authors[0];
  return epheme.ephemes[0];
};

export const api_postEphemesView = async (ephemeID: string) => {
  return await api_postValueOrErrorMessage<ephemesView & Api_Error>(
    `ephemes/view`,
    { id: ephemeID },
  );
};
