export function reduceImageSize(file: File|Blob, maxWidth: number, maxHeight: number):Promise<File|Blob> {
    if(file.type.includes("video")) return  new Promise(res => res(file));
    return new Promise((res:(v:Blob) => void) => {
        var reader = new FileReader();
        reader.onload = function (e) {
            var img = new Image();
            img.onload = function () {
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d')!;

                var width = img.width;
                var height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(function (blob) {
                    if (!blob) return;
                    res(blob);
                }, file.type);
            };
            img.src = e.target!.result as string;
        };
        reader.readAsDataURL(file);
    })
}

export function blobToBase64(blob: Blob) {
    return new Promise((res:(v:string) => void) => {
        var reader = new FileReader();
        reader.onload = function () {
            var dataUrl = reader.result! as string;
            res(dataUrl);
        };
        reader.readAsDataURL(blob);
    })

}

export function getSearchParams(search: string){
    let s = search.replace("?", "");
    let entries = s.split("&");
    let returnObj:any = {};
    for(let entry of entries){
        let [key, value] = entry.split("=");
        returnObj[key] = value;
    }
    return returnObj;
}

export function changePageTitle(title:string){
    document.getElementsByTagName("title")[0].innerHTML = title;
}
