import { api_chatFormatMessage } from "../../Api/chat";
import { fetchMembersFromIds } from "../../Api/member";
import {
  membersInformations_members,
  websiteParameters,
} from "../../Api/types";
import { PLATFORM } from "../../Hook/platform";

export type socketMessage = {
  type: "text" | "content_pending" | "content" | "gift" | "tip";
  author: string;
  time_sent: number;
  member?: membersInformations_members;
};

export type sockedTextMessage = { text: string } & socketMessage;

export type sockedContentMessage = {
  content_type: "photo" | "video";
  content: string;
} & socketMessage;

export type sockedGiftMessage = {
  gift: string; //gift id, used on /chat/gifts/* APIs
  gift_flames_given: number; //number of flames given by the gift
  gift_rank_before: number; //rank of the recipient before the gift
  gift_rank_after: number; //rank of the recipient after the gift
} & socketMessage;

export type sockedTipMessage = {
  tip_amount: number; //tip amount in tokens
  tip_flames_given: number; //number of flames given by the tip
  tip_rank_before: number; //rank of the recipient before the tip
  tip_rank_after: number; //rank of the recipient after the tip
} & socketMessage;

let expression: RegExp | null = null;

export const formatHistory = async (
  messages: sockedTextMessage[],
  updater: any,
) => {
  let arrayMessage: sockedTextMessage[] = [];
  let memberSet = new Set<string>();
  for (let message of messages) {
    if (message.type === "text") {
      let castedMessage = message as sockedTextMessage;
      castedMessage.text = castedMessage.text.replace(/<[^>]*>?/gm, "");

      //if message contains website keywords it needs further formatting
      if (checkMessage(castedMessage.text)) {
        castedMessage.text = await api_chatFormatMessage(castedMessage.text);
      }
      if (
        arrayMessage.length &&
        arrayMessage[arrayMessage.length - 1].author === message.author &&
        arrayMessage[arrayMessage.length - 1].type === "text"
      ) {
        arrayMessage[arrayMessage.length - 1].text += `\n${castedMessage.text}`;
        arrayMessage[arrayMessage.length - 1].time_sent =
          castedMessage.time_sent;
      } else {
        arrayMessage.push(castedMessage);
      }
    } else {
      arrayMessage.push(message);
    }
    memberSet.add(message.author);
  }

  let members = await fetchMembersFromIds(Array.from(memberSet));

  for (let message of arrayMessage) {
    message.member = members.find((e) => e.id === message.author);
  }

  updater(arrayMessage);
};

export const appendMessage = async (
  message: socketMessage,
  messages: socketMessage[],
  updater: any,
) => {
  let memberSet = new Set<string>();
  let arrayMessage: any[] = [...messages];
  if (arrayMessage[arrayMessage.length - 1] === "content_pending")
    arrayMessage.pop();
  if (message.type === "text") {
    let castedMessage = message as sockedTextMessage;
    castedMessage.text = castedMessage.text.replace(/<[^>]*>?/gm, "");

    //if message contains website keywords it needs further formatting
    if (checkMessage(castedMessage.text)) {
      castedMessage.text = await api_chatFormatMessage(castedMessage.text);
    }
    if (
      arrayMessage.length &&
      arrayMessage[arrayMessage.length - 1].author === message.author &&
      arrayMessage[arrayMessage.length - 1].type === "text"
    ) {
      arrayMessage[arrayMessage.length - 1].text += `\n${castedMessage.text}`;
      arrayMessage[arrayMessage.length - 1].time_sent = castedMessage.time_sent;
    } else {
      arrayMessage.push(castedMessage);
    }
  } else {
    arrayMessage.push(message);
  }
  memberSet.add(message.author);
  let members = await fetchMembersFromIds(Array.from(memberSet));
  for (let message of arrayMessage) {
    if (message.member) continue;
    message.member = members.find((e) => e.id === message.author);
  }
  updater(arrayMessage);
};

const checkMessage = (msg: string) => {
  if (!expression) {
    expression = new RegExp(
      (PLATFORM.platform as websiteParameters).chat_keywords_formatting
        .join("|")
        .replace(/[-[\]{}()*+?.,\\^$#\s]/g, "\\$&"),
    );
  }
  return expression.test(msg);
};

export const createXHRUploadRequest = (
  formData: any,
  cb: (rtn: any) => void,
) => {
  const xhr = new XMLHttpRequest();
  xhr.addEventListener("loadend", cb);
  xhr.addEventListener("error", cb);
  xhr.addEventListener("abort", cb);
  (xhr as any).startUpload = () => {
    xhr.open("POST", "/api_front/chat/contents/add");
    xhr.send(formData);
  };
  return xhr;
};
