import React from 'react'
import { chatChannelsInformations_channels } from '../../Api/types'
import { OPEN_CHANNELS } from '../../Hook/openChannels';
import ChatItem from '../ChatItem'

function OpenDesktopChats({channels}: {channels: chatChannelsInformations_channels[]}) {
  return (
    <div className='absolute bottom-0 right-20 flex gap-2'>
        {channels.map(channel => <ChatItem key={channel.id} channel={channel} home closeChat={OPEN_CHANNELS.closeChannel(channel)}/>)}
    </div>
  )
}

export default OpenDesktopChats;