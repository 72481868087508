import { FormattedMessage } from 'react-intl'
import { userTokensOffers_offers } from '../../Api/types'
import Button from '../../Component/Input/Button'

function Audiotel({ tokenOffer, index, setIndex }: { tokenOffer: userTokensOffers_offers, index: number, setIndex: any }) {
    return (
        <>
            <div className='text-xl text-center [&>*]:text-primary'><FormattedMessage id="popin__tokens_title" /></div>
            <iframe className='w-full' height="480px" width="100%" src={tokenOffer.iframe_url} />
            <div className='flex gap-2'>
                <Button fillSpace name="confirmmodal__cancel" color="primary" appearance='outline' onClick={() => { setIndex(0) }} />
            </div>
        </>
    )
}

export default Audiotel