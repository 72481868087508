import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PLATFORM } from '../../Hook/platform'
import { displayPopin } from '../../Hook/popins'
import Content from '../Content'
import Footer from '../Footer'
import Header from '../Header'
import Main from '../Main'
import MenuMobile from '../MenuMobile'
import { useNavigate } from 'react-router'
import useUser from '../../Hook/user'

function ManageMySub() {
  const intl = useIntl();
  const navigate = useNavigate();
  const user = useUser()!;
  


  return (<>
    <Header />
    <Content>
      <div className="m-auto w-full max-w-5xl flex flex-col gap-4">
        <h2 className='text-lg font-bold'><FormattedMessage id="unsub__meta_titre" /></h2>
        <p><FormattedMessage id="unsub__status" /></p>
        <p><FormattedMessage id="unsub__intro" /></p>
        <ol className=' list-disc pl-10'>
          {PLATFORM.platform?.unsubscribe_links.map(e => <li>{e.name} : <a className='text-primary' target="_blank" href={e.url}><FormattedMessage id="unsub__lien_desabonnement" /></a></li>)}
        </ol>
        <p onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          if ((event.target as HTMLElement).tagName === "A") displayPopin()("contact")
        }} className="[&>a]:text-primary" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "unsub__contact" }) }}></p>
      </div>
    </Content>
    <Footer />
    <MenuMobile />
  </>
  )
}

export default ManageMySub