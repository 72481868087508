import { Input } from '@material-tailwind/react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { api_getValueOrErrorMessage, api_postValueOrErrorMessage } from '../../Api/util';
import Button from '../../Component/Input/Button';
import { closeCurrentPopin, displayPopin } from '../../Hook/popins';
import { USER } from '../../Hook/user';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import TextInput from '../../Component/Input/TextInput';

function ContentProviderForm({ showIframeContentProvider }: { showIframeContentProvider: any }) {
  const intl = useIntl();
  const [identity, setIdentity] = useState<any>({});
  const [error, setError] = useState<boolean | string>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>("");
  const [iframe, setIframe] = useState<any>(null);

  useEffect(() => {
    (async () => {
      let answer = await api_getValueOrErrorMessage<{ iframe_url: string }>("user/identity");
      setIdentity(answer)
    })()
  }, []);

  const validateForm = async () => {

    if (!phone) {
      return setPhoneError(true);
    }
    setPhoneError(false);

    let firstname = (document.getElementById("firstname") as HTMLInputElement)?.value;
    let lastname = (document.getElementById("lastname") as HTMLInputElement)?.value;
    let birthdate = (document.getElementById("birthdate") as HTMLInputElement)?.value;
    let email = USER.informations?.email;
    if (firstname && lastname && phone && birthdate) {
      const phonePlus = phone.startsWith("+") ? phone : `+${phone}`;
      let answer = await api_postValueOrErrorMessage<{ url_iframe: string }>("user/content_provider/request", {
        firstname,
        lastname,
        birthdate,
        email,
        phone: phonePlus,
      });
      if (answer.status === "ok") {
        return showIframeContentProvider(answer.url_iframe);
      }

      if (answer.error_message === "email_address_confirmation_needed") {
        closeCurrentPopin();
        displayPopin()("emailconfirm")
      } else if (answer.error_message === "subscription_required") {
        closeCurrentPopin();
        displayPopin()("subscribe");
      } else if (answer.error_message === "user_not_customer") {
        closeCurrentPopin();
        displayPopin()("paymentrequired");
      } else {
        setError(answer.error_message)
      }
    }
  }

  if (iframe) return (
    <>
      <iframe id="yousign-iframe" width={window.innerWidth * 0.8} height={window.innerHeight * 0.8} src={iframe} />
      <p onClick={() => { closeCurrentPopin();
        setTimeout( USER.fetch, 10000);
       }} style={{ textAlign: "center" }}><FormattedMessage id="content_provider__close_iframe" /></p>
    </>
  )
  return (
    <>
      <h2 className='text-center font-bold text-lg'><FormattedMessage id="content_provider__form_step" /></h2>
      <h4><FormattedMessage id="content_provider__reassuring_text" /></h4>
      <TextInput disabled onChange={event => { event.preventDefault() }} id="firstname" value={identity.firstname} label={intl.formatMessage({ id: "content_provider__firstname" })} />
      <TextInput disabled onChange={event => { event.preventDefault() }} id="lastname" value={identity.lastname} label={intl.formatMessage({ id: "content_provider__lastname" })} name="lastname" type='text' />
      <TextInput disabled onChange={event => { event.preventDefault() }} id="birthdate" value={identity.birthdate} label={intl.formatMessage({ id: "content_provider__birthdate" })} name="birthdate" type='text' />
      <div className={phoneError ? 'phone-error' : ''}>
        <PhoneInput
          country={'us'}
          value={phone}
          onChange={phone => setPhone(phone)}
        />
      </div>
      <TextInput disabled onChange={event => { event.preventDefault() }} id="email" label={intl.formatMessage({ id: "content_provider__email" })} name="email" type='email' value={USER.informations?.email} />
      <Button color="primary" name="content_provider__ask_certif" onClick={validateForm} />
    </>
  )
}

export default ContentProviderForm