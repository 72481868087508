import { useState } from "react";
import coupleAvatar from "./avatars/couple.jpg";
import manAvatar from "./avatars/man.jpg";
import womanAvatar from "./avatars/woman.jpg";

const avatarObj: any = {
  "man": manAvatar,
  "woman": womanAvatar,
  "couple": coupleAvatar
}

function Avatar({ member }: { member: { avatar: string, gender: "man" | "woman" | "couple" } & any }) {
  const [def, setDefault] = useState(false);
  if (!member) return null;
  let avatar = member.avatar;
  
  if (def || !avatar) avatar = avatarObj[member.gender];
  if(!avatar) avatar = avatarObj.man;
  return (
    <div className='flex justify-center items-center w-full h-full'>
      <img onError={(e) => setDefault(true)} src={avatar} className={avatar.startsWith("blob:") ?"min-h-[180%] min-w-[180%] flex-shrink-0" : "w-full h-full"} alt="member" />
    </div>
  )
}

export default Avatar