import {
  faBoltLightning,
  faCouch,
  faMessage,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { chatChannelsInformations_channels } from "../../Api/types";
import useNotification from "../../Hook/notification";
import { OPEN_CHANNELS } from "../../Hook/openChannels";
import usePlatform from "../../Hook/platform";
import { displayPopin } from "../../Hook/popins";
import useUser from "../../Hook/user";
import MemberList from "../MemberList";
import Select from "../Select";
import Button from "../Input/Button";
import Loader from "../Loader";
import PrivateChatItem from "./PrivateChatItem";
import useFilters from "../../Hook/filters";

const linkSelect: any = {
  search: "chatlist__see_all_online",
  followed: "chatlist__see_online_followed",
  followers: "chatlist__see_online_followers",
};

function ChatTabs({
  page,
  publicC,
  privateC,
  filter,
  shouldFocusPrivate,
  loadMore,
}: {
  page: number;
  shouldFocusPrivate?: boolean;
  filter: any;
  publicC: null | chatChannelsInformations_channels[];
  privateC: null | chatChannelsInformations_channels[];
  loadMore: any;
}) {
  const [tab, setTab] = useState(shouldFocusPrivate ? 1 : 0);
  const [value, setValue] = useState("search");
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const notification = useNotification();
  const userHook = useUser()!;
  const platform = usePlatform();
  const activeClassname =
    "p-1.5 flex-1 items-center justify-center gap-2 flex text-start border-primary bg-cards text-primary border-2 rounded-xl";
  const inactiveClassname =
    "p-1.5 flex-1 items-center justify-center gap-2 text-messageplaceholder flex text-start bg-cards rounded-xl";
  let selected: any = [];
  if (tab === 1) selected = privateC;
  if (tab === 2) selected = publicC;

  useEffect(() => {
    setLoading(false);
  }, [selected]);
  return (
    <>
      <div className="flex justify-center gap-3 mx-4">
        <div
          className={tab === 0 ? activeClassname : inactiveClassname}
          onClick={() => setTab(0)}
        >
          <div>
            <FontAwesomeIcon className="h-5" icon={faBoltLightning} />
          </div>
          <div className="flex flex-col justify-start">
            <div className="text-xs text-messageplaceholder">
              <FormattedMessage id="chatlist__online_members" />
            </div>
            <div className="text-white font-semibold text-xs">
              {platform?.counters.nb_online_members}
            </div>
          </div>
        </div>
        <div
          className={tab === 1 ? activeClassname : inactiveClassname}
          onClick={() => {
            if (userHook?.informations?.type === "guest")
              return displayPopin()("login", {
                errorMessage: "terminated_guest_freetour",
              });
            setTab(1);
          }}
        >
          <div>
            <FontAwesomeIcon className="h-5" icon={faMessage} />
          </div>
          <div className="flex flex-col justify-start">
            <div className="text-xs text-messageplaceholder">
              <FormattedMessage
                id={
                  notification.chat_message > 1
                    ? "chatlist__message_received_plural"
                    : "chatlist__message_received_singular"
                }
              />
            </div>
            <div className="text-white font-semibold text-xs">
              {notification.chat_message}
            </div>
          </div>
        </div>
        <div
          className={tab === 2 ? activeClassname : inactiveClassname}
          onClick={() => {
            if (userHook?.informations?.type === "guest")
              return displayPopin()("login", {
                errorMessage: "terminated_guest_freetour",
              });
            setTab(2);
          }}
        >
          <div>
            <FontAwesomeIcon className="h-5" icon={faCouch} />
          </div>
          <div className="flex flex-col justify-start">
            <div className="text-xs text-messageplaceholder">
              <FormattedMessage id="chatlist__public_chat" />
            </div>
            <div className="text-white font-semibold text-xs">
              {publicC?.length}
            </div>
          </div>
        </div>
      </div>
      {tab === 0 && (
        <div className="mt-6 px-4">
          <Select
            displayValue={intl.formatMessage({ id: linkSelect[value] })}
            onChange={setValue}
            options={[
              {
                id: "search",
                value: intl.formatMessage({ id: "chatlist__see_all_online" }),
                message: "chatlist__see_all_online",
              },
              {
                id: "followed",
                value: intl.formatMessage({
                  id: "chatlist__see_online_followed",
                }),
                message: "chatlist__see_online_followed",
              },
              {
                id: "followers",
                value: intl.formatMessage({
                  id: "chatlist__see_online_followers",
                }),
                message: "chatlist__see_online_followers",
              },
            ]}
          />
        </div>
      )}
      <div className=" pb-8 flex flex-col gap-4 lg:my-8 cursor-pointer mx-4">
        {tab === 0 && (
          <div className={tab === 0 ? "" : "hidden"}>
            <MemberChatList  endpoint={value} />
          </div>
        )}
        {tab !== 0 && selected && selected.length === 0 && (
          <FormattedMessage id="chat__no_chat_available" />
        )}
        {tab !== 0 &&
          selected &&
          selected.map((e: any) => {
            return <PrivateChatItem e={e} tab={tab} filter={filter} userHook={userHook}/>
            })
        }
        {tab === 1 && selected && (
          <div>
            {loading ? (
              <Loader />
            ) : selected.length === (page + 1) * 20 ? (
              <Button
                onClick={() => {
                  loadMore();
                  setLoading(true);
                }}
                color="primary"
              >
                <FormattedMessage id="load_more" />
              </Button>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
}

function MemberChatList({ endpoint }: { endpoint: string }) {
  const filters = useFilters();
  return React.useMemo(
    () => (
      <MemberList
        chat={OPEN_CHANNELS.openChannel}
        noGlobalFilters
        additionalInputs={{
          is_online: true,
          sort: endpoint === "search" ? "recently_online" : "date",
          genders: filters.filters.genders
        }}
        endpoint={endpoint as any}
        alwaysLoadMore
      />
    ),
    [endpoint, filters],
  );
}


export default ChatTabs;
