import { ReactElement, useEffect, useState } from "react";
import BlockedPopin from "../Popins/Blocked";
import Certificate from "../Popins/Certificate";
import ChatContent from "../Popins/ChatContent";
import ChatError from "../Popins/ChatError";
import ChatReport from "../Popins/ChatReport";
import CommonBoost from "../Popins/CommonBoost";
import Contact from "../Popins/Contact";
import Disclaimer from "../Popins/Disclaimer";
import EditProfile from "../Popins/EditProfile";
import EmailConfirm from "../Popins/EmailConfirm";
import EphemeViewer from "../Popins/EphemeViewer";
import Fanclub from "../Popins/Fanclub";
import ForceLoginPopin from "../Popins/ForceLogin";
import LoginPopin from "../Popins/Login";
import PaymentRequired from "../Popins/PaymentRequired";
import Subscriptions from "../Popins/Subscription";
import Tokens from "../Popins/Tokens";
import Trophy from "../Popins/Trophy";
import UnderagePopin from "../Popins/Underage";
import Uploading from "../Popins/Uploading";
import ContentProvider from "../Popins/ContentProvider";
import PickValidator from "../Popins/Underage/pickValidator";
import addCard from "../Popins/Underage/addCard";
import UploaderRequiredTemp from "../Popins/UploaderRequiredTemp";
import PaymentSuccessful from "../Popins/PaymentSuccessful";
import { USER } from "./user";

const popinsObj: {
  [Key in popinsNames as string]: any;
} = {
  login: LoginPopin,
  viewer: EphemeViewer,
  tokens: Tokens,
  disclaimer: Disclaimer,
  subscribe: Subscriptions,
  certificate: Certificate,
  edit: EditProfile,
  uploading: Uploading,
  contact: Contact,
  trophy: Trophy,
  blocked: BlockedPopin,
  contentprovider: ContentProvider,
  paymentrequired: PaymentRequired,
  emailconfirm: EmailConfirm,
  chatcontent: ChatContent,
  chatreport: ChatReport,
  underage: UnderagePopin,
  fanclub: Fanclub,
  chaterror: ChatError,
  forcelogin: ForceLoginPopin,
  commonboost: CommonBoost,
  pickvalidator: PickValidator,
  addcard: addCard,
  requiredtemp: UploaderRequiredTemp,
  paymentsuccessful: PaymentSuccessful
};

type popinsNames = "paymentsuccessful"|"requiredtemp"|"addcard"|"pickvalidator"|"commonboost"|"chaterror"|"fanclub"|"underage"|"chatreport"|"chatcontent"|"contentprovider"|"blocked"|"edit"|"login"|"paymentrequired"|"viewer"|"tokens"|"disclaimer"|"subscribe"|"certificate"|"uploading"|"contact"|"trophy"|"emailconfirm"|"forcelogin"

class Popins {
  popinsList: ReactElement[] = [];
  propsList: any[] = [];
  listener: any;
  display = (name: popinsNames, properties = {}) => {

    let isEmailConfirmed = USER.informations?.is_email_confirmed;
    
    if(!isEmailConfirmed && (name === "tokens" || name === "subscribe")){
      this.popinsList.push(popinsObj.emailconfirm);
      if(this.listener) this.listener({popins: this.popinsList, props: this.propsList});
      return;
    }

    this.popinsList.push(popinsObj[name]);
    this.propsList.push(properties);
    if(this.listener) this.listener({popins: this.popinsList, props: this.propsList});
  };

  close = () => {
    this.popinsList.shift();
    this.propsList.shift();
    if(this.listener) this.listener({popins: this.popinsList, props: this.propsList});
  };
}

const POPINS = new Popins();

export const closeCurrentPopin = POPINS.close;

export function displayPopin() {
  return POPINS.display;
}

(window as any).displayPopin = displayPopin;

(window as any).displayVerificationEmailPopin = () =>
  displayPopin()("emailconfirm");

export function useListenPopins() {
  const [popins, setPopins] = useState<{popins: ReactElement[], props: any[]}>({popins: POPINS.popinsList, props: POPINS.propsList});

  useEffect(() => {
    POPINS.listener = setPopins;
  }, []);

  return popins;
}
