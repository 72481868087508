import {
  faCamera,
  faClock,
  faFire,
  faLocationDot,
  faLocationPin,
  faRocket,
  faSquareFull,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router";
import {
  ephemesInformations_ephemes,
  membersInformations_members,
} from "../../Api/types";
import { displayPopin } from "../../Hook/popins";
import Avatar from "../Avatar";
import Pseudonym from "../Member/Pseudonym";
import { ReactComponent as Angel } from "../../Component/Filters/svg/angel.svg";
import { ReactComponent as Cutie } from "../../Component/Filters/svg/cutie.svg";
import { FormattedMessage, FormattedRelativeTime } from "react-intl";
import medalSVG from "./medal.svg";
import { FlameSVG } from "../SVG";

function MemberItem({
  member,
  chat,
  common,
  progress,
  medal,
  onClick,
  blocked,
  reload,
}: {
  medal?: number;
  chat?: boolean;
  reload?: any;
  blocked?: boolean;
  common?: boolean;
  progress?: boolean;
  onClick?: any;
  member: membersInformations_members;
  showPopin?: any;
  shouldSnapLeft: boolean;
}) {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        if (blocked) return displayPopin()("blocked", { member, reload });
        if (onClick) onClick();
        else navigate("/member/" + member.id);
      }}
      className={
        "w-96 relative lg:w-full flex max-w-[100%] col-span-1 h-32 rounded-lg overflow-hidden cursor-pointer under-tabs bg-cards"
      }
    >
      <div className="h-32 flex-shrink-0 flex-grow-0 w-32 relative">
        <div className="absolute left-2 top-1 bg-opacity-60 px-2 py-1 rounded-xl text-xs flex items-center gap-1 justify-center">
          <FontAwesomeIcon className="h-2.5 w-2.5 relative" icon={faCamera} />
          <div>{member.nb_public_ephemes}</div>
        </div>
        {medal && (
          <div className="absolute right-0 bottom-2 bg-opacity-60 px-2 py-1 rounded-xl text-xs flex items-center gap-1 justify-center">
            <img height={30} width={16} src={medalSVG} alt="medal image" />
            <div className="absolute top-[5px] text-[8px] left-1/2 -translate-x-1/2">
              {medal}
            </div>
          </div>
        )}
        <Avatar member={member} />
      </div>
      <div className="flex-1">
        <div className="text-lg mx-2 mt-2 overflow-hidden flex justify-between h-fit flex-1 items-center">
          <div className="flex items-center gap-2 w-full">
            <Pseudonym cards online member={member} />
          </div>
        </div>
        <div>
          <div className="text-md flex items-center mt-1 gap-2 px-2 pl-6 relative -left-0.5">
            <div className="flex gap-1 items-center">
              <FontAwesomeIcon
                className="text-primary"
                transform={"shrink-2"}
                icon={faFire}
                inverse
              />
              {progress
                ? "+" + member.flames_evolution
                : common
                  ? member.common_flames
                  : member.flames}
            </div>
            <div className="text-unactive">|</div>
            {common && (
              <div className="flex gap-1 items-center">
                {member.common_boost}%<FontAwesomeIcon icon={faRocket} />
              </div>
            )}
            {common && <div className="text-unactive">|</div>}
            <img
              className="h-4 w-4"
              src={`https://flagcdn.com/24x18/${member.countryInformations?.iso}.png`}
              alt="flag"
            />
            {(member.is_angel || member.is_cutie) && (
              <div className="text-unactive">|</div>
            )}
            {member.is_angel && <Angel className="text-md text-white" />}
            {member.is_cutie && <Cutie className="h-4 text-white" />}
          </div>
        </div>
        <div className="px-2 mt-1 flex justify-between pl-6">
          <div>
            {member.age_range && (
              <div className="mb-1 text-xs text-tabs">
                <FormattedMessage
                  id={"age_range__value_label__" + member.age_range}
                />
              </div>
            )}
            {member.cityInformations && (
              <div className="text-xs text-tabs">
                <FontAwesomeIcon className="mr-2" icon={faLocationDot} />
                {member.cityInformations?.name}{member.distance && <span> · {member.distance}km</span>}
              </div>
            )}
            {chat && (member as any).time_online &&  (
              <div className="absolute bottom-1 right-3 mb-1 text-xs text-tabs flex items-center gap-1">
                <FormattedRelativeTime
                  value={((member as any).time_online - Date.now() / 1000)}
                  numeric="auto"
                  updateIntervalInSeconds={60}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MemberItem;
