import { chatChannelsInformations, chatChannelsInformations_channels} from "../types";

const chatsCacheInformations:{[key:string]: chatChannelsInformations_channels} = {}
const chatsCacheInformationsFromMember:{[key:string]: chatChannelsInformations_channels} = {}

export const chatInformationsChatExistsWithMember = (member: string) => {
  return chatsCacheInformationsFromMember[member];
}

export const chatInformationsFilterFetchingList = (chats:{channels: string[]}) => {
  const alreadyInMemory:{[key:string]: chatChannelsInformations_channels} = {}
  const notInMemory:string[] = [];
  for(let chat of chats.channels){
    if(chatsCacheInformations[chat]){
      alreadyInMemory[chat] = chatsCacheInformations[chat]
    }else{
      notInMemory.push(chat);
    }
  }

  return {alreadyInMemory, notInMemory};
}

export const cachechats = (chats: chatChannelsInformations, member?: string) => {
  if(member){
    return chatsCacheInformationsFromMember[member] = chats.channels[0];
  }
  for(let chat of chats.channels){
    chatsCacheInformations[chat.id] = chat;
  }
}