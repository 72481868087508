import { FormattedMessage } from "react-intl";
import { closeCurrentPopin, displayPopin } from "../../Hook/popins";
import headerLogo from "./assets/headerLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faClose } from "@fortawesome/free-solid-svg-icons";
import certifiedSecure from "./assets/certifiedSecure.png";
import check from "./assets/check.png";
import clock from "./assets/clock.png";
import Button from "../../Component/Input/Button";

export default function UnderagePopin() {
  return <>
    <div onClick={closeCurrentPopin} className="top-0 left-0 right-0 bottom-0 fixed bg-black z-40 opacity-60"></div>
    <div className="z-50 lg:max-w-4xl h-fit flex flex-col gap-3 lg:pt-2 pt-4 px-4 pb-4 rounded-t-xl lg:rounded-xl bg-popinbg w-screen fixed max-h-[100dvh] scroll--numbers overflow-y-auto bottom-0 lg:bottom-unset lg:top-1/2 left-1/2 transform -translate-x-1/2 lg:-translate-y-1/2">
      <div className="flex gap-4 items-center lg:mb-0 mb-2">
        <img className=" h-8 w-8" src={headerLogo} alt="18 certified logo" />
        <h2 className="flex-1"><FormattedMessage id="underage__title_protect" /></h2>
        <div  onClick={closeCurrentPopin}><FontAwesomeIcon className="h-8 w-8 text-[#F34673]" icon={faClose} /></div>
      </div>
      <div className="bg-[#FFFDE4] rounded-xl flex-col lg:flex-row p-10 flex lg:gap-10 items-center">
        <img className=" h-32 w-32 relative bottom-2" src={certifiedSecure} alt="process certified secure" />
        <div className="flex flex-col gap-4">
          <h3 className="text-primary uppercase text-center lg:text-left text-xl font-bold"><span className="text-[#52E248]"><FormattedMessage id="underage__deactivate" /></span><br /><FormattedMessage id="underage__minors_protection" /></h3>
          <div className="flex gap-2 flex-wrap justify-center text-[#8D908A]">
            <div className="flex gap-2 items-center px-2 py-1 rounded-full bg-[#E9E9D3]"><img className=" h-8 w-8" src={check} alt="check" /><FormattedMessage id="underage__tag_1" /></div>
            <div className="flex gap-2 items-center px-2 py-1 rounded-full bg-[#E9E9D3]"><img className=" h-8 w-8" src={check} alt="check" /><FormattedMessage id="underage__tag_2" /></div>
            <div className="flex gap-2 items-center px-2 py-1 rounded-full bg-[#E9E9D3]"><img className=" h-8 w-8" src={check} alt="check" /><FormattedMessage id="underage__tag_3" /></div>
          </div>
        </div>
      </div>

      <div className="text-[#52E248]">
        <FormattedMessage id="underage__can_see_soft" />
      </div>
      <div>
        <FormattedMessage id="underage__in_order_to_protect" />
      </div>

      <Button onClick={() => {
        displayPopin()("pickvalidator");
        closeCurrentPopin();
      }} color="primary" className={"!py-4 !px-8 gap-8"}><FormattedMessage id="underage__continue" /><FontAwesomeIcon icon={faArrowRight}/></Button>
      <div className="text-xs flex items-center justify-center gap-2 text-[#8D908A]"><img className="h-[1.2em]" src={clock} alt="clock"/><FormattedMessage id="underage__instant_validation" /></div>
    </div>
  </>
}