import { faCheck, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
    options: { value: string, id?: number | string, message?: string }[],
    onChange: (value: any) => void,
    displayValue?: string,
    noDefault?: boolean,
    largePadding?: boolean
    selected?: any,
    defaultValue?: string
}

function Select({
    options,
    defaultValue,
    onChange,
    displayValue,
    noDefault,
    largePadding,
    selected
}: Props) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(defaultValue ?? (noDefault ? "" : options[0].value));

    return (
        <div className={'px-4 py-2 cursor-pointer bg-cards rounded-xl w-full relative select-none' + (largePadding ? " !px-8 !py-6" : "")}>
            <div className='flex items-center justify-between h-full' onClick={() => setOpen(!open)}>{displayValue ?? value} <FontAwesomeIcon className='text-woman' icon={open ? faChevronUp : faChevronDown} /></div>
            {open && <div className='absolute z-20 max-h-60 overflow-y-scroll bottom-1 left-0 right-0 translate-y-full bg-cards px-4 py-2 pt-4 flex flex-col gap-2 rounded-b-md'>
                {options.map(e => {
                    let isSelected = e.value === value;
                    if (selected) isSelected = selected.includes(e.value)
                    return (<div
                        className='flex gap-4 items-center'
                        onClick={() => { setValue(e.value); onChange(e.id ?? e.value); setOpen(!open) }}>
                        <div className={'h-3.5 w-3.5 flex-shrink-0 border-woman border rounded-xl flex items-center justify-center' + (isSelected ? " bg-woman" : "")}>
                            {isSelected && <FontAwesomeIcon className='text-white text-xs' icon={faCheck} />}
                        </div>
                        { e.message ? <div><FormattedMessage id={e.message} /></div>
                            :
                            <div>{e.value}</div>
                        }
                    </div>)
                })}
            </div>}
        </div>
    )
}

export default Select