export const defaultColors = {
  white: "#FFFFFF",
  black: "#000000",
  woman: "#fc4574",
  man: "#4d6dad",
  couple: "#EE82EE",
  grey: {
    nickel: '#BDBDBC',
    jumbo: "#858586",
    jumboLight: "#76767A",
    dove: "#6F6F6F",
    tuna: "#2F2F34",
    woodsmoke: "#17171A",
    popin: "#1D1D23",
    item: "#1e1e22",
  },
  pink: {
    violetRed: "#fc4574",
    maroonFlush: "#D31D59",
    disco: "#881450",
  },
  violet: {
    seance: "#692398",
    electric: "#d326f6",
    cornflower: "#7f75fa",
  },
  red: {
    ribbon: "#f42a41",
  },
  green: {
    scooter: "#27cad5",
    turquoise: "#36EBBC",
    emerald: "#4BD963",
    _true: "#4CD964",
    cool: "#90AE85"
  },
  orange: {
    webOrange: "#FEA700",
    saffron: "#F4BA2F",
    scarlet: "#ff3400",
  },
  blue: {
    picton: "#3a8bed",
  },
  gold: "#FBAE23",
  bronze: "#FE9D66",
  silver: "#AACCD5",
  facebook: "#395897",
  gmail: "#FD1F51",
  tests: {
    blue: "#1F3280",
  },
  background: "#17171A"
};

export const PROCESS_COLOURS = () => {
  const platformColours = { ...defaultColors, ...(document as any).colours };
  for (let key in platformColours) {
    if (typeof platformColours[key] == "object") {
      for (let key2 in platformColours[key]) {
        if(platformColours[key][key2].startsWith("<?php")) continue;
        document.documentElement.style.setProperty(`--${key}-${key2}`, platformColours[key][key2]);
      }
    } else {
      document.documentElement.style.setProperty(`--${key}`, platformColours[key]);
    }
  }
}