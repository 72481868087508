import { faEye, faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate, useParams } from 'react-router'
import { api_postEphemesView } from '../../Api/epheme'
import { ephemesInformations, ephemesInformations_ephemes, ephemesView } from '../../Api/types'
import { api_postValueOrErrorMessage } from '../../Api/util'
import Button from '../../Component/Input/Button'
import Loader from '../../Component/Loader'
import MemberList from '../../Component/MemberList'
import Content from '../Content'
import Footer from '../Footer'
import Header from '../Header'
import Main from '../Main'
import MenuMobile from '../MenuMobile'
import useUser from '../../Hook/user'

function MyEpheme() {
  const [epheme, setEpheme] = useState<ephemesView & ephemesInformations_ephemes>();
  const params = useParams<{ id: string }>();
  const user = useUser()!;
  const navigate = useNavigate();

  useEffect(() => {
    if (user.informations?.type === "guest") {
      navigate("/");
    }
  }, [])

  useEffect(() => {
    (async () => {
      if (!params.id) return navigate("/");
      let ephemesInfos = await api_postValueOrErrorMessage<ephemesInformations>("ephemes/informations", { ephemes: [params.id] });
      if (ephemesInfos.error_message) return navigate("/")
      let ephemeView = await api_postEphemesView(params.id);
      if (ephemeView.error_message) return navigate("/");
      setEpheme({ ...ephemesInfos.ephemes[0], ...ephemeView } as unknown as ephemesView & ephemesInformations_ephemes);
    })()
  }, [params.id])
  return (<>
    <Header goBack />
    <Content>
      <Main noAds>
        <div className="m-auto w-full max-w-5xl flex flex-col gap-8">
          {epheme ? <div className='flex flex-col gap-4 pb-8'>
            <h3 className='text-center'>"{epheme.title}"</h3>
            <div className='relative'>
              <div className=' px-2 py-1 bg-background rounded-xl absolute top-2 right-2'>
                {epheme.views} <FontAwesomeIcon icon={faEye} />
              </div>
              {epheme.photo_uncropped ? <img className='max-h-[80vh] mx-auto interact' src={epheme.photo_uncropped} /> : <video className='max-h-[80vh] mx-auto interact' src={epheme.video_hd} controls />}
            </div>
            <Button color="primary" name="myepheme__delete" onClick={() => {
              api_postValueOrErrorMessage("ephemes/delete", { id: params.id }).then(e => {
                navigate("/me")
              })
            }} />
            <h2 className='text-primary text-center text-xl'><FormattedMessage id="myephemeviewer__they_liked" /> ({epheme.likes} <FontAwesomeIcon icon={faHeart} />)</h2>
            {epheme.likes > 0 && <MemberList noGlobalFilters additionalInputs={{ genders: ["man", "woman", "couple"], id: params.id }} endpoint="likes" />}
          </div>
            : <>
              <Loader />
            </>}
        </div>
      </Main>
    </Content>
    <Footer />
    <MenuMobile />
  </>)
}

export default MyEpheme