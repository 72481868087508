import { faCircleCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '../../Component/Input/Button';
import { closeCurrentPopin } from '../../Hook/popins'

import "./certif.css";
import { api_postValueOrErrorMessage } from '../../Api/util';
import { api_requestCertification } from '../../Api/user';
import Loader from '../../Component/Loader';

function Certificate() {
    const [file, setFile] = useState<File | null>(null);
    const inputFile = useRef<HTMLInputElement>(null);
    const [end, setEnd] = useState(false)
    const [loading, setLoading] = useState(false);

    if (end) {
        return (
            <>
                <div onClick={closeCurrentPopin} className="top-0 left-0 right-0 bottom-0 fixed bg-black opacity-60 z-40"></div>
                <div className="z-[100] p-8 lg:rounded-xl bg-popinbg lg:max-w-2xl w-screen fixed max-h-[100dvh] scroll--numbers overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-4" >
                    <div onClick={closeCurrentPopin} className='absolute top-9 right-8'><FontAwesomeIcon icon={faClose} /></div>
                    <div className="text-xl text-center font-semibold"><FormattedMessage id="certification__title_certificate" /></div>
                    <FontAwesomeIcon icon={faCircleCheck} className='w-10 h-10 m-auto'/>
                    <div className='whitespace-break-spaces'><FormattedMessage id="certification__result_success" /></div>
                    <Button
                        name="certification__goto_home"
                        onClick={() => {
                            closeCurrentPopin();
                        }}
                        color="primary"
                        fillSpace
                    />
                </div>
            </>
        )
    }

if (file) {
    return (
        <>
            <div onClick={closeCurrentPopin} className="top-0 left-0 right-0 bottom-0 fixed bg-black opacity-60 z-1z-400"></div>
            <div className="z-[100] p-8 lg:rounded-xl bg-popinbg lg:max-w-2xl w-screen fixed max-h-[100dvh] scroll--numbers overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-4">
                <div onClick={closeCurrentPopin} className='absolute top-9 right-8'><FontAwesomeIcon icon={faClose} /></div>
                <div className="text-xl text-center font-semibold"><FormattedMessage id="certification__title_certificate" /></div>
                {loading && <div className='absolute top-0 left-0 right-0 bottom-0 bg-[#00000077] flex items-center justify-center'><Loader /></div>}
                <img src={URL.createObjectURL(file)} />
                <div className='flex gap-2'>
                    <Button
                        name="certification__cancel_button"
                        appearance='outline'
                        onClick={() => setFile(null)}
                        color="primary"
                        fillSpace
                    />
                    <Button
                        name="certification__validate_button"
                        onClick={() => {
                            setLoading(true);
                            api_requestCertification(file).then(() => {setEnd(true)});
                        }}
                        color="primary"
                        fillSpace
                    />
                </div>
            </div>
        </>
    )
}
return (
    <>
        <div onClick={closeCurrentPopin} className="top-0 left-0 right-0 bottom-0 fixed bg-black opacity-60 z-z-4010"></div>
        <div className="z-[100] p-8 lg:rounded-xl bg-popinbg lg:max-w-2xl w-screen fixed max-h-[100dvh] scroll--numbers overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-4" >
            <div onClick={closeCurrentPopin} className='absolute top-9 right-8'><FontAwesomeIcon icon={faClose} /></div>
            <div className="text-xl text-center font-semibold"><FormattedMessage id="certification__title_certificate" /></div>

            <div id="certifbody" className='whitespace-pre-wrap'><FormattedMessage id="certification__body_certificate" /></div>
            <Button name="certificate__title_button" onClick={() => {
                inputFile.current?.click();
            }}
                color="primary"
                fillSpace
            />
            <input onChange={(event) => {
                setFile(event.target.files?.[0]!);
            }} type="file" className='hidden' accept='image/*' ref={inputFile} />
        </div>
    </>
)
}
export default Certificate