import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { closeCurrentPopin, displayPopin } from "../../Hook/popins";
import ContentProviderEntry from "./entry";
import ContentProviderForm from "./form";
import ContentProviderSteps from "./steps";
import plus18 from "./assets/plus18.png";
import ContentProviderIntro from "./intro";
import { api_getValueOrErrorMessage } from "../../Api/util";

function ContentProvider(props:any) {
  const [step, setStep] = useState(-1);
  const [iURL, setIURL] = useState("");

  const handle = async () => {
    let answer = await api_getValueOrErrorMessage<{ iframe_url: string }>("user/identity/verify");

    if (answer.error_message === "user_not_customer") {
      closeCurrentPopin();
      return displayPopin()("paymentrequired")
    }

    setIURL(answer.iframe_url);
  }

  useEffect(() => {
    (async () => {
      if(props.type=="steps") return setStep(1);
      if(props.type=="form") return setStep(2);
      if(props.type=="iframe") {
        let answer = await api_getValueOrErrorMessage<{ iframe_url: string }>("user/identity/verify");
  
        if (answer.error_message === "user_not_customer") {
          closeCurrentPopin();
          return displayPopin()("paymentrequired")
        }
        setIURL(answer.iframe_url);
      }
    })()
    
  }, [])

  return (
    <>
      <div
        onClick={closeCurrentPopin}
        className="fixed bottom-0 left-0 right-0 top-0 bg-black opacity-60 z-40"
      ></div>
      <div
        className={
          "bg-popinbg pb-4 scroll--numbers fixed left-1/2 top-1/2 z-50  flex max-h-[100dvh] w-screen -translate-x-1/2 -translate-y-1/2 transform flex-col overflow-y-auto lg:rounded-xl " +
          (step === 10
            ? "px-0 pb-4 pt-0 lg:w-fit lg:px-8 lg:pt-8"
            : "lg:max-w-4xl")
        }
      >
        <div className="flex items-center justify-between px-8 gap-2 py-2">
          <div><img className="h-7" src={plus18} alt="plus 18" /></div>
          <h2 className="flex-1 text-lg font-bold">
            <FormattedMessage id="content_provider__requirements_title" />
          </h2>
          <FontAwesomeIcon onClick={closeCurrentPopin} icon={faClose} />
        </div>
        <div className="bg-unactive h-px w-full" />
        <div className="flex flex-col gap-4 px-8 pt-4">
          {step === -1 && <ContentProviderIntro nextStep={() => setStep(0)} />}
          {step === 0 && <ContentProviderEntry nextStep={() => setStep(1)} />}
          {step === 1 && (
            <ContentProviderSteps
              goForm={() => setStep(2)}
              showIframeContentProvider={(url: string) => {
                setStep(10);
                setIURL(url);
              }}
            />
          )}
          {step === 2 && (
            <ContentProviderForm
              showIframeContentProvider={(url: string) => {
                setStep(10);
                setIURL(url);
              }}
            />
          )}

          {step === 10 && (
            <>
              <iframe
                title="ContentProvider Iframe"
                className="h-[80vh] w-screen lg:w-[80vw]"
                id="yousign-iframe"
                src={iURL}
                allow="camera"
              ></iframe>
              <div
                className="cursor-pointer text-center"
                onClick={closeCurrentPopin}
              >
                <FormattedMessage id="content_provider__close_iframe" />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ContentProvider;
