import { FormattedMessage } from "react-intl";
import { closeCurrentPopin, displayPopin } from "../../Hook/popins";
import headerLogo from "./assets/headerLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faChevronRight, faClose } from "@fortawesome/free-solid-svg-icons";
import subscription from "./assets/subscription.png";
import background from "./assets/background.png";
import background2 from "./assets/background3.png";
import validateunlock from "./assets/validateunlock.png";
import creditcard from "./assets/creditcard.png";
import useIsMobile from "../../Hook/mobile";

export default function PickValidator() {
  const detect = useIsMobile();
  return <>
    <div onClick={closeCurrentPopin} className="top-0 left-0 right-0 bottom-0 fixed bg-black z-40 opacity-60"></div>
    <div className="z-50 lg:max-w-4xl flex flex-col gap-3 lg:pt-2 pt-4 px-4 pb-4 rounded-t-xl lg:rounded-xl bg-popinbg w-screen fixed max-h-[100dvh] scroll--numbers overflow-y-auto bottom-0 lg:bottom-auto lg:top-1/2 left-1/2 transform -translate-x-1/2 lg:-translate-y-1/2">
      <div className="flex gap-4 items-center lg:mb-0 mb-2">
        <img className=" h-8 w-8" src={headerLogo} alt="18 certified logo" />
        <h2 className="flex-1"><FormattedMessage id="underage__title_validate_majority" /></h2>
        <div onClick={closeCurrentPopin}><FontAwesomeIcon className="h-8 w-8 text-[#F34673]" icon={faClose} /></div>
      </div>
      <div className="flex gap-12 lg:flex-row flex-col">
        <div className="relative flex-shrink-0 flex-1 items-center justify-center">
          <div className="absolute px-4 z-10 top-0 left-0  flex flex-col items-center lg:gap-4 right-0 bottom-0">
            <img alt="" className="lg:w-60 w-40 lg:pt-24 lg:pb-10" src={validateunlock} />
            <div className="text-[#D300EC] text-xl uppercase lg:mt-30"><FormattedMessage id="underage__unlock_options_count" /></div>
            <div className="text-[#003471]"><FormattedMessage id="underage__less_than_2_mn" /></div>
          </div>
          <img alt="" className="w-full h-48 lg:h-full" src={detect.isMobile ? background2 : background} />

        </div>
        <div className="flex-1 flex-shrink-0 flex flex-col gap-4 justify-center">
          <div className="flex-1 flex flex-col gap-2">
            <div className="text-tabs"><FormattedMessage id="underage__option_1" /></div>
            <div onClick={() => {
              displayPopin()("addcard");
              closeCurrentPopin();
            }} className="flex flex-1 border border-cards rounded-xl hover:border-primary cursor-pointer gap-6 overflow-hidden relative items-center px-4 py-8 bg-cards">
              <div className="absolute bshadow top-2/5 left-20 z-0"></div>
              <div className="relative flex-shrink-0 z-10"><img alt="" className="w-32" src={creditcard} /></div>
              <div>
                  <h4 className="text-lg text-primary flex justify-between items-center"><FormattedMessage id="underage__credit_card_option" /><FontAwesomeIcon icon={faChevronRight} /></h4>
                  <p><FormattedMessage id="underage__credit_card_description" /></p>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-2">
            <div className="text-tabs"><FormattedMessage id="underage__option_2" /></div>
            <div onClick={() => {
              displayPopin()("subscribe");
              closeCurrentPopin();
            }} className="flex flex-1 border border-cards rounded-xl hover:border-primary cursor-pointer gap-6 overflow-hidden relative items-center px-4 py-8 bg-cards">
              <div className="absolute bshadow top-2/5 left-20 z-0"></div>
              <div className="relative  flex-shrink-0 z-10 w-32 flex justify-center"><img alt="" className="h-32 max-w-32" src={subscription} /></div>
              <div>
                  <h4 className="text-lg text-primary flex justify-between items-center"><FormattedMessage id="underage__subscription_option" /><FontAwesomeIcon icon={faChevronRight} /></h4>
                  <p><FormattedMessage id="underage__subscription_description" /></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}