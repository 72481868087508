import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import useIsMobile from '../Hook/mobile';
import { displayPopin } from '../Hook/popins';
import useUser from '../Hook/user';

function Footer() {
  const user = useUser();
  const mobile = useIsMobile();
  if(mobile.isMobile) return null;
  return (
    <div className='flex w-full justify-center gap-6 py-6 mt-12 bg-cards flex-wrap text-xs'>
      <div> <FormattedMessage id="footer__site_name" /> </div>
      {!mobile.isMobile && <>
        <div> - </div>
        <div className='cursor-pointer' onClick={() => displayPopin()("contact")}><FormattedMessage id="menu__contact" /></div>
        <Link to="/cgus"><FormattedMessage id="menu__cgu" /></Link>
        <Link to="/privacy"><FormattedMessage id="menu__privacy" /></Link>
        <Link to="/2257"><FormattedMessage id="menu__exemption" /></Link>
        <Link to="/dmca"><FormattedMessage id="menu__dmca" /></Link>
        {user?.informations?.has_billing_transactions && <Link to="/desabonnement"><FormattedMessage id="menu__unsubscribe" /></Link>}
      </>}
    </div>
  )
}

export default Footer