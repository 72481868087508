import "./App.css";
import usePlatform from "./Hook/platform";
import useUser from "./Hook/user";
import Router from "./Router";

import { ThemeProvider } from "@material-tailwind/react";
import LanguageProvider from "./LanguageProvider";
import { PROCESS_COLOURS } from "./colours";
import useIsMobile from "./Hook/mobile";
import { useEffect } from "react";
PROCESS_COLOURS();
const App = () => {
  const platform = usePlatform();
  const user = useUser();
  const detectMobile = useIsMobile();
  useEffect(() => {
    const preventDefault = (event: any) => {
      event.preventDefault();
    };
    document.addEventListener("dragover", preventDefault);
    document.addEventListener("drop", preventDefault);

    return () => {
      document.removeEventListener("dragover", preventDefault);
      document.removeEventListener("drop", preventDefault);
    };
  }, []);
  if (!platform || !user) {
    return (
      <div className="bg-background min-h-screen min-w-screen lg:px-8 px-4"></div>
    );
  }

  return (
    <LanguageProvider>
      <ThemeProvider>
        <div
          id="main-bg"
          className="bg-background min-h-screen min-w-screen max-w-screen overflow-x-hidden lg:px-0 flex flex-col"
        >
          <Router />
        </div>
      </ThemeProvider>
    </LanguageProvider>
  );
};

export default App;
