import { cacheCities, cityInformationsFilterFetchingList } from "./cache/cities";
import { cacheCountries, countryInformationsFilterFetchingList } from "./cache/countries";
import { locationCitiesInformations, locationCitiesInformations_cities, locationCountriesInformations, locationCountriesInformations_countries } from "./types";
import { api_postValueOrErrorMessage } from "./util";

export async function getCountryInformations(toFetchids: number[]) {
  const countriesCachedResult = countryInformationsFilterFetchingList(toFetchids);
  const countries = countriesCachedResult.notInMemory.length ?
    await api_postValueOrErrorMessage<locationCountriesInformations>(`location/countries/informations`, { countries: countriesCachedResult.notInMemory })
    : { countries: [] };

  cacheCountries(countries);

  const returnArray: locationCountriesInformations_countries[] = [];
  let index = 0;
  for (let country of toFetchids) {
    if (countriesCachedResult.inMemory[country]) {
      returnArray.push(countriesCachedResult.inMemory[country])
    }
    else {
      returnArray.push(countries.countries[index]);
      index++;
    }
  }
  return returnArray;
}

export async function getCitiesInformations(toFetchids: number[]) {
  const citiesCacheResult = cityInformationsFilterFetchingList(toFetchids);
  const cities = citiesCacheResult.notInMemory.length ?
    await api_postValueOrErrorMessage<locationCitiesInformations>(`location/cities/informations`, { cities: citiesCacheResult.notInMemory })
    : { cities: [] };

  cacheCities(cities);

  const returnArray: locationCitiesInformations_cities[] = [];
  let index = 0;
  for (let country of toFetchids) {
    if (citiesCacheResult.inMemory[country]) {
      returnArray.push(citiesCacheResult.inMemory[country])
    }
    else {
      returnArray.push(cities.cities[index]);
      index++;
    }
  }
  return returnArray;
}