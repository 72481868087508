import React from 'react'
import { closeCurrentPopin } from '../../Hook/popins'
import { FormattedMessage } from 'react-intl'

function ChatError({ error }: any) {
    return (
        <>
            <div onClick={closeCurrentPopin} className="top-0 left-0 z-[110] right-0 bottom-0 fixed bg-black opacity-60"></div>
            <div className="z-[120] p-8 lg:max-w-2xl bg-popinbg w-screen fixed max-h-[100dvh] flex flex-col gap-4 scroll--numbers overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <div className='text-center text-xl'><FormattedMessage id="chaterror__restriction_chat_access" /></div>
                <hr className='mb-2' />
                <FormattedMessage id={"chaterror__label__date_end"}></FormattedMessage> {new Date(error.time_end * 1000).toLocaleDateString()}<br />
                <FormattedMessage id={"chaterror__label__reason"}></FormattedMessage> <FormattedMessage id={"chaterror__label__reason_" + error.reason}></FormattedMessage>
            </div>
        </>
    )
}

export default ChatError