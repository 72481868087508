import React, { useEffect, useState } from 'react'
import {IntlProvider} from 'react-intl'
import useLocale from './Hook/locale';

function LanguageProvider({children}:any) {
  const locale = useLocale();

  useEffect(() => {
    locale.changeLang(localStorage.getItem("lang") ?? navigator.language.split("-")[0]);
  }, []);
  
  return (
    <IntlProvider messages={locale.translation} locale={locale.locale} defaultLocale="en"
      defaultRichTextElements={{ 
        b: (chunks) => <b>{chunks}</b>,
        strong: (chunks) => <strong>{chunks}</strong>,
        span: (chunks) => <span>{chunks}</span>,
        br: () => <br />,
        p: (chunks) => <p>{chunks}</p>,
        u: (chunks) => <u>{chunks}</u>,
        i: (chunks) => <i>{chunks}</i>,
       }}
    >
      {children}
    </IntlProvider>
  )
}

export default LanguageProvider