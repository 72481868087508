import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { api_getValueOrErrorMessage } from '../../Api/util';
import Button from '../../Component/Input/Button';
import { closeCurrentPopin, displayPopin } from '../../Hook/popins';
import id from "./assets/id.png";
import mail from "./assets/mail.png";
import sign from "./assets/sign.png";
import { USER } from '../../Hook/user';

var blockClick = false;

function ContentProviderSteps({ showIframeContentProvider, goForm }: { showIframeContentProvider: any, goForm: any }) {
  const [identity, setIdentity] = useState<any>({});
  const [checked, setChecked] = useState(false);
  let status = "content_provider__not_verified"
  if (identity.is_verified === "pending") status = "content_provider__pending"
  if (identity.is_verified === "yes") status = "content_provider__verified"
  if (identity.error_code) status = "content_provider__error_" + identity.error_code;

  let identitySigned = identity.is_verified === "yes" && !identity.lastname;

  useEffect(() => {
    (async () => {
      let answer = await api_getValueOrErrorMessage<{ iframe_url: string }>("user/identity");
      setIdentity(answer)
    })()
    return () => { blockClick = false };
  }, [])

  const handleStepClick = async () => {
    if (blockClick) return;
    blockClick = true;

    if (identity.is_verified !== "yes") {
      let answer = await api_getValueOrErrorMessage<{ iframe_url: string }>("user/identity/verify");

      if (answer.error_message === "user_not_customer") {
        closeCurrentPopin();
        return displayPopin()("paymentrequired")
      }
      blockClick = false;
      showIframeContentProvider(answer.iframe_url);
    }
  }
  return (
    <>
      <p><FormattedMessage id="content_provider__requirements_description" /></p>
      <div onClick={handleStepClick} className='cursor-pointer relative p-4 pl-8 bg-[#470841] rounded-xl flex flex-col lg:flex-row gap-3 items-center'>
        <div>
          <img src={id} alt="" className="max-w-[8rem]" />
        </div>
        <div className='flex flex-col gap-2'>
          <div className='px-4 py-1 bg-[#00000044] w-fit rounded-full'><FormattedMessage id="content_provider__step_1" /></div>
          <div className='text-xl text-green'><FormattedMessage id="content_provider__step2_req2_title" /></div>
          <div className='text-xs'><FormattedMessage id="content_provider__step2_req2_desc" /></div>
          <div className='mt-6 px-2 py-4 bg-[#00000044] w-fit rounded-xl lg:flex hidden  items-center gap-2'>
            <div><FormattedMessage id="content_provider__current_status" /></div>
            {identity.is_verified === "yes" && <FontAwesomeIcon icon={faCheck} />}
            <div className={identity.is_verified === "yes" ? "text-green" : "text-red-600"}><FormattedMessage id={status} /></div>
          </div>
        </div>
        <div className='mt-6 px-2 py-4 bg-[#00000044] w-fit rounded-xl lg:hidden flex  items-center gap-2'>
          <div><FormattedMessage id="content_provider__current_status" /></div>
          {identity.is_verified === "yes" && <FontAwesomeIcon icon={faCheck} />}
          <div className={identity.is_verified === "yes" ? "text-green" : "text-red-600"}><FormattedMessage id={status} /></div>
        </div>
        {/* <div className='absolute left-0 top-1/2 -translate-x-1/2 -translate-y-1/2 h-10 w-10 border rounded-full border-border bg-popinbg flex justify-center items-center'>
          {identity.is_verified === "yes" ? <FontAwesomeIcon icon={faCheck} />: 1}
        </div>
        <div className='flex-1'>
          <span className=' font-bold'><FormattedMessage id="content_provider__step2_req2_title" /></span>
          <br />
          <FormattedMessage id="content_provider__step2_req2_desc" />
        </div>
        <div className={'h-8 w-8 rounded-full border-2 border-white overflow-hidden' + (identity.is_verified === "yes" ? " bg-primary" : " bg-blue-gray-300")}></div>
        <div className='absolute right-2 bottom-2 text-primary'><FormattedMessage id={status} /></div> */}
      </div>
      <div onClick={() => {
        if (identity.is_verified === "yes" && !identitySigned) {
          setChecked(!checked);
        }
      }} className='cursor-pointer relative p-4 pl-8 bg-[#2F2B81] rounded-xl flex flex-col lg:flex-row gap-3 items-center'>
        <div>
          <img src={sign} alt="" className="max-w-[8rem]" />
        </div>
        <div className='flex flex-col gap-2'>
          <div className='px-4 py-1 bg-[#00000044] w-fit rounded-full'><FormattedMessage id="content_provider__step_2" /></div>
          <div className='text-xl text-green'><FormattedMessage id="content_provider__step2_req1_title" /></div>
          <div className='text-xs'><FormattedMessage id="content_provider__step2_req1_desc" /></div>
          <div className='mt-6 lg:flex hidden px-2 py-4 bg-[#00000044] w-fit rounded-xl items-center gap-2'>
            <div><FormattedMessage id="content_provider__current_status" /></div>
            {identitySigned && <FontAwesomeIcon icon={faCheck} />}
            <div className={identitySigned ? "text-green" : "text-red-600"}><FormattedMessage id={identitySigned ? "content_provider__verified" : "content_provider__not_signed"} /></div>
          </div>
        </div>
        <div className='mt-6 lg:hidden flex px-2 py-4 bg-[#00000044] w-fit rounded-xl items-center gap-2'>
          <div><FormattedMessage id="content_provider__current_status" /></div>
          {identitySigned && <FontAwesomeIcon icon={faCheck} />}
          <div className={identitySigned ? "text-green" : "text-red-600"}>
            <FormattedMessage id={identitySigned ? "content_provider__verified" : "content_provider__not_signed"} />
          </div>
        </div>
        {/* <div className='absolute left-0 top-1/2 -translate-x-1/2 -translate-y-1/2 h-10 w-10 border rounded-full border-border bg-popinbg flex justify-center items-center'>
          {checked ? <FontAwesomeIcon icon={faCheck} /> : 2}
        </div>
        <div className='flex-1'>
          <span className=' font-bold'><FormattedMessage id="content_provider__step2_req1_title" /></span>
          <br />
          <FormattedMessage id="content_provider__step2_req1_desc" />
        </div>
        <div className={'h-8 w-8 rounded-full border-2 border-white overflow-hidden' + (checked ? " bg-primary" : " bg-blue-gray-300")}></div>
        <div className='absolute right-2 bottom-2 text-primary'><FormattedMessage id="content_provider__not_signed" /></div> */}
      </div>
      <Button name="content_provider__go_form" fillSpace color={checked ? "primary" : 'border'} onClick={() => {
        if (checked && !identitySigned) goForm();
      }} />
    </>
  )
}

export default ContentProviderSteps