import { useEffect, useState } from "react";
import RToast  from "../Component/Toast";
import PToast from "../Component/Toast/PToast";
class Toast {
  toastList: any[] = [];
  listener: any;
  currentID = 0;
  display = (member:any, title:string, message:string, toastType: "messageReceived"|"profileVisit") => {
    this.toastList.push(
      toastType === "messageReceived" ? 
      <RToast member={member} title={title} message={message} id={this.currentID}/>
      :
      <PToast member={member} title={title} message={message} id={this.currentID} />
    );
    this.currentID +=1;
    this.listener({toast: this.toastList});
  };

  close = () => {
    this.toastList.shift();
    this.listener({toast: this.toastList});
  };
}

const TOAST = new Toast();

export const closeCurrentToast = TOAST.close;

export const displayToast = TOAST.display;

export function useToast() {
  const [toasts, setToasts] = useState<{toast: any[]}>({toast: []});

  useEffect(() => {
    TOAST.listener = setToasts;
  }, []);

  return toasts;
}
