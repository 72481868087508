import { faCoins, faCreditCardAlt, faHandshake } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { userTokensOffers_offers } from '../../Api/types'
import CardForm from '../../Component/CardForm'
import CardList from '../../Component/CardList'
import Button from '../../Component/Input/Button'
import { FormatPrice, handleSecurionPayTokenTransaction } from '../../Component/utils'
import { USER } from '../../Hook/user'
import { closeCurrentPopin, displayPopin } from '../../Hook/popins'
import { api_logErrorPayment } from '../../Api/log'
import { PLATFORM } from '../../Hook/platform'
import Loader from '../../Component/Loader'

function CardsPicker({ tokenOffer, setStep }: { tokenOffer: userTokensOffers_offers, setStep: any }) {
    const [index, setIndex] = useState(0);
    const [pick, setPickMenu] = useState(false);
    const [newCard, setNewCard] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    

    const paymentProcessCallBack = (obj: any) => {
        if (obj.status === "error") {
            api_logErrorPayment({obj, securionLoaded: !!(window as any).SecurionPay, errorFrom:"API", spKey: PLATFORM.platform?.securionpay_public_key , USER: USER.informations?.id})
            setLoading(false);
            return setError("securion__" + obj.error_message);
        }
        //error securionpay
        if (obj.error){ 
            api_logErrorPayment({obj, securionLoaded: !!(window as any).SecurionPay, errorFrom:"SecurionPay", spKey: PLATFORM.platform?.securionpay_public_key , USER: USER.informations?.id})
            setLoading(false);
            return setError("securion__" + obj.error.code);
        }
        setTimeout(USER.fetch,2500);
        closeCurrentPopin();
        displayPopin()("paymentsuccessful", {amount: tokenOffer.price, currency: tokenOffer.currency});
    }

    return (
        <div className='flex flex-col gap-4 px-4 lg:px-0'>
            <div className='text-xl text-center [&>*]:text-primary'><FormattedMessage id="popin__tokens_title" /></div>
            <div className='flex p-4 rounded-xl bg-background gap-2 items-center'>
                <FontAwesomeIcon icon={faCoins} />
                <FormattedMessage id="payment_token_description_tokens" values={{ nb_tokens: tokenOffer.tokens }} />
                <div className='flex font-bold flex-1 justify-end'><FormatPrice price={tokenOffer.price} currency={tokenOffer.currency} /></div>
            </div>
            {loading && <div className='inset-0 absolute bg-black opacity-60 flex items-center justify-center'><Loader /></div>}
            {!newCard && <CardList index={index} setIndex={setIndex} pick={pick} setPickMenu={setPickMenu} />}
            {!pick && <CardForm setNewCard={setNewCard} newCard={newCard} />}
            {error && <div className=' text-red-600'><FormattedMessage id={error} /></div>}
            <div className='flex gap-2 '>
                <Button fillSpace name="confirmmodal__cancel" color="primary" appearance='outline' onClick={() => { newCard ? setNewCard(false) : pick ? setPickMenu(false) : setStep(0) }} />
                {!pick && <Button fillSpace name="payment__buy_now" color="primary" onClick={() => { if(loading)return; setLoading(true); handleSecurionPayTokenTransaction(tokenOffer, index, paymentProcessCallBack, newCard) }} />}
            </div>
            <div className='flex px-4  justify-between text-xs'>
                <div><FontAwesomeIcon className='mr-1' icon={faCreditCardAlt} /><FormattedMessage id="popin__upgrade_account_secured_payement" /></div>
                <div><FontAwesomeIcon className='mr-1' icon={faHandshake} /><FormattedMessage id="popin__upgrade_account_anonymous" /></div>
            </div>
            <div className='text-xs px-4  text-center'><FormattedMessage id="popin__upgrade_account_bank_note" /></div>
            <div className='text-xs px-4  text-center'><FormattedMessage id="popin__upgrade_account_service_provider" /></div>
        </div>
    )
}

export default CardsPicker