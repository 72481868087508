import React from 'react'
import { closeCurrentPopin } from '../../Hook/popins'
import required from "./required.png";
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
function UploaderRequiredTemp() {
  return (
    <>
      <div onClick={closeCurrentPopin} className="top-0 left-0 right-0 bottom-0 fixed bg-black opacity-60 z-40"></div>
      <div className="z-50 p-8 lg:max-w-2xl bg-popinbg w-screen fixed max-h-[100dvh] scroll--numbers overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div onClick={closeCurrentPopin} className='absolute top-2 right-4'><FontAwesomeIcon className='text-label h-8' icon={faClose} /></div>
        <div className='flex flex-col gap-4 justify-center items-center'>
          <img className='w-fit' src={required} alt="required" />
          <div className='text-xl [&>*]:text-primary'><FormattedMessage id="uploader__required_temp" /></div>
        </div>
      </div>
    </>
  )
}

export default UploaderRequiredTemp