import { faClose, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, useIntl } from 'react-intl';
import { closeCurrentPopin, displayPopin } from '../../Hook/popins';
import background from "./assets/background2.png";
import background2 from "./assets/background4.png";
import creditcard from "./assets/creditcard.png";
import headerLogo from "./assets/headerLogo.png";
import check from "./assets/check.png";
import TextInput from '../../Component/Input/TextInput';
import CreditCard from '../../Component/CreditCard';
import { USER } from '../../Hook/user';
import useIsMobile from '../../Hook/mobile';

export default function AddCard() {
  const intl = useIntl();
  const detect = useIsMobile();

  const cb = () => {
    closeCurrentPopin();
    USER.fetch();
  }
  return <>
    <div onClick={closeCurrentPopin} className="top-0 left-0 right-0 bottom-0 fixed bg-black z-40 opacity-60"></div>
    <div className="z-50 lg:max-w-4xl flex flex-col gap-3 lg:pt-2 pt-4 px-4 pb-4 rounded-t-xl lg:rounded-xl bg-popinbg w-screen fixed max-h-[100dvh] scroll--numbers overflow-y-auto bottom-0 lg:bottom-auto lg:top-1/2 left-1/2 transform -translate-x-1/2 lg:-translate-y-1/2">
      <div className="flex gap-4 items-center lg:mb-0 mb-2">
        <img className=" h-8 w-8" src={headerLogo} alt="18 certified logo" />
        <h2 className="flex-1"><FormattedMessage id="underage__title_validate_majority" /></h2>
        <div onClick={closeCurrentPopin}><FontAwesomeIcon className="h-8 w-8 text-[#F34673]" icon={faClose} /></div>
      </div>
      <div className="relative w-full h-80 lg:h-60 flex-shrink-0 flex-1 items-center justify-center">
        <div className="absolute px-4 z-10 top-0 left-0  flex lg:flex-row flex-col items-center gap-4 right-0 bottom-0">
          <img className="lg:w-72 w-48" src={creditcard} />
          <div>
            <div className="text-primary text-center lg:text-left mb-8 text-xl uppercase mt-30"><FormattedMessage id="underage__validate_majority" /></div>
            <div className='flex flex-wrap text-[#8D908A] gap-2'>
              <div className="flex gap-2 items-center px-2 py-1 rounded-full bg-[#E9E9D3]"><img className=" h-8 w-8" src={check} alt="check" /><FormattedMessage id="underage__validate_tag_1" /></div>
              <div className="flex gap-2 items-center px-2 py-1 rounded-full bg-[#E9E9D3]"><img className=" h-8 w-8" src={check} alt="check" /><FormattedMessage id="underage__validate_tag_2" /></div>
              <div className="flex gap-2 items-center px-2 py-1 rounded-full bg-[#E9E9D3]"><img className=" h-8 w-8" src={check} alt="check" /><FormattedMessage id="underage__validate_tag_3" /></div>
            </div>
          </div>
        </div>
        <img className="w-full h-80 lg:h-auto" src={detect.isMobile ? background2 : background} />

      </div>
      <CreditCard cb={cb} button="underage__confirm_card"/>
    </div>
  </>
}