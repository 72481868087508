import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '../../Component/Input/Button'
import { closeCurrentPopin, displayPopin } from '../../Hook/popins'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faClose, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import bg from "./assets/bg.png";
import bgMobile from "./assets/bg-mobile.png";
import sad from "./assets/sad.png";
import sub from "./assets/sub.png";
import coins from "./assets/coins.png";
import useIsMobile from '../../Hook/mobile'

function PaymentRequired() {
  const detect = useIsMobile();
  return (
    <>
      <div onClick={closeCurrentPopin} className="top-0 left-0 right-0 bottom-0 fixed bg-black opacity-60 z-z-4010"></div>
      <div
        className={
          "bg-popinbg scroll--numbers fixed lg:max-w-4xl left-1/2 top-1/2 z-50  flex max-h-[100dvh] w-screen -translate-x-1/2 -translate-y-1/2 transform flex-col overflow-y-auto lg:rounded-xl"
        }
      >
        <div className="flex items-center justify-between pt-4 px-4 lg:px-8 lg:pt-2">
          <div></div>
          <h2 className="flex-1 text-lg font-bold">
            <FormattedMessage id="paymentrequired__title" />
          </h2>
          <FontAwesomeIcon onClick={closeCurrentPopin} icon={faClose} />
        </div>
        <div className='p-4'>
          <div className='flex gap-4 flex-col lg:flex-row'>
            <div className='relative flex-shrink-0'>
              <img className='w-full lg:w-auto' src={detect.isMobile ? bgMobile : bg} alt="bg" />
              <div className='absolute top-0 left-0 bottom-0 right-0 px-4 flex flex-col gap-4 items-center lg:justify-center'>
                <div><img className='h-32 lg:h-auto' src={sad} alt="sad" /></div>
                <div className='text-[#D300EC] text-2xl'><FormattedMessage id="paymentrequired__cost_money" /></div>
              </div>
            </div>
            <div className='flex flex-col gap-2'>

              <p className="whitespace-pre-wrap"><FormattedMessage id="paymentrequired__new_desc" /></p>
              <button onClick={() => {
                closeCurrentPopin()
                displayPopin()("tokens")
              }}  type='button' className='text-primary px-4 h-40 w-full flex items-center py-8 bg-cards rounded-xl'>
                <div className='w-1/4 h-full'>
                  <img className='h-20' src={coins} />
                </div>
                <div className='flex-1 text-left'><FormattedMessage id="paymentrequired__tokens" /></div>
                <div><FontAwesomeIcon icon={faChevronRight} /></div>
              </button>
              <button onClick={() => {
                closeCurrentPopin()
                displayPopin()("subscribe")
              }} type='button' className='text-primary px-4 h-40 w-full flex items-center py-8 bg-cards rounded-xl'>
                <div className='w-1/4 h-full'>
                  <img className='h-full' src={sub} />
                </div>
                <div className='flex-1 text-left'><FormattedMessage id="paymentrequired__subscription" /></div>
                <div><FontAwesomeIcon icon={faChevronRight} /></div>
              </button>
              <div className='flex gap-4'>
                <FontAwesomeIcon className='relative top-1' icon={faInfoCircle} />
                <p><FormattedMessage id="paymentrequired__postscriptum" /></p>
              </div>
            </div>
          </div>
        </div>

        {/* <h2 className='font-lg text-bold text-center'><FormattedMessage id="paymentrequired__title" /></h2>
        <p  className="whitespace-pre-wrap"><FormattedMessage id="paymentrequired__desc" /></p>
        <div className='flex gap-2'>
          <Button name="paymentrequired__tokens" color='primary' />
          <Button name="paymentrequired__subscription" color='primary' onClick={() => {
            closeCurrentPopin()
            displayPopin()("subscribe")
          }} />
        </div>
        <p><FormattedMessage id="paymentrequired__postscriptum" /></p> */}
      </div>
    </>
  )
}

export default PaymentRequired