import { ephemesFeed, ephemesInformations, ephemesInformations_ephemes } from "../types";

const ephemesCacheInformations:{[key:string]: ephemesInformations_ephemes} = {}

export const ephemeInformationsFilterFetchingList = (ephemes:ephemesFeed) => {
  const alreadyInMemory:{[key:string]: ephemesInformations_ephemes} = {}
  const notInMemory:string[] = [];
  for(let epheme of ephemes.ephemes){
    if(ephemesCacheInformations[epheme]){
      alreadyInMemory[epheme] = ephemesCacheInformations[epheme]
    }else{
      notInMemory.push(epheme);
    }
  }

  return {alreadyInMemory, notInMemory};
}

export const cacheEphemes = (ephemes: ephemesInformations) => {
  for(let epheme of ephemes.ephemes){
    ephemesCacheInformations[epheme.id] = epheme;
  }
}