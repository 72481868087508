import { faClose, faSliders } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Button from "../Input/Button";
import FilterOptions from "./FilterOptions";
import GenderFilter from "./GenderFilter";
import ThermometerFilter from "./ThermometerFilter";
import useFilters from "../../Hook/filters";

export default function FiltersMobile({nbFilters}:{nbFilters:number}) {
  const [open, setOpen] = useState(false);


  return (
    <>
      <div className='max-w-4xl m-auto my-0 flex flex-col gap-4 w-fit px-4'>
        <div className='flex bg-cards rounded-xl w-full justify-center'>
          <GenderFilter />
          <Button className={"!p-3 border-l relative border-background text-tabs flex !items-center !justify-center !m-0"} appearance='full' onClick={() => { setOpen(!open) }} color="cards">
            <FontAwesomeIcon icon={faSliders} />
            {nbFilters > 0 && <div className="absolute -top-1.5 -right-1.5 bg-primary rounded-full w-5 h-5 text-xs text-white flex justify-center items-center">{nbFilters}</div>}
          </Button>
        </div>
        {open && <div className="fixed z-20 bg-black opacity-70 top-0 left-0 right-0 bottom-0" onClick={() => setOpen(!open)}></div>}
        <div className={open ?
          'fixed z-[200] overflow-y-scroll flex-1 w-screen h-[100vh] pt-[25vh] bottom-0 left-0 flex gap-16 gap-y-4 flex-col pb-8 rounded-t-[40px]'
          : 'hidden'}>
          <div className="h-3/5 relative bg-popinbg py-10">
            <div onClick={() => { setOpen(!open) }} className="absolute top-2 right-4 text-messageplaceholder"><FontAwesomeIcon icon={faClose} /></div>
            <div className=" px-4 bg-popinbg">
              <div className="absolute top-4 left-1/2 -translate-x-1/2 w-32 h-1 rounded-full bg-unactive"></div>
              <div className="flex flex-col min-h-[min-content] gap-8 bg-popinbg pb-10">
                <ThermometerFilter close={() =>setOpen(false)}/>
                <FilterOptions property='age_ranges' title="age_range" />
                <FilterOptions property='website_uses' />
                <FilterOptions property='has_fanclub' />
                <FilterOptions property='languages' title="spoken_language" />
                <Button fillSpace name="Valider" onClick={() => { setOpen(!open) }} color="primary" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )

}