import { getCountryInformations } from "./location"
import { gifts, trophies, websiteParameters } from "./types"
import { api_getValueOrErrorMessage, api_postValueOrErrorMessage } from "./util"

export const api_getPlatformInformations = async () => {
    return await api_getValueOrErrorMessage<websiteParameters>("website/parameters")
}

export const getPlatformTrophies = async () => {
    return await api_getValueOrErrorMessage<trophies>("trophies");
}

export const getPlatformGifts = async () => {
    return await api_getValueOrErrorMessage<gifts>("gifts");
}

export const getPlatformCountriesPerContinent = async () => {
    if(localStorage.getItem("locationContinent")) return JSON.parse(localStorage.getItem("locationContinent") as string);
    let returnValues:any = {};
    for(let continent of ["africa", "america", "asia", "europe", "oceania", "antarctic"])
    {
        let countries = await api_postValueOrErrorMessage<{countries: number[]}>("location/countries", {continent});
        let infos = await getCountryInformations(countries.countries);
        returnValues[continent] = infos;
    }
    localStorage.setItem("locationContinent", JSON.stringify(returnValues));
    return returnValues;
}