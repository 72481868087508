import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import ChatDesktop from "../Component/ChatDesktop";
import ToastHolder from "../Component/Toast/ToastHolder";
import useIsMobile from "../Hook/mobile";
import Blocked from "../Page/Blocked";
import ManageMySub from "../Page/ManageMySub";
import MyEpheme from "../Page/MyEpheme";
import Search from "../Page/Search";
import PopinsHolder from "../Popins";
import Loader from "../Component/Loader";
import { displayPopin } from "../Hook/popins";
import useUser from "../Hook/user";
import ForgotPassword from "../Page/ForgotPassword";
const Home = React.lazy(() => import("../Page/Home"));
const EphemeUploader = React.lazy(() => import("../Page/EphemeUploader"));
const Profile = React.lazy(() => import("../Page/Profile"));
const UserProfile = React.lazy(() => import("../Page/Profile/user"));
const Members = React.lazy(() => import("../Page/Members"));
const Chat = React.lazy(() => import("../Page/Chat"));
const Tokens = React.lazy(() => import("../Page/Tokens"));
const Chats = React.lazy(() => import("../Page/Chats"));
const Top = React.lazy(() => import("../Page/Top"));
const Challenge = React.lazy(() => import("../Page/Challenge"));
const Webcam = React.lazy(() => import("../Page/Webcam"));
const SingleChallenge = React.lazy(() => import("../Page/Challenge/SingleChallenge"));
const StaticPage = React.lazy(() => import("../Page/StaticPage"));
const ParametersPage = React.lazy(() => import("../Page/Parameters"));
const SuppressionPage = React.lazy(() => import("../Page/Suppression"));
const ResetPage = React.lazy(() => import("../Page/ResetPassword"));
const ContactPage = React.lazy(() => import("../Page/Contact"));

function Router() {
  const isMobile = useIsMobile();
  const user = useUser();

  useEffect(() => {
    if (user?.informations?.type === "guest") {
      const search = window.location.search.substring(1).split('&');
      for (let param of search) {
        let [key, value] = param.split("=");
        if (key === "force_login_user") displayPopin()("forcelogin", { userID: value });
      };
    }
  }, []);

  return (
    <Suspense fallback={<div className="bg-background flex justify-center items-center h-screen w-screen"><Loader /></div>}>
      <BrowserRouter>
        <Routes>
          <Route path="/chat" element={!isMobile.isMobile ? <Home /> : <Chats />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/chat/:id" element={<Chat />} />
          <Route path="/member/:id" element={<Profile />} />
          <Route path="/profil/:id" element={<Profile />} />
          <Route path="/members" element={<Members />} />
          <Route path="/top" element={<Top />} />
          <Route path="/challenge" element={<Challenge />} />
          <Route path="/challenge/:id" element={<SingleChallenge />} />
          <Route path="/search" element={<Search />} />
          <Route path="/visionnage/:id" element={<Home />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetPassword/:id" element={<ResetPage />} />
          <Route path="/reinitialisationmotdepasse/:id" element={<ResetPage />} />
          <Route path="/:id" element={<StaticPage />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />

          {user?.informations?.type !== "guest" && <>
            <Route path="/webcams" element={<Webcam />} />
            <Route path="/me" element={<UserProfile />} />
            <Route path="/uploader" element={<EphemeUploader />} />
            <Route path="/uploader/:id" element={<EphemeUploader />} />
            <Route path="/parameters" element={<ParametersPage />} />
            <Route path="/me/parameters" element={<ParametersPage />} />
            <Route path="/suppression" element={<SuppressionPage />} />
            <Route path="/myepheme/:id" element={<MyEpheme />} />
            <Route path="/desabonnement" element={<ManageMySub />} />
            <Route path="/blocked" element={<Blocked />} />
            <Route path="/tokens" element={<Tokens />} />
          </>}
          
        </Routes>
        <PopinsHolder />
        <ToastHolder />
        {!isMobile.isMobile && <ChatDesktop />}
        <GlobalNavigate />
      </BrowserRouter>
    </Suspense>
  );
}

export const NAVIGATE: any = {
  navigate: undefined
};

function GlobalNavigate() {
  const navigate = useNavigate();
  NAVIGATE.navigate = navigate;
  return null;
}

export default Router;
