import React, { Component, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
    tabs: any[],
    sideIcons?: string[],
    sideIconClick?: (arg0: string) => any,
    defaultIcon?: string,
    onSelect?: (arg0: number) => void
    defaultIndex?: number,
    noOX?: boolean,
    yesOF?: boolean,
    all?: boolean,
    children?: any
}

export default class Tabs extends Component<Props> {


    constructor(props: Props) {
        super(props)
        this.state = {
            i: props.defaultIndex ?? 0,
            iconI: 0
        }
        if (props.sideIcons && props.defaultIcon) (this.state as any).iconI = props.sideIcons.findIndex(e => e === props.defaultIcon);
        props.tabs.forEach((tab, i) => (this.state as any)[`rendered${i}`] = false);
        (this.state as any)[`rendered${(this.state as any)["i"]}`] = true;
    }

    static getDerivedStateFromProps(props: Props, state: any) {
        if (props.defaultIndex) {
            if (props.defaultIndex != state.i && state.defaultIndex !== props.defaultIndex) {
                state[`rendered${props.defaultIndex}`] = true;
                state.i = props.defaultIndex;
                state.defaultIndex = props.defaultIndex;
                return state;
            }
        }
        return state;
    }

    render() {
        const state: any = this.state as any;
        const propsChildren = Array.isArray(this.props.children) ? this.props.children : [this.props.children];
        const children = (propsChildren as ReactNode[]).filter(e => typeof e != "boolean" && e !== null && e !== undefined);
        const tabs = (this.props.tabs as ReactNode[]).filter(e => typeof e != "boolean" && e != null);
        const { sideIcons, sideIconClick, onSelect, noOX, yesOF, all } = this.props;
        if (tabs.length !== children.length) return null;
        return (
            <div className='flex flex-col justify-center w-screen lg:w-full group is-tabs'>
                <div className='w-full'>
                    <div className='flex gap-4 overflow-y-clip bg-cards p-4 rounded-t-md border-b border-b-unactive overflow-x-auto w-full'>
                        {tabs.map((tab, i) =>
                            <div key={i}> 
                            <button onClick={() => {
                                let state: any = { i };
                                state[`rendered${i}`] = true;
                                onSelect && onSelect(i);
                                this.setState(state);
                            }} className={"relative whitespace-nowrap text-tabs flex flex-1 justify-center " + ((all || i === state.i) ? "!text-primary" : "")}>  {React.isValidElement(tab) ? tab : <FormattedMessage id={`tabs__${tab}`} />}
                            {(all || i === state.i) && <div className='absolute h-1 w-full bg-primary -bottom-[17px]'></div>}
                            </button>
                            </div>
                        )}
                    </div>
                    {sideIcons && <div className="display-mode">
                        {sideIcons.map((icon, i) =>
                            <button onClick={() => this.setState({ iconI: i }, () => sideIconClick && sideIconClick(icon))} className={`btn-slide ${state.iconI === i ? "active" : ""}`}>
                                <img src={icon} alt="side" />
                            </button>
                        )}
                    </div>}
                </div>
                {React.Children.map(children, (child, i) =>
                    <div key={i} className={"bg-cards p-4 w-full rounded-b-md lg:rounded-tr-md " + (i === state.i ? "show" : "hidden")}>
                        {state[`rendered${i}`] && child}
                    </div>
                )}
            </div>
        )
    }
}
