import React, { useEffect } from 'react'
import useIsMobile from '../Hook/mobile';

function Content({ children }: any) {
  return (
    <div className="lg:flex flex-1 overflow-x-hidden">
      {children}
    </div>
  )
}

export default Content