import { faArrowLeft, faCheckCircle, faCircleXmark, faEdit, faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { api_postValueOrErrorMessage } from '../../Api/util'
import Avatar from '../../Component/Avatar'
import FilterOptions, { FilterPicker } from '../../Component/Filters/FilterOptions'
import GenderFilter from '../../Component/Filters/GenderFilter'
import Button from '../../Component/Input/Button'
import TextInput from '../../Component/Input/TextInput'
import Tabs from '../../Component/Tabs/Tabs'
import { closeCurrentPopin } from '../../Hook/popins'
import useUser from '../../Hook/user'
import LocationPicker, { LocationPickerCity } from './LocationPicker'
import InformationEdit from './InformationEdit'

function EditProfile() {
  const intl = useIntl();
  const user = useUser();
  const [currentGenders, setCurrentGenders] = useState<string[]>(user?.informations?.genders_sought ?? []);
  const [age, setAge] = useState(user?.informations?.age_range);
  const [relations, setRelations] = useState(user?.informations?.relationships_sought ?? []);
  const [uses, setUses] = useState(user?.informations?.website_uses ?? []);
  const [city, setCity] = useState(user?.informations?.cityInfos);
  const [country, setCountry] = useState(user?.informations?.countryInfos);
  const [description, setDescription] = useState<string>('');
  const [errorPrefs, setErrorPrefs] = useState<string | null>(null);




  const updateFilter = (updater: any, currentValue: any) => (value: any) => {
    if (!currentValue.includes(value)) currentValue.push(value);
    else currentValue = currentValue.filter((e: any) => value !== e);
    currentValue.sort();
    updater([...currentValue]);
  }

  const updatePrefs = () => {
    let data = {
      country_id: country?.id,
      city_id: city?.id,
      description,
      relationships_sought: relations,
      website_uses: uses,
      age_range: age,
      genders_sought: currentGenders
    }
    if (!data.country_id) delete data.country_id;
    if (!data.city_id) delete data.city_id;
    api_postValueOrErrorMessage('user/informations/modify', data).then((value) => {
      if (value.error_message) return setErrorPrefs("popin__edit_error_" + value.error_message);
      user?.fetch();
      closeCurrentPopin();
    });
  }

  return (
    <>
      <div onClick={closeCurrentPopin} className="top-0 left-0 right-0 bottom-0 fixed bg-black opacity-60 z-z-4010"></div>
      <div className="z-50 lg:max-w-2xl w-screen  bg-cards fixed h-screen lg:h-fit lg:rounded-xl max-h-[100dvh] max-w-[100vw] scroll--numbers overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className='flex gap-4 border-b border-b-unactive p-4'>
          <FontAwesomeIcon icon={faArrowLeft} className='text-label h-7' onClick={closeCurrentPopin} />
          <div className='text-xl'><FormattedMessage id="menu__edit_my_profile" /></div>
        </div>
        <div className='w-full [&>.is-tabs]:!w-full'>
          <Tabs tabs={[<FormattedMessage id="tabs__infos" />, <FormattedMessage id="tabs__prefs" />]}>
            <InformationEdit />

            <div className='flex flex-col gap-4'>
              <div>
                <div className='text-sm text-label'><FormattedMessage id="information__looking_for" /></div>
                <GenderFilter fill localUpdater={updateFilter(setCurrentGenders, currentGenders)} currentValues={currentGenders} />
              </div>

              <div>
                <FilterPicker
                  className='[&>*]:!bg-input'
                  fill updater={setAge} value={age} property='age_range' />
              </div>

              <div>
                <div className='flex flex-col gap-2'>
                  <LocationPicker updater={setCountry} value={country?.name} />
                  <LocationPickerCity updater={setCity} value={city?.name} country_id={country?.id} />
                </div>
              </div>

              <div>
                <TextInput type="textarea" defaultValue={user?.informations?.description} setValue={setDescription} label={intl.formatMessage({ id: "information__description" })} />
              </div>

              <div>
                <FilterOptions
                  className='[&>*]:!bg-input'
                  fill none updater={updateFilter(setRelations, relations)} value={relations} property='relationship_sought' />
              </div>

              <div>
                <FilterOptions
                  className='[&>*]:!bg-input'
                  fill none updater={updateFilter(setUses, uses)} value={uses} property='website_uses' />
              </div>
              {errorPrefs && <div className='text-red-600 text-center'><FormattedMessage id={errorPrefs} /></div>}

              <Button name="popin__edit_profil_validate_button" onClick={updatePrefs} color="primary" fillSpace />

            </div>
          </Tabs>
        </div>
      </div>
    </>
  )
}

export default EditProfile;


