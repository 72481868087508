import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import useUser from '../../Hook/user'
import UserMenu from '../../Page/UserMenu'
import useNotification from '../../Hook/notification'

function MobileSideMenu() {
  const [open, setOpen] = useState(false)
  const notification = useNotification();
  const user = useUser();
  const nbNotifs = notification.profile_visit + notification.follow + notification.epheme_like;
  return (
    <>
      <div className={"fixed left-0 z-[130] w-[80vw] top-0 h-screen overflow-y-auto -translate-x-full bg-background border-r border-border transition-all" +
        (open ? ' !translate-x-0 flex flex-col gap-4 p-4' : '')
      }>
        <UserMenu setOpen={setOpen} open={open} />
      </div>
      {open && <div onClick={() => setOpen(false)} className='fixed left-0 top-0 right-0 bottom-0 z-40 bg-black opacity-50'></div>}
      <div className='absolute top-5 left-7 lg:hidden' onClick={() => setOpen(true)}>
        <FontAwesomeIcon className='h-6' icon={faBars} />
        {nbNotifs > 0 && <div className='absolute bg-green text-white h-4 w-4 rounded-full flex items-center justify-center -top-0.5 -right-2 text-xs'>{nbNotifs > 9 ? "9+" : notification.profile_visit}</div>}
      </div>
    </>
  )
}

export default MobileSideMenu