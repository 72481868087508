import { membersInformations_members } from '../../Api/types';
import useUser from '../../Hook/user';
import certified from "./certified.svg";

function Pseudonym({ member, online, webcam, cards }: { cards?: boolean, webcam?: boolean, member: membersInformations_members & any, online?: boolean }) {
  const user = useUser();
  if (!member) return <div></div>;
  if(member.id === user?.informations?.id) member.is_online = true;
  if (cards) return (
    <div className={'flex gap-2 items-center w-full ' + (webcam ? "w-fit mx-auto" : "")}>
      {online && <div className={member.is_online ? 'w-2 h-2 bg-green rounded-full' : 'w-2 h-2 bg-red-600 rounded-full'}></div>}
      <div className={"flex gap-2 w-full flex-1 items-center " + `text-${member.gender}`}><div className=' max-w-[80%] overflow-hidden truncate'>{member.pseudonym ?? "Guest"}</div> {member.is_certified && <img className='h-[1.2rem]' src={certified} alt="certified" />}</div>

    </div>
  );
  return (
    <div className={'flex gap-2 items-center ' + (webcam ? "w-fit mx-auto" : "")}>
      {online && <div className={member.is_online ? 'w-2 h-2 bg-green rounded-full' : 'w-2 h-2 bg-red-600 rounded-full'}></div>}
      <div className={"flex gap-2 w-fit flex-1 items-center truncate " + `text-${member.gender}`}>{member.pseudonym ?? "Guest"} {member.is_certified && <img className='h-[1.2rem]' src={certified} alt="certified"/>}</div>

    </div>
  )
}

export default Pseudonym