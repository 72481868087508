import { useEffect, useState } from "react";
import BaseHookWithUpdaters from "./baseHookWithUpdaters";

class Mobile extends BaseHookWithUpdaters {
    isMobile = window.innerWidth < 960;

    constructor(){
        super();
        window.addEventListener("resize", () => {
            let prev = this.isMobile;
            this.isMobile = window.innerWidth < 960;
            if(prev !== this.isMobile) this.update(); 
        })
    }
}

export const MOBILE = new Mobile();

function useIsMobile() {
    const [isMobile, setIsMobile] = useState<Mobile>(MOBILE);

    useEffect(() => {
        MOBILE.register(setIsMobile);
        return () => {
            MOBILE.unregister(setIsMobile);
        }
    }, [])


    return isMobile;
}

export default useIsMobile;