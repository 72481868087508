import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { FormattedMessage } from 'react-intl'

function Button({name, icon, children, color, onClick, appearance, fillSpace, className}:{className?: any, name?: string, children?:any, color:string, onClick: (event?:any) => void, appearance?: "outline"|"full", fillSpace?: boolean, icon?:any}) {
  if(appearance === "outline")
  return <div onClick={onClick} className={
    `cursor-pointer w-fit px-4 py-1 border-${color} border-2 rounded-full text-center m-auto flex justify-center items-center` +
    (fillSpace ? " !w-full h-[44px] my-0 " : " ") + className
  }>
    {name && <FormattedMessage id={name} />}
    {children}
    {icon && <FontAwesomeIcon className='ml-2' icon={icon} />}
  </div>
  
  return (
    <div onClick={onClick} className={
      `cursor-pointer px-4 w-fit py-1 bg-${color} rounded-full text-center mx-auto flex justify-center items-center` +
      (fillSpace ? " !w-full h-[44px] my-0 " : " ") + className
  }>
    {name && <FormattedMessage id={name} />}
    {children}
    {icon && <FontAwesomeIcon className='ml-2' icon={icon} />}
    </div>
  )
}

export default Button