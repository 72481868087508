import { FormattedMessage } from 'react-intl';
import useFilters from '../../Hook/filters';
import { displayPopin } from '../../Hook/popins';
import useUser from '../../Hook/user';
import hard from "./svg/tempExplicite.svg";
import hot from "./svg/tempSexy.svg";
import soft from "./svg/tempSoft.svg";

function ThermometerFilter({ underage, close }: { underage?: boolean, close: any }) {
  const filters = useFilters();
  const user = useUser();
  if (underage) return <DemoThermometer />
  let filterExist = filters.filters["thermometers"];
  let softSelected = filterExist && filterExist.includes("soft");
  let hotSelected = filterExist && filterExist.includes("hot");
  let hardSelected = filterExist && filterExist.includes("hard");
  return (
    <div className=' w-full lg:w-fit'>
      <div className='text-label mb-2 text-[0.85rem]'><FormattedMessage id={"uploader__confirmation_title"} /></div>
      <div className="flex w-full lg:w-fit py-0.5 px-2 lg:h-auto lg:bg-cards lg:py-4 h-11 items-center gap-2 rounded-xl">
        <div onClick={() => {
          filters.updateFilter("thermometers", "soft")
        }} className={"flex-1 h-full px-4 lg:py-2 items-center cursor-pointer flex gap-2 border rounded-full bg-chatbg justify-center" + (softSelected ? " border-soft text-soft" : "")}>
          <div><FormattedMessage id="filter__thermometer_soft" /></div>
        </div>
        <div onClick={() => {
          if (user?.informations?.type === "guest") {
            close();
            return displayPopin()("login", { errorMessage: "terminated_guest_freetour" })
          }
          filters.updateFilter("thermometers", "hot")
        }} className={"flex-1 h-full px-4 lg:py-2 items-center cursor-pointer flex gap-2 border rounded-full bg-chatbg justify-center" + (hotSelected ? " border-hot text-hot" : "")}>
          <div><FormattedMessage id="filter__thermometer_hot" /></div>
        </div>
        <div onClick={() => {
          if (user?.informations?.type === "guest") {
            close();
            return displayPopin()("login", { errorMessage: "terminated_guest_freetour" })
          } filters.updateFilter("thermometers", "hard")
        }} className={"flex-1 h-full px-4 lg:py-2 items-center cursor-pointer flex gap-2 border rounded-full bg-chatbg justify-center" + (hardSelected ? " border-hard text-hard" : "")}>
          <div><FormattedMessage id="filter__thermometer_hard" /></div>
        </div>
      </div>
    </div>
  )
}

function DemoThermometer() {
  return (
    <div className=' w-full lg:w-fit'>
      <div className='text-center mb-2 text-[0.85rem]'><FormattedMessage id={"uploader__confirmation_title"} /></div>
      <div className="flex w-full lg:w-fit py-0.5 px-2 h-14 border-2 border-border items-center gap-2 rounded-xl">
        <div className={"flex-1 h-full px-4 items-center rounded-xl cursor-pointer flex gap-2  justify-center bg-primary"}>
          <img className='h-5' src={soft} alt="soft" />
          <div><FormattedMessage id="filter__thermometer_soft" /></div>
        </div>
        <div className={"flex-1 relative h-full px-4 items-center rounded-xl cursor-pointer flex gap-2  justify-center bg-primary"}>
          <img className='h-5' src={hot} alt="hot" />
          <div><FormattedMessage id="filter__thermometer_hot" /></div>
          <div className='absolute -rotate-[24deg] top-1 right-1 text-[10px] text-primary font-bold flex items-center justify-center h-4 w-4 bg-white rounded-full'>-18</div>
        </div>
        <div className={"flex-1 relative h-full px-4 items-center rounded-xl cursor-pointer flex gap-2 justify-center bg-primary"}>
          <img className='h-5' src={hard} alt="hard" />
          <div><FormattedMessage id="filter__thermometer_hard" /></div>
          <div className='absolute -rotate-[24deg] top-1 right-1 text-[10px] text-primary font-bold flex items-center justify-center h-4 w-4 bg-white rounded-full'>-18</div>

        </div>
      </div>
    </div>
  )
}

export default ThermometerFilter