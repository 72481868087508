import { faCoins } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { userSubscriptionOffers_offers } from '../../Api/types'
import CardForm from '../../Component/CardForm'
import CardList from '../../Component/CardList'
import Button from '../../Component/Input/Button'
import { FormatPrice, handleSecurionPaysubTransaction } from '../../Component/utils'
import useUser, { USER } from '../../Hook/user'
import { closeCurrentPopin, displayPopin } from '../../Hook/popins'
import { api_logErrorPayment } from '../../Api/log'
import { PLATFORM } from '../../Hook/platform'
import Loader from '../../Component/Loader'

function CardsPicker({ subOffer, setStep }: { subOffer: userSubscriptionOffers_offers, setStep: any }) {
    const [index, setIndex] = useState(0);
    const user = useUser();
    const [pick, setPickMenu] = useState(false);
    const [newCard, setNewCard] = useState(user?.cards.length === 0);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const paymentProcessCallBack = (obj: any) => {
        if (obj.status === "error") {
            api_logErrorPayment({obj, securionLoaded: !!(window as any).SecurionPay, errorFrom:"API", spKey: PLATFORM.platform?.securionpay_public_key , USER: USER.informations?.id})
            setLoading(false);
            return setError("securion__" + obj.error_message);
        }
        //error securionpay
        if (obj.error){ 
            api_logErrorPayment({obj, securionLoaded: !!(window as any).SecurionPay, errorFrom:"SecurionPay", spKey: PLATFORM.platform?.securionpay_public_key , USER: USER.informations?.id})
            setLoading(false);
            return setError("securion__" + obj.error.code);
        }
        USER.fetch();
        closeCurrentPopin();
        displayPopin()("paymentsuccessful", {amount: subOffer.price, currency: subOffer.currency});
    }

    const goBack = () => {
        if (newCard) {
            if (user?.cards.length !== 0) return setNewCard(false)
        } else if (pick) {
            return setPickMenu(false)
        }
        return setStep(0)
    }
    return (
        <>
            <div className='px-4 flex flex-col gap-4'>
                <div className='flex p-4 rounded-xl bg-background gap-2 items-center'>{subOffer.days < 30 ? subOffer.days : Math.round(subOffer.days / 30)} {subOffer.days < 30 ?
                    <FormattedMessage id="popin__subscription_duration_day_singular" /> :
                    <FormattedMessage id={"popin__subscription_duration_month_" + (Math.round(subOffer.days / 30) === 1 ? "singular" : "plural")} />
                } + {subOffer.tokens} <FontAwesomeIcon icon={faCoins} />
                <div className='flex-1 text-end justify-end flex'><FormatPrice currency={subOffer.currency} price={subOffer.price} /></div>
                </div>
                {loading && <div className='inset-0 absolute bg-black opacity-60 flex items-center justify-center'><Loader /></div>}

                {!newCard && <CardList index={index} setIndex={setIndex} pick={pick} setPickMenu={setPickMenu} />}
                {!pick && <CardForm setNewCard={setNewCard} newCard={newCard} />}
                {error && <div className=' text-red-600'><FormattedMessage id={error} /></div>}
                <div className='text-xs text-center'><FormattedMessage values={{amount: subOffer.price, currency: subOffer.currency, days: subOffer.days}} id="popin__upgrade_account_billing_infos" /></div>
                <div className='flex gap-2 '>
                    <Button fillSpace name="confirmmodal__cancel" color="primary" appearance='outline' onClick={goBack} />
                    {!pick && <Button fillSpace name="payment__buy_now" color="primary" onClick={() => { if(loading)return; setLoading(true);  handleSecurionPaysubTransaction(subOffer, index, paymentProcessCallBack, newCard) }} />}
                </div>
                
                <div className='text-xs text-center'><FormattedMessage id="popin__upgrade_account_bank_note" /></div>
                <div className='text-xs text-center'><FormattedMessage id="popin__upgrade_account_service_provider" /></div>
            
            </div>
        </>
    )
}

export default CardsPicker