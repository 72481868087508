import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl';
import { locationCitiesSuggest, locationCitiesSuggest_countries, locationCountriesSuggest } from '../../Api/types';
import { api_postValueOrErrorMessage } from '../../Api/util';
import TextInput from '../../Component/Input/TextInput';

function LocationPicker({ value, updater }: any) {
  const intl = useIntl();
  const [suggested, setS] = useState<locationCitiesSuggest_countries[]>([]);
  const suggest = async (v: string) => {
    setS((await api_postValueOrErrorMessage<locationCountriesSuggest>("location/countries/suggest", { name: v })).countries ?? []);
  }

  const onChange = (value:string) => value.length > 2 && suggest(value)

  return (
    <div>
      <div className='w-full'>
        <TextInput id="country-input" defaultValue={value} label={intl.formatMessage({ id: "topandprogress__select_country" })} setValue={onChange} />
      </div>

      {suggested.length > 0 && <div className='w-full p-2 rounded-b-md border border-border flex flex-col gap-2'>
        {suggested.map(e => <div onClick={() => {
          updater(e);
          let input = (document.getElementById('country-input') as HTMLInputElement);
          if(input) input.value = e.name;
          setS([]);
        }} className='cursor-pointer w-full'>{e.name}</div>)}
      </div>}
    </div>
  )
}

export function LocationPickerCity({ value, country_id, updater }: any) {
  const intl = useIntl();
  const [suggested, setS] = useState<locationCitiesSuggest_countries[]>([]);
  const suggest = async (v: string) => {
    setS((await api_postValueOrErrorMessage<locationCitiesSuggest>("location/cities/suggest", { name: v, country_id })).cities ?? []);
  }

  
  const onChange = (value:string) => value.length > 2 && suggest(value)

  return (
    <div>
      <div>
        <TextInput id="city-input" defaultValue={value} label={intl.formatMessage({ id: "profile__location" })} setValue={onChange}/>
      </div>

      {suggested.length > 0 && <div className='w-full p-2 rounded-b-md border border-border flex flex-col gap-2'>
        {suggested.map(e => <div onClick={() => {
          updater(e);
          let input = (document.getElementById('city-input') as HTMLInputElement);
          if(input) input.value = e.name
          setS([]);
        }} className='cursor-pointer w-full'>{e.name}</div>)}
      </div>}
    </div>
  )
}

export default LocationPicker