import { faSliders } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import useFilters from '../../Hook/filters';
import useIsMobile from '../../Hook/mobile';
import Button from '../Input/Button';
import FilterOptions from './FilterOptions';
import FiltersMobile from './FiltersMobile';
import GenderFilter from './GenderFilter';
import ThermometerFilter from './ThermometerFilter';

function Filters({noOnlineFilter}: {noOnlineFilter?:boolean}) {
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();
  const filters = useFilters();

  let nbFilters = 0;
  if(filters.filters){
    for(let key in filters.filters){
      if(key === "is_online" && noOnlineFilter) continue;
      if(key === "genders" || key === "thermometers") continue;
      if(filters.filters[key].length === 0 || filters.filters[key] === "no")continue;
      nbFilters++;
    }
  }
  
  if (isMobile.isMobile) return <FiltersMobile nbFilters={nbFilters} />
  return (
    <>
      <div className='max-w-5xl w-full m-auto my-0 flex flex-col gap-4'>
        <div className='flex justify-between gap-8 w-full'>
          <FilterOptions property='age_ranges' title="age_range" />
          <ThermometerFilter close={() =>setOpen(false)}/>
          <GenderFilter />
          <div className='flex items-end'>
            <Button className={"!h-[4.7rem] !rounded-xl !w-16 border-l relative  border-background text-tabs flex !items-center !justify-center !m-0"} appearance='full' onClick={() => { setOpen(!open) }} color="cards">
              <FontAwesomeIcon icon={faSliders} />
              {nbFilters > 0 && <div className="absolute -top-2 -right-2 bg-primary rounded-full w-7 h-7 text-xs text-white flex justify-center items-center">{nbFilters}</div>}
            </Button>
          </div>
        </div>
        <div className={open ? 'flex justify-center gap-8 gap-y-4 flex-wrap' : 'hidden'}>
          <FilterOptions property='languages' title="spoken_language" />
          {!noOnlineFilter && <FilterOptions property='is_online' title='online' />}
          <FilterOptions property='website_uses' />
          <FilterOptions property='relationship_sought' />
          <FilterOptions property='has_fanclub' />
        </div>
        <div className={open ? 'm-auto cursor-pointer mt-4' : 'hidden'}>
          <Button onClick={() => setOpen(!open)} name={open ? "home__see_less" : "button_fetch_more_list"} color="primary" />
        </div>
      </div>
    </>
  )
}

export default Filters