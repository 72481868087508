import { faArrowLeft, faClose, faCoins, faFire, faRocket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getRelation } from '../../Api/member';
import { chatChannelsInformations_channels, membersInformations_members } from '../../Api/types';
import { api_postValueOrErrorMessage } from '../../Api/util';
import { PLATFORM } from '../../Hook/platform';
import { displayPopin } from '../../Hook/popins';
import useUser from '../../Hook/user';
import Avatar from '../Avatar';
import Button from '../Input/Button';

function GiftBar({ member, setSimulated, channel, close, displayError }: { displayError: any,  close: any, channel: chatChannelsInformations_channels, member: membersInformations_members | undefined, setSimulated: any }) {
    const user = useUser()!;
    const [commonBoost, setCommonBoost] = useState<any>({});

    useEffect(() => {
        if (!member) return;
        getRelation(member.id).then((e) => {
            setCommonBoost(e);
        })
    }, [member])

    return (
        <div className='absolute overflow-y-auto top-0 left-0 bottom-0 right-0 z-50 bg-background px-2 py-4 flex flex-col gap-4'>
            <div className='text-left px-4 cursor-pointer' onClick={close}><FontAwesomeIcon className='text-messageplaceholder text-2xl' icon={faArrowLeft} /></div>
            <div className='flex items-center justify-center'>
                <div className='w-12 h-12 rounded-full overflow-hidden relative left-1'><Avatar member={member} /></div>
                <div className='w-14 h-14 rounded-full overflow-hidden relative right-1 border-4 border-background'><Avatar member={user.informations} /></div>
            </div>
            <div className='py-4 text-center w-full [&>*]:text-primary text-xs flex gap-2 justify-center'>
                <FormattedMessage id="gifttray__header_common_boost" values={{ member: member?.pseudonym }} />
            </div>
            <div className='w-fit mx-auto'>
                <div className="flex gap-4 items-center bg-chatheader px-4 py-2 rounded-full"><FontAwesomeIcon className='text-man' icon={faRocket} />+{commonBoost.boost_common}%</div>
            </div>
            <div className=' grid grid-cols-3 gap-2'>
                {PLATFORM.gifts.map(e => <div  onClick={() => {
                        close();
                        api_postValueOrErrorMessage<any>("gifts/simulate", {
                            id: e.id,
                            member: member?.id
                        }).then(answer => {
                            if (answer.error_message === "not_enough_tokens") return displayPopin()("tokens");
                            else if(answer.error_message) return displayError(answer.error_message);
                            else setSimulated({ ...answer, gift: e, member })
                        })
                    }} className='flex flex-col gap-4 relative pt-10 items-center justify-center text-xs bg-cards rounded-xl p-4'>
                    <img className='w-[25vw] max-w-[60px]' src={e.thumbnail} />
                    <div className='flex items-center justify-center gap-1 absolute bg-chatbg px-2 rounded-full py-1 top-1 left-1'><FontAwesomeIcon className=' text-primary' icon={faFire} />+{e.flames_given} </div>
                    <Button name={(e.tokens_cost as any)?.toString()} icon={faCoins} appearance='full' onClick={() => {}} color="message" className={"bg-message text-messagetxt !py-1 !w-full"} />
                </div>
                )}
            </div>
        </div>
    )
}

export default GiftBar