import { membersInformations, membersInformations_members } from "../types";

let cachedMembers:{[key:string]:membersInformations_members} = {};

export const memberInformationsFilterFetchingList = (membersIDs: string[]) => {
  const notInMemory:string[] = [];
  const inMemory:{[key:string]:membersInformations_members} = {};

  for(let memberID of membersIDs)
  {
    if(cachedMembers[memberID])
    {
      inMemory[memberID] = cachedMembers[memberID];
    }
    else
    {
      notInMemory.push(memberID);
    }
  }
  
  return {notInMemory, inMemory};

}

export const cacheMembers = (members: membersInformations) => {
  for(let member of members.members){
    cachedMembers[member.id] = member;
  }
}

export const resetMemberCache = () => {
  cachedMembers = {};
}