import { FormattedMessage, FormattedRelativeTime } from 'react-intl';
import { useNavigate } from 'react-router';
import useUser from '../../../Hook/user';
import Avatar from '../../Avatar';
import Pseudonym from '../../Member/Pseudonym';
import { sockedTextMessage } from '../utils';

function TextMessage({ message, openDialog }: { message: sockedTextMessage, openDialog: any }) {
  const user = useUser();
  const navigate = useNavigate();
  let time = (message.time_sent - Date.now()) / 1000;
  if (time.toString() === "NaN") time = 0;
  let userMessage = user?.informations?.id === message.author;

  if (userMessage) return (
    <div className='flex gap-4 text-xs'>
      <div className='flex-1 flex flex-col items-end'>
        <div>
          <div className='text-white'><FormattedMessage id="chatmessage__is_mine" /></div>
        </div>
        <div className="text-messagetxt mb-4 relative whitespace-prewrap w-3/4 bg-[#D5D7D8] px-4 py-2 rounded-xl">
          <div className='text-black mb-2 whitespace-pre-wrap'>{message.text}</div>
          <div className='absolute -bottom-2 right-0 rounded-tl-md px-1 text-messagetxt bg-chatbg text-[0.5rem]'><FormattedRelativeTime value={time} numeric="auto" updateIntervalInSeconds={60} /></div>
        </div>
      </div>
      <div className='h-14 mt-2 w-14 rounded-full overflow-hidden'>
        <Avatar member={message.member} />
      </div>
    </div>
  )

  return (
    <div className='flex gap-4 text-xs'>
      <div className='h-14 mt-2 w-14 rounded-full overflow-hidden'>
        <Avatar member={message.member} />
      </div>
      <div className='flex-1'>
        <div className='text-messagetxt flex justify-between  w-3/4'>
          <div className='cursor-pointer' onClick={() => navigate("/member/"+message.member!.id)}><Pseudonym member={message.member} /></div>
          <div className='text-gray-500 cursor-pointer' onClick={() => openDialog(message.member)}>ooo</div>
        </div>
        <div className="text-messagetxt mb-4 relative whitespace-prewrap w-3/4 bg-message px-4 py-2 rounded-xl">
          <div className='text-messagetxt mb-2 whitespace-pre-wrap'>{message.text}</div>
          <div className='absolute -bottom-2 right-0 rounded-tl-md px-1 text-messagetxt bg-chatbg text-[0.5rem]'><FormattedRelativeTime value={time} numeric="auto" updateIntervalInSeconds={60} /></div>
        </div>
      </div>
    </div>
  )
}

export default TextMessage