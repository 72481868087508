import { useEffect, useState } from "react";
import { FormattedMessage, FormattedRelativeTime } from "react-intl";
import {
  chatContentsInformations,
  chatContentsInformations_contents,
} from "../../../Api/types";
import { api_postValueOrErrorMessage } from "../../../Api/util";
import { displayPopin } from "../../../Hook/popins";
import useUser from "../../../Hook/user";
import Avatar from "../../Avatar";
import Pseudonym from "../../Member/Pseudonym";
import { sockedContentMessage } from "../utils";

function ContentMessage({ message }: { message: sockedContentMessage }) {
  const user = useUser();
  const [content, setContent] =
    useState<chatContentsInformations_contents | null>(null);
  let time = (message.time_sent - Date.now()) / 1000;
  if (time.toString() === "NaN") time = 0;
  let userMessage = user?.informations?.id === message.author;
  useEffect(() => {
    (async () => {
      let c = (
        await api_postValueOrErrorMessage<chatContentsInformations>(
          "chat/contents/informations",
          { contents: [message.content] },
        )
      ).contents[0];
      setContent(c);
    })();
  }, []);

  if (!content) return null;

  if (userMessage)
    return (
      <div className="flex gap-4 text-xs">
        <div className="flex-1 flex flex-col items-end">
          <div>
            <div className="text-white">
              <FormattedMessage id="chatmessage__is_mine" />
            </div>
          </div>
          <div className="text-black mb-4 relative whitespace-prewrap w-3/4 bg-[#D5D7D8] px-4 py-2 rounded-xl">
            <div className="text-black mb-4 whitespace-pre-wrap">
              <img
                onClick={() => {
                  displayPopin()("chatcontent", { content });
                }}
                className="interact cursor-pointer"
                src={content.thumbnail}
              />
            </div>
            <div className="absolute -bottom-2 right-0 rounded-tl-md px-1 text-messagetxt bg-chatbg text-[0.5rem]">
              <FormattedRelativeTime
                value={time}
                numeric="auto"
                updateIntervalInSeconds={60}
              />
            </div>
          </div>
        </div>
        <div className="h-14 mt-2 w-14 rounded-full overflow-hidden">
          <Avatar member={message.member} />
        </div>
      </div>
    );

  return (
    <div className="flex gap-4 text-xs">
      <div className="h-14 mt-2 w-14 rounded-full overflow-hidden">
        <Avatar member={message.member} />
      </div>
      <div className="flex-1">
        <div>
          <div>
            <Pseudonym member={message.member} />
          </div>
        </div>
        <div className="text-black mb-4 relative whitespace-prewrap w-3/4 bg-blue-gray-100 px-4 py-2 rounded-xl">
          <div className="text-black mb-4 whitespace-pre-wrap">
            <img
              onClick={() => {
                displayPopin()("chatcontent", { content });
              }}
              className="interact cursor-pointer"
              src={content.thumbnail}
            />
          </div>
          <div className="absolute bottom-0 right-0 rounded-tl-md px-1 text-black bg-[#D5D7D8] text-xs">
            <FormattedRelativeTime
              value={time}
              numeric="auto"
              updateIntervalInSeconds={60}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentMessage;
