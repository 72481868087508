import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { api_postValueOrErrorMessage } from '../../Api/util';
import Button from '../../Component/Input/Button';
import TextInput from '../../Component/Input/TextInput';
import Select from '../../Component/Select';

function ReportModal({ setReportModal, id, next }: { setReportModal: any, id: string, next: any }) {
    const intl = useIntl();
    const [currentReport, setCurrentReport] = useState(intl.formatMessage({id:"reportpopin__body_reason1"}));
    const [otherReason, setOtherReason] = useState("")
    const other = currentReport === intl.formatMessage({id:"reportpopin__body_other_reason"});

    return (
        <div className='flex flex-col fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-4 rounded-xl bg-popinbg z-50 gap-4 lg:w-fit w-11/12'>
            <div className='text-center text-xl'><FormattedMessage id="reportpopin__title" /></div>
            <hr className='mb-2 opacity-20' />
            <div><FormattedMessage id="reportpopin__body_text" /></div>
            <Select
            options={[
                {
                    value: intl.formatMessage({id: "reportpopin__body_reason1"}),
                    message: intl.formatMessage({id: "reportpopin__body_reason1"})
                },
                {
                    value: intl.formatMessage({id: "reportpopin__body_reason2"}),
                    message: intl.formatMessage({id: "reportpopin__body_reason2"})
                },
                {
                    value: intl.formatMessage({id: "reportpopin__body_reason3"}),
                    message: intl.formatMessage({id: "reportpopin__body_reason3"})
                },
                {
                    value: intl.formatMessage({id: "reportpopin__body_other_reason"}),
                    message: intl.formatMessage({id: "reportpopin__body_other_reason"})
                },
            ]}
            onChange={v => setCurrentReport(v)}
            />
            {other && <TextInput value={otherReason} onChange={(event) => setOtherReason(event.target.value)} label={intl.formatMessage({id:"reportpopin__body_other_reason"})}/>}
            <div className='flex gap-2 justify-center'>
                <Button color="primary" appearance='full' fillSpace onClick={() => {
                    api_postValueOrErrorMessage("ephemes/report", {
                        id,
                        message: other? otherReason : currentReport
                    });
                    setReportModal(false)
                    next();
                }}><FormattedMessage id="reportpopin__report_button" /></Button>
            </div>
            <div  className='text-center text-xs' onClick={() => setReportModal(false)}><FormattedMessage id="reportpopin__cancel_button" /></div>

        </div>
    )
}

export default ReportModal