import { faCheck, faFire, faMessage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { fetchMembersFromIds, getRelation } from '../../../Api/member'
import { chatChannelsAdd, chatChannelsInformations, membersInformations_members } from '../../../Api/types'
import { api_postValueOrErrorMessage } from '../../../Api/util'
import EphemeList from '../../../Component/EphemeList'
import Button from '../../../Component/Input/Button'
import { OPEN_CHANNELS } from '../../../Hook/openChannels'
import { closeCurrentPopin, displayPopin } from '../../../Hook/popins'
import useUser, { USER } from '../../../Hook/user'

function ProfileFanclub({ member }: { member: membersInformations_members }) {
    const [relation, setRelation] = useState<any>(null);
    const user = useUser()!;

    useEffect(() => {
        getRelation(member.id).then(rel => {
            setRelation(rel)
        })
    }, [])

    const startChat = () => {
        if (user?.informations?.type === "guest") {
            return displayPopin()("login", { errorMessage: "terminated_guest_freetour" });
        }
        closeCurrentPopin();
        let err = USER?.informations?.restrictions?.find(e => e.type === "chat_access");
        if (err) {
            return displayPopin()("chaterror", {error: err});
        }
        api_postValueOrErrorMessage<chatChannelsAdd>("chat/channels/add", { members: [member.id] }).then(channel => {
            if (!channel.error_message)
                api_postValueOrErrorMessage<chatChannelsInformations>("chat/channels/informations", { channels: [channel.channel] }).then(async chan => {
                    if (!chan.error_message) {
                        let currentChan = chan.channels[0];
                        let otherMember = currentChan.members.find(e => e !== user?.informations?.id);
                        const member = await fetchMembersFromIds([otherMember as string]);
                        currentChan.otherMember = member[0];
                        OPEN_CHANNELS.openChannel(currentChan, true);
                    }
                });
        })
    }

    let isInFC = member.is_fan_user;
    if (!isInFC && user.informations?.type !== "guest") {

        if (user.informations?.gender === "man") {
            isInFC = relation?.flames_gift_sent > member.fanclub_flames_required!;
        } else {
            isInFC = relation?.fanclub_chat_messages_required > member.fanclub_chat_messages_required!;
        }
    }

    if (isInFC) {
        return <EphemeList additionalInputs={{
            genders: ["man", "woman", "couple"],
            types: ["fanclub"],
            thermometers: ["soft", "hot", "hard"],
            content_types: ["photo", "video"],
            member: member.id
        }} endpoint={"member_feed"} />
    }

    return (
        <div className='flex flex-col items-center text-xs gap-2'>
            <div className='text-xl [&>*]:text-primary'><FormattedMessage id="profile__their_fanclub" /></div>
            <div className='text-primary mb-2'><FormattedMessage values={{ member: member.pseudonym }} id="home__fanclub_advantage_title" /></div>
            <div><FontAwesomeIcon className='text-primary' icon={faCheck} /> <FormattedMessage id="profile__fanclub_reason_1" /></div>
            <div><FontAwesomeIcon className='text-primary' icon={faCheck} /> <FormattedMessage id="profile__fanclub_reason_2" /></div>
            <div><FontAwesomeIcon className='text-primary' icon={faCheck} /> <FormattedMessage id="profile__fanclub_reason_3" /></div>
            <div><FontAwesomeIcon className='text-primary' icon={faCheck} /> <FormattedMessage id="profile__fanclub_reason_4" /></div>
            {user.informations?.gender !== "man" && <>
                <div className='h-0.5 w-full bg-gray-800 my-4' />
                <div><FontAwesomeIcon className='h-8' icon={faMessage} /><span className='text-2xl'>{relation?.nb_chat_messages}</span><span className='text-primary'>/{member.fanclub_chat_messages_required}</span></div>
                <div><FormattedMessage values={{ value: member.fanclub_chat_messages_required, member: member.pseudonym }} id={`profile__fanclub_${user.informations?.gender}_description`} /></div>
                <div>
                    <Button color="primary" onClick={startChat}>
                        <FormattedMessage id="profileheader__chat" />
                    </Button>
                </div>
            </>}
            <div className='h-0.5 w-full bg-gray-800 my-4' />
            <div><FontAwesomeIcon className='h-8' icon={faFire} /><span className='text-2xl'>{relation?.flames_gift_sent}</span><span className='text-primary'>/{member.fanclub_flames_required}</span></div>
            <div><FormattedMessage id="profile__fanclub_description" /></div>
            <div>
                <Button color="primary" onClick={startChat}>
                    <FormattedMessage values={{ flames: member.fanclub_flames_required }} id="profile__offer_x_flames" />
                </Button>
            </div>
        </div>
    )
}

export default ProfileFanclub