import { FormattedMessage, useIntl } from 'react-intl';
import { USER } from '../../Hook/user';
import { cards } from '../CardList/cardsIcons';
import TextInput from '../Input/TextInput';
import cardsIMG from "./creditcards2.png";
function CardForm({ setNewCard, newCard }: any) {
    const intl = useIntl();
    if (newCard) {
        return (
            <div id="securionpay-form" className='flex flex-col gap-4 all:!text-white'>
                <img className="h-8 m-auto" src={cardsIMG} alt="" />
                <TextInput name="cardholderName" type="text" autoComplete='cardholderName' label={intl.formatMessage({ id: "payment__card_owner_name" })} />
                <TextInput maxLength={16} type="text" name="number" autoComplete='cc-number' label={intl.formatMessage({ id: "payment__card_number" })} />
                <div className='flex gap-4'>
                    <TextInput onChange={(event) => {
                        if (event.target.value.length <= 2) {
                            return
                        }
                        let current = event.currentTarget.value.replace("/", "");
                        if (current.length == 2) return event.currentTarget.value = current;
                        event.currentTarget.value = current.substr(0, 2) + "/" + current.substr(2);
                    }} maxLength={5} type="text" name="exp" autoComplete="cc-exp" label={intl.formatMessage({ id: "payment__expiration" })} />
                    <TextInput name="cvc" pattern="\d*" type="text" maxLength={3} autoComplete="cc-csc" label="CVC" />
                </div>
            </div>
        )
    }

    return (
        <div onClick={() => setNewCard(true)} className="p-4 cursor-pointer border border-border rounded-xl items-center flex item-center gap-2">
            <div className='w-8 [&>*]:w-full' dangerouslySetInnerHTML={{ __html: cards.CB }}></div>
            <div><FormattedMessage id={USER.cards.length === 0 ? "underage__add_card" : "payment__new_card_infos"} /></div>
        </div>
    )
}

export default CardForm