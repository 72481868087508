import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import MemberList from '../../Component/MemberList'
import Content from '../Content'
import Footer from '../Footer'
import Header from '../Header'
import Main from '../Main'
import MenuMobile from '../MenuMobile'
import { useNavigate } from 'react-router'
import useUser from '../../Hook/user'

function Blocked() {

  const navigate = useNavigate();
  const user = useUser()!;
  useEffect(() => {
    if (user.informations?.type === "guest") {
      navigate("/");
    }
  }, [])

  
  return (<>
    <Header />
    <Content>
      <Main>
        <h2 className='text-center text-2xl [&>*]:text-primary'><FormattedMessage id="blocked__title_page" /></h2>        
        <div className=' max-w-5xl w-full m-auto my-0 flex flex-col gap-8'>
          <MemberList endpoint='blocked' blocked noGlobalFilters additionalInputs={{ genders: ["man", "woman", "couple"] }} />
        </div>
      </Main>
    </Content>
    <Footer />
    <MenuMobile />
  </>)
}

export default Blocked