import { useEffect } from "react";
import { displayPopin, useListenPopins } from "../Hook/popins";

function PopinsHolder() {
  const popins = useListenPopins();
  const Element:any = popins.popins[0];
  useEffect(() => {
    if(document.location.href.includes("nodisclaimer")) localStorage.setItem("disclaimer", "true");
    if(!localStorage.getItem("disclaimer") && !document.location.href.includes("nodisclaimer")) displayPopin()("disclaimer")
  }, [])
  if(!Element) return null;
  return <Element {...popins.props[0]} />;
}

export default PopinsHolder;
