import { faCoins, faFire, faRankingStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { PLATFORM } from '../../../Hook/platform';
import useUser from '../../../Hook/user';
import Pseudonym from '../../Member/Pseudonym';
import { sockedGiftMessage, sockedTipMessage } from '../utils';

function TipMessage({ message, otherMember }: { message: sockedTipMessage, otherMember:any }) {
  const user = useUser();
  return (<div className='w-60 max-w-[100%] m-auto my-0 bg-cards flex flex-col gap-4 p-4 rounded-xl items-center'>
    <div className="[&>span]:text-primary text-center">
      <FormattedMessage  id={user?.informations?.id === message.author ? "tipmessage__title_sent" : "tipmessage__title_received"} values={{ member: otherMember.pseudonym }} />
    </div>
    <div className='flex gap-2 items-center text-2xl'>
        <div>{message.tip_amount}</div>
        <FontAwesomeIcon icon={faCoins} />
      </div>
    <div className='h-0.5 w-full bg-border'></div>
    <div><Pseudonym member={user?.informations?.id === message.author ? otherMember : user?.informations} /></div>
    <div className='flex justify-around w-full'>
      <div className='flex flex-col items-center text-2xl'>
        <FontAwesomeIcon icon={faFire} />
        <div>+{message.tip_flames_given}</div>
      </div>
      <div className='flex flex-col items-center text-2xl'>
        <FontAwesomeIcon icon={faRankingStar} />
        <div>+{message.tip_rank_before - message.tip_rank_after}</div>
      </div>
    </div>
  </div>)

}

export default TipMessage