import { useEffect, useState } from "react";
import { api_chatGetChannels } from "../Api/chat";
import { fetchMembersFromIds } from "../Api/member";
import { api_postValueOrErrorMessage } from "../Api/util";
import BaseHookWithUpdaters from "./baseHookWithUpdaters";
import { PLATFORM } from "./platform";
import { displayToast } from "./toast";
import { USER } from "./user";

class Notification extends BaseHookWithUpdaters {
  socket: any = null;
  interval: any = null;
  profile_visit = 0;
  epheme_like = 0;
  content_like = 0;
  follow = 0;
  nb_online_members = 0;
  epheme_from_followed = 0;
  chat_message = 0;
  profile_visit_notifications = {};
  chat_message_notifications = {};
  identity_verified_notifications = {};

  startSocketListener = () => {
    this.socket = require("socket.io-client")(
      PLATFORM.platform?.notification_server,
      { query: `session_id=${USER.informations?.session_id}` },
    );

    //keep the connection alive
    this.interval = setInterval(() => {
      this.socket.emit("online");
      this.getUnreadMessageCount();
    }, 10000);

    //handle the counters payload
    this.socket.on("counter", this.socketCounterMessage);
    this.socket.on("notification", this.socketNotificationMessage);
    this.getUnreadMessageCount();
  };

  socketCounterMessage = (unparsedPayload: string) => {
    let parsedCounters = JSON.parse(unparsedPayload);
    (this as any)[parsedCounters.counter] = parsedCounters.value;
    this.update();
  };

  alreadyNotif:string[] = [];
  socketNotificationMessage = async (notificationPayload: any) => {
    if (notificationPayload.type == "chat_message") {
      this.getUnreadMessageCount();
      let member = (await fetchMembersFromIds([notificationPayload.author]))[0];
      if (member)
        displayToast(
          member,
          "toast__message_title",
          notificationPayload.message,
          "messageReceived"
        );

      window.dispatchEvent(new CustomEvent("refreshChat"));
    }
    if (notificationPayload.type == "profile_visit") {
      console.log(notificationPayload);
      this.getUnreadMessageCount();
      if (this.alreadyNotif.includes(notificationPayload.author)) return;
      this.alreadyNotif.push(notificationPayload.author);
      let member = (await fetchMembersFromIds([notificationPayload.author]))[0];
      if (member)
        displayToast(
          member,
          "toast__profile_visit_title",
          "",
          "profileVisit"
        );

      window.dispatchEvent(new CustomEvent("refreshChat"));
    }
    if (notificationPayload.type == "") {
    }
    (this as any)[`${notificationPayload.type}_notifications`] =
      notificationPayload;
  };

  getUnreadMessageCount = async () => {
    let infos = await api_chatGetChannels(["private"], 0, true);
    let newMessagesCount = 0;

    for (const key in infos) {
      const channel = infos[key as any];
      if (!channel) continue;
      newMessagesCount += channel.nb_new_messages ?? 0;
    }

    this.chat_message = newMessagesCount;
    this.update();
  };

  decreaseMessageCount = (amount: number) => {
    this.chat_message -= amount;
    this.update();
  };

  closeServer = () => {
    if (this.socket) this.socket.close();
  };

  resetCounter = (name: string) => {
    (this as any)[name] = 0;
    api_postValueOrErrorMessage("user/notifications/counters/reset", {
      counters: [name],
    });
  };
}

export const NOTIFICATION = new Notification();

function useNotification() {
  const [notification, setIsNotification] =
    useState<Notification>(NOTIFICATION);

  useEffect(() => {
    NOTIFICATION.register(setIsNotification);
    return () => {
      NOTIFICATION.unregister(setIsNotification);
    };
  }, []);

  return notification;
}

export default useNotification;
