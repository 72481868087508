import {
  faArrowRight,
  faCheckCircle,
  faInfoCircle,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import Button from "../../Component/Input/Button";
import { LOCALE } from "../../Hook/locale";
import id from "./assets/id.png";
import mail from "./assets/mail.png";
import sign from "./assets/sign.png";
import yousign from "./assets/yousign.png";
import yoti from "./assets/yoti.png";
import ReactSimplyCarousel from 'react-simply-carousel';
import { useState } from "react";
import useIsMobile from "../../Hook/mobile";

const inside = [
  <div className="flex flex-col  w-[calc(100vw-4rem)] lg:w-auto  h-full gap-2 rounded-xl bg-[#470841] p-4 pb-12">
    <div className="text-center bg-[#00000033] w-fit mx-auto px-4 py-2 rounded-full mb-2">
      <FormattedMessage id="content_provider__step_1" />
    </div>
    <div className="flex h-32 justify-center">
      <div>
        <img src={id} alt="" className="max-w-[8rem]" />
      </div>
    </div>
    <div className="text-[#09C55D]">
      <FormattedMessage id="content_provider__step_1_title" />
    </div>
    <div className="h-10">
      <div className="p-2 bg-[#00000033] w-fit rounded-md flex items-center gap-2">
        <FormattedMessage id="content_provider__with" />
        <img className="h-8" src={yoti} />
      </div>
    </div>
    <div className="pt-10">
      <FormattedMessage id="content_provider__step_1_description" />
    </div>
  </div>,
  <div className="flex flex-col  w-[calc(100vw-4rem)] lg:w-auto  h-full gap-2 rounded-xl bg-[#2D2205] p-4">
    <div className="text-center bg-[#00000033] w-fit mx-auto px-4 py-2 rounded-full mb-2">
      <FormattedMessage id="content_provider__step_2" />
    </div>
    <div className="flex h-32 justify-center">
      <div>
        <img src={mail} alt="" className="max-w-[8rem]" />
      </div>
    </div>
    <div className="text-[#09C55D]">
      <FormattedMessage id="content_provider__step_2_title" />
    </div>
    <div className="pt-10">
      <FormattedMessage id="content_provider__step_2_description" />
    </div>
  </div>,
  <div className="flex flex-col  w-[calc(100vw-4rem)] lg:w-auto  h-full gap-2 rounded-xl bg-[#2F2B81] p-4">
    <div className="text-center bg-[#00000033] w-fit mx-auto px-4 py-2 rounded-full mb-2">
      <FormattedMessage id="content_provider__step_3" />
    </div>
    <div className="flex h-32 justify-center">
      <div>
        <img src={sign} alt="" className="max-w-[8rem]" />
      </div>
    </div>
    <div className="text-[#09C55D]">
      <FormattedMessage id="content_provider__step_3_title" />
    </div>
    <div className="h-10">
      <div className="p-2 bg-[#00000033] w-fit rounded-md flex items-center gap-2">
        <FormattedMessage id="content_provider__with" />
        <img className="h-8" src={yousign} />
      </div>
    </div>
    <div className="pt-10">
      <FormattedMessage id="content_provider__step_3_description" />
    </div>
  </div>
]


function ContentProviderEntry({ nextStep }: any) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const detectMobile = useIsMobile();

  return (
    <>
      <h2 className="text-lg text-primary">
        <FormattedMessage id="content_provider__entry_title" />
      </h2>
      <p>
        <FormattedMessage id="content_provider__entry_description" />
      </p>
      {detectMobile.isMobile ? <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        preventScrollOnSwipe
        itemsToShow={1}
        itemsToScroll={1}
        backwardBtnProps={
          { show: false }
        }
        forwardBtnProps={
          { show: false }
        }
        dotsNav={{
          show: true,
          itemBtnProps: {
            className: "rounded-full bg-white h-3 w-3",
          },
          activeItemBtnProps: {
            className: "rounded-full bg-primary h-3 w-3",
          },
          containerProps: {
            className: "flex gap-2 mt-4",
          }
        }}
      >
        {inside}
      </ReactSimplyCarousel>
        :
        <div className="grid grid-cols-3 gap-4">
          {inside}
        </div>
      }
      <Button
        onClick={() => {
          nextStep();
        }}
        color="primary"
        className={"flex items-center justify-center gap-4 !px-10 !py-3"}
      >
        <FormattedMessage id="content_provider__start_process" />
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
      <div
        onClick={() =>
          window.open(
            `https://www.snapdesk.info/hc/${LOCALE.locale === "fr" ? "fr-fr" : "en-us"}/articles/4406628307474-A-quoi-sert-l-authentification-pour-publier-du-contenu-SEXY-ou-EXPLICITE-`,
            "_blank",
          )
        }
        className="flex cursor-pointer items-center justify-center gap-2"
      >
        <FontAwesomeIcon icon={faInfoCircle} />
        <FormattedMessage id="content_provider__more_infos" />
      </div>
    </>
  );
}

export default ContentProviderEntry;
