export default class BaseHookWithUpdaters {

    isLoaded = false;
    isLoading = false;

    listOfUpdaters:any[] = [];

    fetch = async (updater: React.Dispatch<React.SetStateAction<any|null>>) => {
        alert("this method must be override");
    }

    update = () => {
        this.listOfUpdaters.forEach(updater => {
            updater({...this});
        });
    }

    register = (setUpdater:any) => {
        this.listOfUpdaters.push(setUpdater)
    }

    unregister = (setUpdater:any) => {
        this.listOfUpdaters = this.listOfUpdaters.filter(elem => elem !== setUpdater)
    }
}