import { useEffect, useState } from "react";
import { resetMemberCache } from "../Api/cache/member";
import { getCitiesInformations, getCountryInformations } from "../Api/location";
import { fetchMembersFromIds, getTrophies } from "../Api/member";
import { cardsInformations, membersFollowed, userInformations } from "../Api/types";
import { api_getUserInformations, api_postLogin } from "../Api/user";
import { api_getValueOrErrorMessage, api_postValueOrErrorMessage } from "../Api/util";
import { getBase64 } from "../Component/utils";
import BaseHookWithUpdaters from "./baseHookWithUpdaters";
import { FILTERS } from "./filters";
import { NOTIFICATION } from "./notification";
import { reduceImageSize } from "../utils";
import { PLATFORM } from "./platform";

export class User extends BaseHookWithUpdaters {
    informations: userInformations | undefined;
    classicTrophies: any[] = [];
    challengeTrophies: any[] = [];
    cards: cardsInformations[] = [];
    followed = new Set<string>();
    fetch = async () => {
        if (this.isLoading) return;
        this.isLoading = true;
        this.informations = await api_getUserInformations();
        if (!this.informations.gender) this.informations.gender = "man";
        this.isLoaded = true;
        if (this.informations.filter_genders) {
            FILTERS.filters.genders = this.informations.filter_genders
        }
        if (this.informations.filter_thermometers) {
            FILTERS.filters.thermometers = this.informations.filter_thermometers
        }
        const listAllPromises = [this.fetchCountry(), this.fetchCity()];
        if (this.informations.type !== "guest") {
            listAllPromises.push(this.fetchPublicInfos());
            listAllPromises.push(this.getTrophyClassic());
            listAllPromises.push(this.getTrophyChallenge());
            listAllPromises.push(this.fetchCards());
            listAllPromises.push(this.fetchDataLayer());
            resetMemberCache()
            if (this.informations.filter_genders) FILTERS.filters.genders = this.informations.filter_genders;
            if (this.informations.filter_thermometers) FILTERS.filters.thermometers = this.informations.filter_thermometers;
            FILTERS.update();
        }
        await Promise.allSettled(listAllPromises);
        this.update();
        if (this.informations.type !== "guest") {
            NOTIFICATION.startSocketListener();
            this.fetchAllFollowedMembers().then(this.update);
        }
        this.isLoading = false;
    }

    fetchCountry = async () => {
        if (this.informations && this.informations.country_id) {
            this.informations.countryInfos = (await getCountryInformations([this.informations.country_id]))[0];
        }
    }

    fetchAllFollowedMembers = async () => {
        const limit = 50;
        let prevFetchNumber = 50;
        let page = 0;
        while (prevFetchNumber === limit) {
            let members = (await api_postValueOrErrorMessage<membersFollowed>("members/followed", {
                genders: ["man", "woman", "couple"],
                offset: page * limit,
                limit,
                sort: "date"
            })).members;
            prevFetchNumber = members.length;
            for (let member of members) this.followed.add(member.id);
            page++;
        }

    }

    fetchPublicInfos = async () => {
        if (!this.informations) return;
        let mInfos = await fetchMembersFromIds([this.informations.id as string]);
        this.informations = { ...mInfos[0], ...this.informations };
    }

    getTrophyClassic = async () => {
        if (!this.informations) return;
        this.classicTrophies = await getTrophies(this.informations.id as any, "classic");
    }

    getTrophyChallenge = async () => {
        if (!this.informations) return;
        this.challengeTrophies = await getTrophies(this.informations.id as any, "challenge");
    }

    fetchCards = async () => {
        this.cards = (await api_postValueOrErrorMessage("user/cards/informations", (await api_postValueOrErrorMessage("user/cards", { limit: 20, offset: 0 }))) as any).cards;
    }
    fetchDataLayer = async () => {
        const layer = await api_getValueOrErrorMessage("user/datalayers");
        if ((window as any).dataLayer) {
            (window as any).dataLayer.push({ event: 'datalayer_push', ...layer });
        }
    }

    fetchCity = async () => {
        if (this.informations && this.informations.city_id) {
            this.informations.cityInfos = (await getCitiesInformations([this.informations.city_id]))[0];
        }
    }

    login = async (informations: any) => {
        let answer = await api_postLogin(informations);
        if (!answer.error_message) {
            this.fetch();
            PLATFORM.fetch();
        }
        return answer;
    }

    logout = async () => {
        NOTIFICATION.closeServer();
        await api_getValueOrErrorMessage("user/logout");
        this.classicTrophies = [];
        this.challengeTrophies = [];
        this.cards = [];
        this.followed = new Set<string>();
        return this.fetch();
    }

    changeAvatar = async (file: File) => {
        let url = URL.createObjectURL(file);
        if (!this.informations) return;
        this.informations.avatar = url;
        this.informations.avatar_big = url;
        this.update();
        reduceImageSize(file, 400, 400).then(async blob => {
            api_postValueOrErrorMessage("user/avatar/modify", {
                image: await getBase64(blob)
            });
        })
        
    }
}

export const USER = new User();

function useUser() {
    const [user, setUser] = useState<User | null>(USER.isLoaded ? USER : null);

    useEffect(() => {
        USER.register(setUser);
        if (!user) {
            USER.fetch();
        }
        return () => {
            USER.unregister(setUser);
        }
    }, [])


    return user;
}

export default useUser;