import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { channelAddWithMember } from '../../Api/chat';
import { api_postEphemesList, api_postEphemesView } from '../../Api/epheme';
import { chatChannelsInformations_channels, ephemesInformations_ephemes, ephemesView } from '../../Api/types';
import { api_postValueOrErrorMessage } from '../../Api/util';
import Avatar from '../../Component/Avatar';
import ChatItem from '../../Component/ChatItem';
import angel from "../../Component/Filters/svg/angel.svg";
import cutie from "../../Component/Filters/svg/cutie.svg";
import Loader from '../../Component/Loader';
import Pseudonym from '../../Component/Member/Pseudonym';
import useIsMobile from '../../Hook/mobile';
import { closeCurrentPopin, displayPopin } from '../../Hook/popins';
import useUser from '../../Hook/user';
import ProfileFanclub from '../../Page/Profile/Components/ProfileFanclub';
import MobileEphemeViewer from './MobileEphemeViewer';
import ReportModal from './ReportModal';
import close from "./icons/close.svg";
import mutedV from "./icons/muted.svg";
import report from "./icons/report.svg";
import volume from "./icons/volume.svg";
import zoomin from "./icons/zoomin.svg";
import zoomout from "./icons/zoomout.svg";
import FollowButton from '../../Component/Input/FollowButton';

function EphemeViewer(props: any) {
  const [fullScreen, setFullScreen] = useState((localStorage.getItem("fsv-enabled") as any) === "true");
  const [currentView, setCurrentView] = useState<ephemesView | null>(null);
  const [reportModal, setReportModal] = useState(false);
  const [currentEpheme, setCurrentEpheme] = useState(props.current as ephemesInformations_ephemes & { channel?: chatChannelsInformations_channels });
  const [ephemeList, setEphemeList] = useState<ephemesInformations_ephemes[]>(props.list.filter((e: any) => e.id !== props.current.id));
  const [fanclubError, setFanclubError] = useState(false);
  const [more, setMore] = useState(true);
  const user = useUser()!;
  const [muted, setMuted] = useState(true);
  const navigate = useNavigate();
  const [liked, setLiked] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    const eventHandler = function (event: any) {
      if (event.key === "ArrowRight") {
        loadNext();
      }
    }
    document.addEventListener('keyup', eventHandler);

    return () => {
      document.removeEventListener("keyup", eventHandler)
    }
  }, [])

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.documentElement.style.overflow = "hidden";
    (async () => {
      let answer = await api_postEphemesView(currentEpheme.id);
      if (answer.status === "error") {
        if (answer.error_message === "terminated_guest_freetour") {
          displayPopin()("login", { errorMessage: "terminated_guest_freetour" });
          closeCurrentPopin();
        } else if (answer.error_message === "terminated_freetour") {
          displayPopin()("emailconfirm");
          closeCurrentPopin();
        } else {
          setFanclubError(true);
        }
      }
      if (user.informations?.type !== "guest") {
        let channel = await channelAddWithMember(currentEpheme.author);
        (channel as any).otherMember = currentEpheme.authorInformation;
        (currentEpheme as any).channel = channel;
      }
      setCurrentView((answer as ephemesView));
      setLiked(false);
      if (ephemeList.length < 2 && more) {
        let answer = await api_postEphemesList(props.loadMoreToDisplay[0], props.loadMoreToDisplay[1]);
        if (answer.length !== props.loadMoreToDisplay[1].limit) setMore(false);
        let ids = ephemeList.map(e => e.id);
        ephemeList.push(...answer.filter(e => !ids.includes(e.id)));
      }
    })()
    return () => {
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto";
      props.reload && props.reload();
    }
  }, [currentEpheme])

  const loadNext = () => {
    setCurrentView(null);
    setFanclubError(false);
    const next = ephemeList.shift();
    if (!next) {
      return closeCurrentPopin();
    }
    setCurrentEpheme(next);
  }

  const like = () => {
    if (user.informations?.type === "guest") {
      closeCurrentPopin();
      return displayPopin()("login", { errorMessage: "terminated_guest_freetour" });
    }
    api_postValueOrErrorMessage("ephemes/like", { id: currentEpheme.id });
    setLiked(true)
  }

  useEffect(() => {
    localStorage.setItem("fsv-enabled", (fullScreen as any))
  }, [fullScreen])
  if (isMobile.isMobile) return <MobileEphemeViewer fanclubError={fanclubError} loadNext={loadNext} currentEpheme={currentEpheme} currentView={currentView} />
  return (
    <div className='z-30 epheme-viewer fixed flex top-0 left-0 right-0 bottom-0 bg-popinbg'>
      <div className='flex flex-col w-3/5 max-h-screen'>
        <div className='flex justify-center items-center w-full relative flex-1'>
          <div className='absolute top-4 left-4 cursor-pointer z-50' onClick={closeCurrentPopin}><img src={close} alt="close" /></div>
          {!currentView && <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'><Loader /></div>}
          <div style={{ height: "calc(100vh - 2.5rem)" }} className={'w-full overflow-hidden relative flex justify-center items-center'}>
            {fanclubError && <>
              <img className={fullScreen ? 'w-auto max-h-full h-full object-contain relative z-40 m-auto' : ''} alt={currentEpheme.title} src={currentEpheme.thumbnail_preview} />
              <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50'>
                <ProfileFanclub member={currentEpheme.authorInformation!} />
              </div>
            </>}
            {!fanclubError && currentView && <>
              {currentView.photo ?
                <img className={fullScreen ? 'w-auto max-h-full h-full object-contain relative z-40 m-auto' : ''} alt={currentEpheme.title} src={currentView.photo_uncropped ?? currentView.photo} />
                :
                <video loop autoPlay muted={muted} playsInline className={fullScreen ? 'w-full h-full object-contain relative z-40' : ''} src={currentView.video_hd} />
              }
            </>}
            <div className={'[&>*]:text-red-600 left-12 absolute z-50 flex flex-col top-full transition-transform ' + (liked ? '-translate-y-[90%]' : '')}>
              <FontAwesomeIcon className=' h-12 ml-8 relative top-12' icon={faHeart} />
              <FontAwesomeIcon className=' h-14 ml-4 -left-8 top-8 relative' icon={faHeart} />
              <FontAwesomeIcon className=' h-8 ml-8 relative ' icon={faHeart} />
              <FontAwesomeIcon className=' h-12 ml-20 relative ' icon={faHeart} />
              <FontAwesomeIcon className=' h-12 -left-2 relative bottom-8' icon={faHeart} />
            </div>
          </div>
          <div className='absolute top-4 right-4 cursor-pointer z-50' onClick={() => setFullScreen(!fullScreen)}><img src={fullScreen ? zoomout : zoomin} alt="close" /></div>
        </div>
        <div className='h-10 w-full bg-border flex flex-row justify-between items-center px-4'>
          <div onClick={() => {
            if (user.informations?.type === "guest") {
              closeCurrentPopin();
              return displayPopin()("login", { errorMessage: "terminated_guest_freetour" });
            }
            setReportModal(true)
          }}><img src={report} alt="report" /></div>
          <div className="bg-[#313131] px-4 py-1 rounded-full" onClick={loadNext}><FormattedMessage id="ephemeviewer__next_epheme" /></div>
          <div onClick={() => setMuted(!muted)}><img className={currentView && currentView.video_hd ? '' : 'opacity-0'} src={muted ? mutedV : volume} alt="volume" /></div>
        </div>
      </div>
      <div className='flex flex-col flex-1'>
        <div className='p-8 border-b-2 border-border'>
          <div className='flex gap-4'>
            <div onClick={() => {
              navigate("/member/" + currentEpheme.authorInformation?.id);
              closeCurrentPopin();
            }} className='rounded-full w-32 h-32 flex-shrink-0 overflow-hidden'>
              <Avatar member={currentEpheme.authorInformation} />
            </div>
            <div className='flex flex-col justify-center gap-4'>
              <div className='flex items-center gap-2' onClick={() => {
                navigate("/member/" + currentEpheme.authorInformation?.id);
                closeCurrentPopin();
              }}>
                <Pseudonym member={currentEpheme.authorInformation} />
                {currentEpheme.authorInformation!.is_angel && <img src={angel} alt="angel" className="text-md" />}
                {currentEpheme.authorInformation!.is_cutie && <img src={cutie} alt="cutie" className="h-4" />}
              </div>
              <FollowButton member={currentEpheme.authorInformation!} />
              <div><FormattedMessage id={"age_range__value_label__" + currentEpheme.authorInformation?.age_range} /></div>
              <div>{currentEpheme.authorInformation?.description}</div>
            </div>
          </div>
        </div>
        <div className='flex flex-col justify-center gap-4 items-center p-8 border-b-2 border-border'>
          {currentEpheme.title && <div>{currentEpheme.title}</div>}
          {!liked && <div className='border-2 border-primary rounded-xl py-1 px-3 pr-2 cursor-pointer' onClick={like}><FormattedMessage id="ephemeviewer__like_button" /> ❤️</div>}
          {liked && <div className='border-2 border-white bg-primary rounded-xl py-1 px-3 pr-2 cursor-pointer'><FormattedMessage id="ephemeviewer__liked_button" /> 🤍</div>}

        </div>
        <ChatItem fullPage channel={currentEpheme.channel} closeChat={() => { }} />
      </div>
      {reportModal && <ReportModal setReportModal={setReportModal} next={loadNext} id={currentEpheme.id} />}
    </div >
  )
}

export default EphemeViewer