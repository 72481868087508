import React from 'react'
import { FormattedMessage } from 'react-intl';
import { trophiesMember_trophies, trophies_trophies } from '../../Api/types'
import { closeCurrentPopin } from '../../Hook/popins'
import useUser, { USER } from '../../Hook/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faRocket, faStar } from '@fortawesome/free-solid-svg-icons';

function Trophy({ trophy, base, isUser, member }: { trophy: trophiesMember_trophies, base: trophies_trophies, isUser?: boolean, member: any }) {
  const user = useUser();
  let descriptionValues: any = {}
  let nextDescriptionValues: any = {}
  if (base.levels) descriptionValues = {
    value: base.levels[(trophy.level) ? (trophy.level - 1) : 0]?.value,
    boost: base.levels[(trophy.level) ? (trophy.level - 1) : 0]?.boost
  }

  if (base.levels && isUser) {
    nextDescriptionValues = {
      value: base.levels[(trophy.level) ?? 1]?.value,
      boost: base.levels[(trophy.level) ?? 1]?.boost
    }
  }

  descriptionValues = { ...descriptionValues, member: member.pseudonym }
  return (<>
    <div onClick={closeCurrentPopin} className="top-0 left-0 right-0 bottom-0 fixed bg-black opacity-60 zz-40-10"></div>
    <div className="z-50 p-8 lg:max-w-2xl lg:rounded-xl bg-popinbg w-screen fixed max-h-[100dvh] scroll--numbers overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-4">
      <div onClick={closeCurrentPopin} className='absolute top-5 right-8'><FontAwesomeIcon icon={faClose} /></div>

      <img className='m-auto' src={`${(window as any).__webpack_public_path__}trophies-icon/trophie_${trophy.id}.png`} />
      <h2 className='text-lg font-semibold text-center [&>*]:text-yellow-600'><FormattedMessage values={descriptionValues} id={`trophy__${trophy.id}__title`} /></h2>
      {base.levels && <div className='flex gap-y-1 gap-x-1 flex-wrap w-full px-2 justify-center'>
        {base.levels?.map((e, i) => {
          return <FontAwesomeIcon className={e.level <= (trophy.level as number) ? ' text-yellow-600 w-4' : ' w-4'} icon={faStar} />
        })}
      </div>}
      {descriptionValues.boost && <div className='text-center'>+<span className='text-primary'>{descriptionValues.boost}<span className='text-xs relative bottom-1'>%</span></span> <FontAwesomeIcon icon={faRocket} /></div>}
      <h2 className='text-lg font-semibold text-center'><FormattedMessage values={descriptionValues} id={`trophy__${trophy.id}__level_${trophy.level ?? 1}__${trophy.achieved ? "unlock" : "lock"}_description${isUser ? "" : "_tier"}`} /></h2>
      {trophy.achieved && isUser && <>
        <hr />
        <div className='text-center text-sm'>
          <FormattedMessage values={nextDescriptionValues} id={`trophy__${trophy.id}__level_${trophy.level ? trophy.level + 1 : 1}__lock_description`} />
        </div>
      </>}
    </div>
  </>)
}

export default Trophy