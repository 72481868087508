import { USER } from "../Hook/user";
import { cachechats, chatInformationsChatExistsWithMember, chatInformationsFilterFetchingList } from "./cache/chat";
import { fetchMembersFromIds, getOnlineStatus } from "./member";
import { chatChannelsInformations, chatChannelsInformations_channels } from "./types";
import { api_postValueOrErrorMessage } from "./util"

export const channelAddWithMember = async (id:string) => {
  let exists = chatInformationsChatExistsWithMember(id);
  if(exists) return exists;
  let channelIds = await api_postValueOrErrorMessage<{channel: string}>("chat/channels/add", {members: [id]});
  if(channelIds.status === "error"){
    return channelIds;
  }
  let answer = await api_postValueOrErrorMessage<chatChannelsInformations>("chat/channels/informations", {channels: [channelIds.channel]});
  cachechats(answer, id);
  return answer.channels[0];
}

export const api_chatFormatMessage = async (msg:string) => {
  return msg;
};

export const api_chatGetChannels = async (types:("private"|"public"|"fanclub")[], page = 0, skipCache = false) => {
  let chatsChannels = await api_postValueOrErrorMessage<{channels: string[]}>("chat/channels", {limit: 20, offset: page* 20, types});
  if(chatsChannels.status === "error") return;
  let e = chatInformationsFilterFetchingList(chatsChannels as unknown as {channels: string[]});
  let answer = await api_postValueOrErrorMessage<chatChannelsInformations>("chat/channels/informations", {channels: skipCache ? chatsChannels.channels : e.notInMemory});
  cachechats(answer);
  const returnArray:chatChannelsInformations_channels[] = [];
  for(let chat of chatsChannels.channels)
  {
    if(e.alreadyInMemory[chat])
    {
      returnArray.push(e.alreadyInMemory[chat])
    }
    else
    {
      returnArray.push(answer.channels.find(e => e.id === chat) as chatChannelsInformations_channels);
    }
  }
  if(types.includes("private")){
      const memberIds = returnArray.map(a => {
        return a.members.find(e => e !== USER.informations?.id) ?? "";
      })
      const member = await fetchMembersFromIds(memberIds);
      const online = await getOnlineStatus(memberIds);
      returnArray.forEach((item, index) => {
        member[index].is_online = online[index]?.is_online;
        item.otherMember = member[index];
      })
  }
  for(let chat of returnArray){
    if(chat.type==="fanclub"){
      let starMember = chat.id.split("-")[1];
      const member = await fetchMembersFromIds([starMember]);
      (chat as any).star = member[0];
    }
  }
  return returnArray;
}