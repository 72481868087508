import { useEffect, useState } from "react";
import BaseHookWithUpdaters from "./baseHookWithUpdaters";
import { api_postValueOrErrorMessage } from "../Api/util";

class Filters extends BaseHookWithUpdaters {

    filters:any = {
      genders: ["woman", "couple"],
      thermometers: ["soft"]
    }

    constructor(){
      super();
      let item = localStorage.getItem("filters");
      if(item){
        this.filters = JSON.parse(item);
      }
    }

    updateFilter = (key:string, value:any) => {
      if(value === "yes" || value === "no"){
         this.filters[key] = value;
         localStorage.setItem("filters", JSON.stringify(this.filters));
          return this.update();  
      }
      if(this.filters[key]) {
        if(!this.filters[key].includes(value)) this.filters[key].push(value);
        else this.filters[key] = this.filters[key].filter((e:any) => value !== e);
      }
      else this.filters[key] = [value];
      this.filters[key].sort();
      this.update();
      api_postValueOrErrorMessage("user/informations/modify", {
        filter_genders: this.filters.genders,
        filter_thermometers: this.filters.thermometers
      });
      localStorage.setItem("filters", JSON.stringify(this.filters));
    }
}

export const FILTERS = new Filters();

function useFilters() {
    const [filters, setFilters] = useState<any>(FILTERS);

    useEffect(() => {
      FILTERS.register(setFilters);
        return () => {
          FILTERS.unregister(setFilters);
        }
    }, [])


    return filters;
}

export default useFilters;