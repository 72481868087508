import { useEffect, useState } from "react";
import { chatChannelsInformations_channels } from "../Api/types";
import { NAVIGATE } from "../Router";
import BaseHookWithUpdaters from "./baseHookWithUpdaters";
import { closeCurrentPopin } from "./popins";

class OpenChannels extends BaseHookWithUpdaters {
  channels:chatChannelsInformations_channels[] = [];
  
  
  openChannel = async (channel:chatChannelsInformations_channels, forceGift?: boolean) => {
    channel.defaultOpenGift = forceGift;
    if(window.innerWidth < 768){
      closeCurrentPopin();
      return NAVIGATE.navigate("/chat/"+channel.id, {state:{channel}});
    }
    if(this.channels.length > 2) return;
    if(this.channels.find(e => e.id === channel.id))return;
    if(this.channels.length > 2 && !forceGift) return;
    if(forceGift && this.channels.length > 2) this.channels.shift();
    this.channels = [...this.channels, channel]
    this.update();
  }

  closeChannel = (channel:any) => () => {
    this.channels = this.channels.filter(e => e !== channel);
    this.update();
  };
}

export const OPEN_CHANNELS = new OpenChannels();

function useOpenedChannels() {
    const [openChannel, setOpenChannel] = useState<OpenChannels>(OPEN_CHANNELS);

    useEffect(() => {
      OPEN_CHANNELS.register(setOpenChannel);
        return () => {
          OPEN_CHANNELS.unregister(setOpenChannel);
        }
    }, [])


    return openChannel;
}

export default useOpenedChannels;