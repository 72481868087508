import React from 'react'
import useFilters from '../../Hook/filters'
import { ReactComponent as Man } from "./svg/man.svg";
import { ReactComponent as WomanSVG } from "./svg/woman.svg";
import { ReactComponent as Angel } from "./svg/angel.svg";
import { ReactComponent as Couple } from "./svg/couple.svg";
import { FormattedMessage } from 'react-intl';
import useIsMobile from '../../Hook/mobile';
import useUser from '../../Hook/user';
import { displayPopin } from '../../Hook/popins';

function GenderFilter({ localUpdater, hasAngel, currentValue, currentValues, fill }: { hasAngel?: boolean, localUpdater?: any, currentValue?: string, currentValues?: string[], fill?: boolean }) {
  const filters = useFilters();
  const user = useUser();
  const detectMobile = useIsMobile();
  let filterExist = filters.filters["genders"];
  //if local current value we find what currentValue is (single value) otherwise it depends on app-wide filters
  let manSelected = currentValues ? currentValues.includes("man") : (currentValue && currentValue === "man") ?? (filterExist && filterExist.includes("man"));
  let womanSelected = currentValues ? currentValues.includes("woman") : (currentValue && currentValue === "woman") ?? (filterExist && filterExist.includes("woman"));
  let coupleSelected = currentValues ? currentValues.includes("couple") : (currentValue && currentValue === "couple") ?? (filterExist && filterExist.includes("couple"));
  let angelSelected = currentValues ? currentValues.includes("angel") : (currentValue && currentValue === "angel") ?? (filterExist && filterExist.includes("angel"));
  let shouldShowIcons = window.innerWidth < 390;
  if(hasAngel && detectMobile.isMobile) {
    shouldShowIcons = true;
  }

  return (
    <div className={'bg-cards lg:bg-transparent rounded-xl w-fit ' + (fill ? ' !w-full' : '')}>
      {!detectMobile.isMobile && !fill && <div className='text-label mb-2 text-sm'><FormattedMessage id={"profile__gender"} /></div>}
      <div className={"flex float-right lg:bg-cards lg:h-auto lg:py-4 py-2 p-2 h-11 items-center gap-2 rounded-xl" + (fill ? ' !w-full' : '')}>
        <div onClick={() => {
          if (localUpdater) return localUpdater("man")
          filters.updateFilter("genders", "man")
        }} className={"flex-1 flex-shrink-0 rounded-full border py-2 px-4 border-border cursor-pointer flex justify-center" + (manSelected ? " border-primary text-primary" : " text-tabs")}>
          {!shouldShowIcons && <FormattedMessage id="gender_sought__label__man" />}
          {shouldShowIcons && <Man /> }
        </div>
        <div onClick={() => {
          if (localUpdater) return localUpdater("woman")
          filters.updateFilter("genders", "woman")
        }} className={"flex-1 flex-shrink-0 rounded-full border py-2 px-4 border-border cursor-pointer flex justify-center" + (womanSelected ? " border-primary text-primary" : " text-tabs")}>
          {!shouldShowIcons && <FormattedMessage id="gender_sought__label__woman" />}
          {shouldShowIcons && <WomanSVG /> }
        </div>
        <div onClick={() => {
          if (localUpdater) return localUpdater("couple")
          filters.updateFilter("genders", "couple")
        }} className={"flex-1 flex-shrink-0 rounded-full border py-2 px-4 border-border cursor-pointer flex justify-center" + (coupleSelected ? " border-primary text-primary" : " text-tabs")}>
          {!shouldShowIcons && <FormattedMessage id="gender_sought__label__couple" />}
          {shouldShowIcons && <Couple />}
        </div>
        {hasAngel && <div onClick={() => {
          if (localUpdater) return localUpdater("angel")
        }} className={"flex-1 flex-shrink-0 rounded-full border py-2.5 px-4 border-border cursor-pointer flex justify-center" + (angelSelected ? " border-primary text-primary" : " text-tabs")}>
          {!shouldShowIcons && <FormattedMessage id="gender_sought__label__angel" />}
          {shouldShowIcons && <Angel />}
        </div>}
      </div>
    </div>
  )
}

export default GenderFilter;