import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '../../Component/Input/Button'
import { PLATFORM } from '../../Hook/platform'
import { closeCurrentPopin } from '../../Hook/popins'

function Disclaimer() {
    return (
        <>
            <div onClick={closeCurrentPopin} className="top-0 left-0 right-0 bottom-0 fixed bg-black opacity-80"></div>
            <div className="z-50 p-8 w-screen fixed max-h-[100dvh] lg:max-w-lg scroll--numbers overflow-y-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-popinbg rounded-xl flex flex-col gap-4">
            <div onClick={closeCurrentPopin} className='absolute top-9 right-8'><FontAwesomeIcon icon={faClose}/></div>
                <div className='flex flex-col gap-2 text-xs'>

                    <img src={PLATFORM.platform?.logo} style={{ height: 35 }} />


                    <div className='font-bold'><FormattedMessage id="popin__disclaimer" /></div>
                    <div className=' whitespace-pre-wrap text-md'>
                        <FormattedMessage id="disclaimer__body" />
                    </div>
                    <div className='max-h-20 overflow-y-auto bg-black p-2 rounded-xl'>
                        <FormattedMessage id="disclaimer__list" />
                    </div>

                    <Button fillSpace onClick={() => {
                        localStorage.setItem("disclaimer", "true");
                        closeCurrentPopin();
                    }} color="primary" name="disclaimer__accept" />
                    <Button fillSpace onClick={() => window.location.href = "www.google.com"} appearance="outline" color="primary" name="disclaimer__deny" />
                </div>
            </div>
        </>
    )
}

export default Disclaimer